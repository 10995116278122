import bind from "bind-decorator";
import React from "react";
import Helmet from 'react-helmet';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ApplicationState } from "../../store";
import { String_Format, firstLetterToLowerCase, getPromiseFromAction, nameof } from "../../utils/utils";
import { DefaultFilterValues } from "../../enums/Common/DefaultFilterValues";
import { LookupStore } from "../../store/LookupStore";
import RegularExpressions from "../../resources/Common/RegularExpressions";
import { DatePicker, Select } from 'antd';
import { RoomBookingsGridStore } from "../../store/RoomBookingsGridStore";
import RoomBookingsTable from "../../components/RoomBookings/RoomBookingsTable";
import { RoomBookingStatuses } from "../../enums/RoomBookings/RoomBookingStatuses";
import RoomBookingStatusTypes from "../../resources/AdminUI/RoomBookings/RoomBookingStatusTypes";
import RoomBookingsGrid from "../../resources/AdminUI/RoomBookings/RoomBookingsGrid";
import { ApplicationPaths } from "../../enums/Common/ApplicationPaths";
import RoomBookingsService from "../../services/RoomBookingsService";
import { LocalDateTime } from "@js-joda/core";
import { Link, NavigateFunction } from "react-router-dom";
import { IAntdSelectListItem } from "../../models/Common/IAntdSelectListItem";
import dayjs from "dayjs";
import { withRouter } from "../../components/Shared/withRouter";
const { RangePicker } = DatePicker;


interface IProps {
    filters: string,
    searchTerm: string,
    singleSelectedStatus: string,
    singleSelectedCountry: string,
    singleSelectedCity: string,
    startDateSearchTemplate: number,
    endDateSearchTemplate: number,
    countries: IAntdSelectListItem[],
    cities: IAntdSelectListItem[],

    navigate: NavigateFunction,

    initialize: (defaultSelectedItemsPerPageOption: number) => void,
    reload: () => Promise<any>,
    changeCurrentPage: (currentPage: number) => void,
    setFilters: (filters: string) => void,
    setSearchTerm: (searchTerm: string) => void,
    setSingleSelectedStatus: (singleSelectedStatus: string) => void,
    setSingleSelectedCountry: (singleSelectedCountry: string) => void,
    setSingleSelectedCity: (singleSelectedCity: string) => void,
    setStartDateSearchTemplateAndEndDateSearchTemplate: (startDateSearchTemplate: number, endDateSearchTemplate: number) => void
}

class RoomBookingsGridPage extends React.PureComponent<any, any>{
    constructor(props) {
        super(props);
        this.state = {};
        this.props.getCountriesWithCities(true);
        this.props.getCities(true);
    }

    nameSearchTemplate = `contains(tolower(UserCompanyName), tolower('{0}')) or contains(concat(concat(tolower(UserFirstName), ' '), tolower(UserLastName)), tolower('{0}')) or contains(tolower(WorkspaceName), tolower('{0}'))`;
    idSearchTemplate = ` or Id eq {0}`;
    statusFilterTemplate = `StatusId eq {0}`;
    countryFilterTemplate = `WorkspaceCountryId eq {0}`;
    cityFilterTemplate = `WorkspaceCityId eq {0}`;
    startDateFilterTemplate = `UnixStartsAtWithTimezone eq {0}`;
    startAndEndDateFilterTemplate = `UnixStartsAtWithTimezone ge {0} and UnixStartsAtWithTimezone le {1}`;
    dateFormat = 'DD-MM-YYYY';

    @bind
    rebuildODataAndGetData() {
        var filters = [];
        if (this.props.searchTerm) {
            var searchFilter = String_Format(this.nameSearchTemplate, this.props.searchTerm);
            var pattern = RegularExpressions.Resources.onlyNumbers;
            if (RegExp(pattern).test(this.props.searchTerm)) {
                searchFilter = searchFilter.concat(String_Format(this.idSearchTemplate, this.props.searchTerm));
            }
            filters.push(`(${searchFilter})`);
        }

        if(this.props.singleSelectedStatus && this.props.singleSelectedStatus != DefaultFilterValues.All.toString()) {
            filters.push(`(${String_Format(this.statusFilterTemplate, this.props.singleSelectedStatus)})`)
        }

        if(this.props.singleSelectedCountry && this.props.singleSelectedCountry != DefaultFilterValues.All.toString()) {
            filters.push(`(${String_Format(this.countryFilterTemplate, this.props.singleSelectedCountry)})`)
        }

        if(this.props.singleSelectedCity && this.props.singleSelectedCity != DefaultFilterValues.All.toString()) {
            filters.push(`(${String_Format(this.cityFilterTemplate, this.props.singleSelectedCity)})`)
        }

        if (this.props.startDateSearchTemplate && this.props.endDateSearchTemplate) {
            if (this.props.startDateSearchTemplate == this.props.endDateSearchTemplate) {
                var startDatefilter = String_Format(this.startDateFilterTemplate, this.props.startDateSearchTemplate);
                filters.push(`(${startDatefilter})`);
            } else {
                var startAndEndDatefilter = String_Format(this.startAndEndDateFilterTemplate, this.props.startDateSearchTemplate, this.props.endDateSearchTemplate);
                filters.push(`(${startAndEndDatefilter})`);
            }
        }

        var oDataFilter = filters.join(" and ");
        this.props.setFilters(oDataFilter);
        this.props.changeCurrentPage(1);
        getPromiseFromAction(this.props.reload()).then(() => this.props.changeCurrentPage(1));
    }

    @bind
    onSearchFilterChange(e: React.FormEvent<HTMLInputElement>) {
        let searchTerm = e.currentTarget.value;
        if (this.props.searchTerm != searchTerm) {
            getPromiseFromAction(this.props.setSearchTerm(searchTerm)).then(() => {
                this.rebuildODataAndGetData();
            });
        }
    }

    @bind
    onStatusFilterChange(value: string, option: any) {
        getPromiseFromAction(this.props.setSingleSelectedStatus(value)).then(() => {
            this.rebuildODataAndGetData();
        });
    }

    @bind
    onCountryFilterChange(value: string, option: any) {
        getPromiseFromAction(this.props.setSingleSelectedCountry(value)).then(() => {
            this.rebuildODataAndGetData();
        });
    }

    @bind
    onCityFilterChange(value: string, values: any) {
        getPromiseFromAction(this.props.setSingleSelectedCity(value)).then(() => {
            this.rebuildODataAndGetData();
        });
    }

    @bind
    onDateChange(dates: [any, any], dateStrings: [string, string]) {
        if (dateStrings[0] == dateStrings[1]) {
            var startDate = dateStrings[0].split("-").reverse().join("-");
            var startDateSearchTemplate = parseInt((new Date(startDate).getTime() / 1000).toFixed(0));
            getPromiseFromAction(this.props.setStartDateSearchTemplateAndEndDateSearchTemplate(startDateSearchTemplate, startDateSearchTemplate)).then(() => {
                this.rebuildODataAndGetData();
            });
        } else {
            var startDate = dateStrings[0].split("-").reverse().join("-");
            var endDate = dateStrings[1].split("-").reverse().join("-");
            var startDateSearchTemplate = parseInt((new Date(startDate).getTime() / 1000).toFixed(0));
            var endDateSearchTemplate = parseInt((new Date(endDate).getTime() / 1000).toFixed(0));
            getPromiseFromAction(this.props.setStartDateSearchTemplateAndEndDateSearchTemplate(startDateSearchTemplate, endDateSearchTemplate)).then(() => {
                this.rebuildODataAndGetData();
            });
        }
    }

    @bind
    getAllRoomStatuses() {
        var options = [];
        let keys = Object.keys(RoomBookingStatuses).filter(k => typeof RoomBookingStatuses[k as any] === "number");

        keys.forEach(key => {
            let value = RoomBookingStatuses[key as any].toString();
            options.push({
                value: value,
                label: RoomBookingStatusTypes.Resources[firstLetterToLowerCase(key)]
            });
        });
        return options;
    }

    @bind
    addRoomLog() {
        this.props.navigate(ApplicationPaths.AddRoomBooking);
    }

    @bind
    async exportLogs() {
        await RoomBookingsService.getRoomBookingsForExport(this.props.filters).then(res => {
            const entities = res.value ? res.value.entities.map(e => Object.entries(e)
                .filter(([key]) => key != "unixStartsAtWithTimezone" && key != "timezone" && key != "workspaceCountryFlag")
                .map(([key, value]) => typeof value === 'string' ? value.replaceAll(',', ' ') : value)
                .join(","))
                .join("\n") : "";
            const csvContent = RoomBookingsGrid.Resources.exportRoomBookingLogsHeader + "\n"
                + entities;
            const blob = new Blob([csvContent], {type: 'text/csv;charset=utf-8;'});
            const url = URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.setAttribute("href", url);
            link.setAttribute("download", RoomBookingsGrid.Resources.roomsLogs + "_" + LocalDateTime.now() + RoomBookingsGrid.Resources.csvExtension);
            document.body.appendChild(link);

            link.click();
        });
    }

    render() {
        return <div>
            <Helmet title={RoomBookingsGrid.Resources.roomsLogs} />
            <div className="page-top-container">
                <div className="page-title">{RoomBookingsGrid.Resources.roomsLogs}</div>
                <div className="page-actions">

                    <div className="btn-big btn-secondary" onClick={this.exportLogs}>
                        <p className="btn-secondary-text">{RoomBookingsGrid.Resources.exportRoomsLogsCsv}</p>
                    </div>

                    <Link className="btn-big btn-primary" to={ApplicationPaths.AddRoomBooking}>
                        <span className="material-symbols-outlined">add_circle</span>
                        <p className="btn-primary-text">{RoomBookingsGrid.Resources.addRoomLog}</p>
                    </Link>

                </div>
            </div>
            <div className="filter-bar">

                <div className="filter-search">
                    <div className="input-group">
                        <span className="icon material-symbols-outlined">search</span>
                        <input type="text"
                            className="form-control"
                            placeholder={RoomBookingsGrid.Resources.searchByLogIdUserNameOrCustomerName}
                            onChange={this.onSearchFilterChange}
                            name="searchTerm"
                            value={this.props.searchTerm}
                        />
                    </div>
                </div>

                <div className="filter-item" >
                    <div className="input-group">
                        <Select
                            options={this.props.countries}
                            onChange={this.onCountryFilterChange}
                            value={this.props.singleSelectedCountry}
                            showSearch
                            optionFilterProp="label"
                        />
                    </div>
                </div>
                <div className="filter-item" >
                    <div className="input-group">
                        <Select
                            options={this.props.singleSelectedCountry != DefaultFilterValues.All.toString() ? this.props.cities.filter(e => e.group == this.props.singleSelectedCountry || e.value == DefaultFilterValues.All.toString()) : this.props.cities}
                            onChange={this.onCityFilterChange}
                            value={this.props.singleSelectedCity}
                            showSearch
                            optionFilterProp="label"
                        />
                    </div>
                </div>
                <div className="filter-item" >
                    <div className="input-group">
                        <Select
                            options={this.getAllRoomStatuses()}
                            onChange={this.onStatusFilterChange}
                            value={this.props.singleSelectedStatus}
                        ></Select>
                    </div>
                </div>

                <RangePicker
                    value={[this.props.startDateSearchTemplate ? dayjs.unix(this.props.startDateSearchTemplate) : this.props.startDateSearchTemplate, this.props.endDateSearchTemplate ? dayjs.unix(this.props.endDateSearchTemplate) : this.props.endDateSearchTemplate]}
                    format={this.dateFormat}
                    onChange={this.onDateChange}
                />

            </div>

            <RoomBookingsTable
                componentId={componentId}
                hiddenColumns={["statusName", "userLastName", "userId", "userEmail", "userPhone", "userCompanyName", "roomName", "workspaceName", "workspaceCityId", "workspaceCityName", "workspaceCountryId", "workspaceCountryName", "workspaceCountryFlag", "currency", "bookingAmount", "timezone", "startsAt", "endsAt", "expiresAt", "cancelledAt", "rejectedAt", "unixStartsAtWithTimezone"]}
                expandableColumns={["userId"]}
                filters={this.props.filters}
                showActionsColumn={true}
            />

        </div>

    }
}

const componentId = "RoomBookingGridPage";

export default withRouter(connect(
    (state: ApplicationState) => {
        const componentState = state.roomBookingsGrid[componentId];

        return {
            filters: componentState?.filters || '',
            searchTerm: componentState?.searchTerm || '',
            singleSelectedStatus: componentState?.singleSelectedStatus || '0',
            singleSelectedCountry: componentState?.singleSelectedCountry || '0',
            singleSelectedCity: componentState?.singleSelectedCity || '0',
            startDateSearchTemplate: componentState?.startDateSearchTemplate || null,
            endDateSearchTemplate: componentState?.endDateSearchTemplate || null,
            countries: state.lookup.countries,
            cities: state.lookup.cities
        }
    },
    (dispatch) => bindActionCreators({
        ...RoomBookingsGridStore.getActionCreators(componentId),
        getCountriesWithCities: LookupStore.actionCreators.getCountriesWithCities,
        getCities: LookupStore.actionCreators.getCities,
    }, dispatch)
)(RoomBookingsGridPage));