import bind from "bind-decorator";
import React from "react";
import Helmet from "react-helmet";
import { connect } from "react-redux";
import { ApplicationState } from "../../store";
import { NavigateFunction } from "react-router-dom";
import { AddOrEditHotdeskBookingStore } from "../../store/AddOrEditHotdeskBookingStore";
import { ApplicationPaths } from "../../enums/Common/ApplicationPaths";
import {
  firstLetterToLowerCase,
  getPromiseFromAction,
} from "../../utils/utils";
import HotdeskBookingStatusTypes from "../../resources/AdminUI/HotdeskBookings/HotdeskBookingStatusTypes";
import { HotdeskBookingStatuses } from "../../enums/HotdeskBookings/HotdeskBookingStatuses";
import General from "../../resources/AdminUI/General";
import AddOrEditHotdeskBooking from "../../resources/AdminUI/HotdeskBookings/AddOrEditHotdeskBooking";
import AddOrEditHotdeskBookingForm from "../../components/HotdeskBookings/AddOrEditHotdeskBookingForm";
import { ActionConfirmationModal } from "../../components/Shared/ActionConfirmationModal";
import { Modal } from "antd";
import ErrorMessages from "../../resources/Common/ErrorMessages";
import { withRouter } from "../../components/Shared/withRouter";
import ModalDialog from "../../components/Shared/ModalDialog";
import Loading from "../../components/Shared/Loading";

interface IProps {
  navigate?: NavigateFunction;
  params: URLSearchParams;
  loading: boolean;
  hasServerSideErrors: boolean;
  initializeCreatePage?: () => void;
  initializeEditPage?: (id: number) => void;
  deleteBooking?: (id: number) => any;
}

interface IState {
  id: number;
  isEditMode: boolean;
  isDeleteHotdeskBookingModalOpen: boolean;
}

class AddOrEditHotdeskBookingPage extends React.PureComponent<IProps, IState> {
  constructor(props) {
    super(props);

    if (window.location.pathname == ApplicationPaths.AddHotdeskBooking) {
      this.state = {
        isEditMode: false,
        id: null,
        isDeleteHotdeskBookingModalOpen: false,
      };
      this.props.initializeCreatePage();
    } else if (
      window.location.pathname == ApplicationPaths.EditHotdeskBooking
    ) {
      if (props.params.has("id")) {
        const id = parseInt(props.params.get("id"));
        this.state = {
          isEditMode: true,
          id: id,
          isDeleteHotdeskBookingModalOpen: false,
        };
        this.props.initializeEditPage(id);
      } else {
        this.props.navigate(ApplicationPaths.NotFound);
      }
    }
  }

  @bind
  showDeleteHotdeskBookingModal() {
    this.setState({ isDeleteHotdeskBookingModalOpen: true });
  }

  @bind
  hideDeleteHotdeskBookingModal() {
    this.setState({ isDeleteHotdeskBookingModalOpen: false });
  }

  @bind
  getAllHotdeskBookingStatuses() {
    var options = [];
    let keys = Object.keys(HotdeskBookingStatuses).filter(
      (k) => typeof HotdeskBookingStatuses[k as any] === "number"
    );

    keys.forEach((key) => {
      let value = HotdeskBookingStatuses[key as any].toString();
      if (value != HotdeskBookingStatuses.AllStatuses.toString()) {
        options.push({
          value: value,
          label:
            HotdeskBookingStatusTypes.Resources[firstLetterToLowerCase(key)],
        });
      }
    });
    return options;
  }

  @bind
  deleteHotdeskBooking() {
    getPromiseFromAction(this.props.deleteBooking(this.state.id)).then(() => {
      if (!this.props.hasServerSideErrors) {
        this.props.navigate(ApplicationPaths.DesksLogs);
      } else {
        Modal.error({
          title: ErrorMessages.Resources.generalErrorMessage,
        });
      }
    });
  }

  render() {
    return (
      <div>
        <Helmet
          title={
            this.state.isEditMode
              ? AddOrEditHotdeskBooking.Resources.editDeskLog
              : AddOrEditHotdeskBooking.Resources.addDeskLog
          }
        />

        <div className="page-title">
          <div className="details-page-top">
            <div className="page-navigation">
              <span
                className="material-symbols-outlined"
                onClick={() => this.props.navigate(-1)}
              >
                arrow_back
              </span>
              <div className="page-title">
                {this.state.isEditMode
                  ? AddOrEditHotdeskBooking.Resources.editDeskLog
                  : AddOrEditHotdeskBooking.Resources.addDeskLog}
              </div>
            </div>

            <div className="page-actions">
              {this.state.isEditMode ? (
                <button
                  className="btn-danger-action"
                  onClick={this.showDeleteHotdeskBookingModal}
                >
                  <span className="material-symbols-outlined">delete</span>
                </button>
              ) : null}
            </div>
          </div>

          <ModalDialog
            title={""}
            open={this.state.isDeleteHotdeskBookingModalOpen}
            onCancel={this.hideDeleteHotdeskBookingModal}
          >
            <ActionConfirmationModal
              contentTitle={AddOrEditHotdeskBooking.Resources.deleteDeskLog}
              contentText={
                AddOrEditHotdeskBooking.Resources
                  .areYouSureYouWantToDeleteThisHotdeskLog
              }
              confirmButtonText={General.Resources.deleteLabel}
              onCancelCallback={this.hideDeleteHotdeskBookingModal}
              onSaveCallback={() => this.deleteHotdeskBooking()}
            />
          </ModalDialog>
        </div>

        {this.props.loading ? (
          <div>
            <Loading />
          </div>
        ) : (
          <div>
            <AddOrEditHotdeskBookingForm
              isEditMode={this.state.isEditMode}
              statuses={this.getAllHotdeskBookingStatuses()}
            />
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(
  connect(
    (state: ApplicationState) => {
      return {
        loading: state.addOrEditHotdeskBooking.loading,
        hasServerSideErrors: state.addOrEditRoomBooking.hasServerSideErrors,
      };
    },
    {
      initializeCreatePage:
        AddOrEditHotdeskBookingStore.actionCreators.initializeCreatePage,
      initializeEditPage:
        AddOrEditHotdeskBookingStore.actionCreators.initializeEditPage,
      deleteBooking: AddOrEditHotdeskBookingStore.actionCreators.deleteBooking,
    }
  )(AddOrEditHotdeskBookingPage as any)
);
