import React from "react";
import General from "../../resources/AdminUI/General";

interface IProps {
    contentTitle: string,
    contentText: string, 
    confirmButtonText: string
    onCancelCallback: () => void,
    onSaveCallback: () => void
}

export class ActionConfirmationModal extends React.Component<IProps, any> {
    constructor(props){
        super(props);
    }

    render() {
        return <div className="modal-container">
            <div className="modal-title">{this.props.contentTitle}</div>
            <div className="modal-description">{this.props.contentText}</div>
                <div className="form-line form-bottom-actions">
                    <button className="btn-big btn-secondary" onClick={this.props.onCancelCallback}>{General.Resources.cancelButtonLabel}</button>
                    <button className="btn-big btn-primary" onClick={this.props.onSaveCallback}>{this.props.confirmButtonText}</button>
                </div>
        </div>
    }
}