import React from "react";
import { ApplicationState } from "../../store";
import { connect } from "react-redux";
import Benefits from "../../resources/AdminUI/Benefits/Benefits";
import bind from "bind-decorator";
import { BenefitItem } from "../../models/Benefits/BenefitItem";
import ModalDialog from "./ModalDialog";
import { Modal } from "antd";
import BenefitHistory from "../../resources/AdminUI/Benefits/BenefitHistory";
import BenefitHistoryTable from "../Clients/Tables/BenefitHistoryTable";
import General from "../../resources/AdminUI/General";
import {
  firstLetterToLowerCase,
  getPromiseFromAction,
} from "../../utils/utils";
import { BenefitsStore } from "../../store/BenefitsStore";
import ErrorMessages from "../../resources/Common/ErrorMessages";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { DeactivateBenefitForm } from "../Clients/Forms/DeactivateBenefitForm";
import AssignBenefitForm from "../Clients/Forms/AssignBenefitForm";
import { BenefitBeneficiaries } from "../../enums/Benefits/BenefitBeneficiaries";
import BenefitBeneficiaryList from "../../resources/AdminUI/Benefits/BenefitBeneficiaryList";
import StartDateOptions from "../../resources/AdminUI/Benefits/StartDateOptions";
import { BenefitStartDateOptions } from "../../enums/Benefits/BenefitStartDateOptions";
import DropdownButton from "./DropdownButton";
import { withRouter } from "./withRouter";
import { NavigateFunction } from "react-router";
import { ApplicationPathBuilder } from "../../enums/Common/ApplicationPaths";
const { confirm } = Modal;

interface IProps {
  benefits?: BenefitItem[];
  clientId?: number;
  beneficiaryName: string;
  userId?: number;
  hiddenColumns?: string[];

  hasServerSideErrors?: boolean;
  errors?: string;

  navigate?: NavigateFunction;

  getClientBenefits?: (clientId: number) => any;
  deactivateBenefit?: (
    benefitId: number,
    deactivateFromCurrentCycle: boolean
  ) => any;
  deleteBenefit?: (benefitId: number) => any;
}

interface IState {
  isAssignBenefitModalOpen: boolean;
  isDeactivateBenefitModalOpen: boolean;
  isShowBenefitsHistoryModalOpen: boolean;
  selectedBenefit?: BenefitItem;
}

class BenefitsTable extends React.PureComponent<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = {
      isAssignBenefitModalOpen: false,
      isDeactivateBenefitModalOpen: false,
      isShowBenefitsHistoryModalOpen: false,
    };
  }

  tableClass: string = "table";
  benefitHistoryHiddenColumns = [
    "id",
    "name",
    "featureId",
    "maxValue",
    "currency",
    "hasUserLimit",
  ];

  @bind
  showAssignBenefitModal(benefit: BenefitItem) {
    this.setState({ isAssignBenefitModalOpen: true, selectedBenefit: benefit });
  }

  @bind
  hideAssignBenefitModal() {
    this.setState({ isAssignBenefitModalOpen: false });
  }

  @bind
  showDeactivateBenefitModal(benefit: BenefitItem) {
    this.setState({
      isDeactivateBenefitModalOpen: true,
      selectedBenefit: benefit,
    });
  }

  @bind
  hideDeactivateBenefitModal() {
    this.setState({ isDeactivateBenefitModalOpen: false });
  }

  @bind
  showBenefitsHistoryModal(benefit: BenefitItem) {
    this.setState({
      isShowBenefitsHistoryModalOpen: true,
      selectedBenefit: benefit,
    });
  }

  @bind
  hideBenefitsHistoryModal() {
    this.setState({ isShowBenefitsHistoryModalOpen: false });
  }

  @bind
  showOnDeleteBenefitModal(benefit: BenefitItem) {
    confirm({
      title: General.Resources.areYouSureModalLabel,
      icon: <ExclamationCircleOutlined />,
      content: (
        <div>
          <p>{Benefits.Resources.deleteServiceConfirmation}</p>
        </div>
      ),
      okText: Benefits.Resources.deleteService,
      okType: "danger",
      cancelText: General.Resources.cancelButtonLabel,
      onOk: () => {
        this.handleOnDeleteBenefit(benefit.id);
      },
      onCancel() {},
    });
  }

  @bind
  handleOnDeactivateBenefit(benefitId, deactivateFromCurrentCycle) {
    getPromiseFromAction(
      this.props.deactivateBenefit(benefitId, deactivateFromCurrentCycle)
    ).then(() => {
      if (!this.props.hasServerSideErrors) {
      } else {
        Modal.error({
          title: ErrorMessages.Resources.error,
        });
      }
    });
  }

  @bind
  handleOnDeleteBenefit(benefitId) {
    getPromiseFromAction(this.props.deleteBenefit(benefitId)).then(() => {
      if (!this.props.hasServerSideErrors) {
      } else {
        Modal.error({
          title: ErrorMessages.Resources.generalErrorMessage,
        });
      }
    });
  }

  @bind
  getAllBenefitStartDateOptions() {
    var options = [];
    let keys = Object.keys(BenefitStartDateOptions).filter(
      (k) => typeof BenefitStartDateOptions[k as any] === "number"
    );

    keys.forEach((key) => {
      let value = BenefitStartDateOptions[key as any].toString();
      options.push({
        value: value,
        text: StartDateOptions.Resources[firstLetterToLowerCase(key)],
      });
    });
    return options;
  }

  @bind
  getAllBenefitBeneficiaryList() {
    var options = [];
    let keys = Object.keys(BenefitBeneficiaries).filter(
      (k) => typeof BenefitBeneficiaries[k as any] === "number"
    );

    keys.forEach((key) => {
      let value = BenefitBeneficiaries[key as any].toString();
      options.push({
        value: value,
        text: BenefitBeneficiaryList.Resources[firstLetterToLowerCase(key)],
      });
    });
    return options;
  }

  render() {
    return (
      <div className="table-wrapper">
        <table className={this.tableClass}>
          <thead>
            <tr>
              {this.props.hiddenColumns.filter((c) => c === "actions").length ==
                0 && <th>{General.Resources.actions}</th>}
              {this.props.benefits[0].TrueProperties.filter(
                (p) => this.props.hiddenColumns.indexOf(p) < 0
              ).map((p) => (
                <th key={p}>
                  {this.props.benefits[0].GetDisplayNameForProperty(p)}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {this.props.benefits.map((benefit) => {
              return (
                <tr key={benefit.id}>
                  {this.props.hiddenColumns.filter((c) => c === "actions")
                    .length == 0 && (
                    <td className="">
                      <div className="client-benefits-actions">
                        <button
                          className="btn-table-action client-benefit-table"
                          onClick={() => {
                            this.props.navigate(
                              ApplicationPathBuilder.EditService(benefit.id)
                            );
                          }}
                        >
                          <span className="material-symbols-outlined">
                            edit
                          </span>
                        </button>

                        <DropdownButton
                          className="btn-table-action client-benefit-table"
                          items={[
                            {
                              icon: "toggle_off",
                              text: Benefits.Resources.deactivateService,
                              action: () =>
                                this.showDeactivateBenefitModal(benefit),
                            },
                            {
                              icon: "history",
                              text: Benefits.Resources.viewServiceHistoryText,
                              action: () =>
                                this.showBenefitsHistoryModal(benefit),
                            },
                            {
                              icon: "delete",
                              text: Benefits.Resources.deleteService,
                              action: () =>
                                this.showOnDeleteBenefitModal(benefit),
                            },
                          ]}
                          icon={
                            <span className="material-symbols-outlined">
                              more_vert
                            </span>
                          }
                        />
                        <ModalDialog
                          title=""
                          open={
                            this.state.isDeactivateBenefitModalOpen &&
                            this.state.selectedBenefit == benefit
                          }
                          onCancel={this.hideDeactivateBenefitModal}
                        >
                          <DeactivateBenefitForm
                            benefitId={benefit.id}
                            contentText={
                              Benefits.Resources.deactivateServiceText
                            }
                            confirmButtonText={
                              Benefits.Resources.deactivateService
                            }
                            onCancelCallback={this.hideDeactivateBenefitModal}
                            onSaveCallback={this.handleOnDeactivateBenefit}
                          />
                        </ModalDialog>
                        <ModalDialog
                          title={
                            BenefitHistory.Resources.servicesUsedHistoryHeader
                          }
                          open={
                            this.state.isShowBenefitsHistoryModalOpen &&
                            this.state.selectedBenefit == benefit
                          }
                          onCancel={this.hideBenefitsHistoryModal}
                          width={700}
                        >
                          <BenefitHistoryTable
                            benefitId={benefit.id}
                            hiddenColumns={this.benefitHistoryHiddenColumns}
                          />
                        </ModalDialog>

                        <button
                          className="btn-big btn-primary"
                          onClick={() => this.showAssignBenefitModal(benefit)}
                        >
                          {Benefits.Resources.assignText}
                        </button>
                        <ModalDialog
                          title={""}
                          open={
                            this.state.isAssignBenefitModalOpen &&
                            this.state.selectedBenefit == benefit
                          }
                          onCancel={this.hideAssignBenefitModal}
                        >
                          <AssignBenefitForm
                            clientId={this.props.clientId}
                            benefitId={benefit.id}
                            benefitBeneficiaryList={this.getAllBenefitBeneficiaryList()}
                            benefitStartDateOptions={this.getAllBenefitStartDateOptions()}
                            onCancelCallback={this.hideAssignBenefitModal}
                          />
                        </ModalDialog>
                      </div>
                    </td>
                  )}
                  {benefit.TrueProperties.filter(
                    (p) => this.props.hiddenColumns.indexOf(p) < 0
                  ).map((p) => (
                    <td key={p}>{benefit.GetDisplayValueForProperty(p)}</td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
}

export default withRouter(
  connect(
    (state: ApplicationState, ownProps: IProps) => {
      return {
        benefits: state.benefits.benefits,
        hasServerSideErrors: state.benefits.hasServerSideErrors,
        errors: state.benefits.errors,
        ...ownProps,
      };
    },
    {
      getClientBenefits: BenefitsStore.actionCreators.getClientBenefits,
      deactivateBenefit: BenefitsStore.actionCreators.deactivateBenefit,
      deleteBenefit: BenefitsStore.actionCreators.deleteBenefit,
    }
  )(BenefitsTable as any)
);
