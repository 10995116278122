import * as React from "react";
import { DatePicker, TimePicker } from "antd";
import dayjs from "dayjs";
import bind from "bind-decorator";
import { nameof } from "../../utils/utils";

interface IProps {
    labelTitle: string;
    labelSubtitle: string;
    isRequired: boolean;
    date?: string;
    dateName?: string;
    time?: string;
    timeName?: string;
    isDatePickerDisabled?: boolean;
    isTimePickerDisabled?: boolean;
    hideTimePicker?: boolean;
    errors: string[];
    handleOnChange: (name: string, value: string) => void;
}

interface IState {
    date?: string;
    time?: string;
}

export default class AntdDateTimePickerFormWrapper extends React.PureComponent<IProps, IState> {
    constructor(props) {
        super(props);

        this.state = {
            date: this.props.date,
            time: this.props.time
        }

    }

    @bind
    handleOnChange(stateName, name, value) {
        this.setState({
            [stateName]: value
        });

        this.props.handleOnChange(name, value);
    }

    render() {
        return (
            <div className="form-line">
                
                <div className="line-info">
                    <div className="title">{this.props.labelTitle} {this.props.isRequired ? <span className="mandatory">*</span> : null}</div>
                    <div className="description">{this.props.labelSubtitle}</div>
                </div>

                <div className="line-action">
                    <div className="input-group">
                        <DatePicker
                            className={"form-item"}
                            disabled={this.props.isDatePickerDisabled}
                            format="DD-MM-YYYY"
                            value={this.state.date ? dayjs(this.state.date, "DD-MM-YYYY") : null}
                            onChange={(value, dateString) =>
                                this.handleOnChange(nameof(s => s.date), this.props.dateName, dateString)
                            }
                        />
                    </div>
                    {!this.props.hideTimePicker ?
                        <TimePicker
                            className={"form-item"}
                            disabled={this.props.isTimePickerDisabled}
                            format="HH:mm"
                            value={this.state.time ? dayjs(this.state.time, "HH:mm") : null}
                            onChange={(value, timeString) =>
                                this.handleOnChange(nameof(s => s.time), this.props.timeName, timeString)
                            }
                        /> :
                        null}

                    <div>
                        {this.props.errors.map(err => <span key={err} className="field-validation-error">{err}</span>)}
                    </div>

                </div>
            </div>
        );
    }
}