import * as React from "react";
import { Image } from 'antd';

export interface IProps {
    itemUrls: string[];
}

export default class NewImageGalleryViewer extends React.PureComponent<IProps, any> {
    constructor(props) {
        super(props);
    }

    render() {
        return <div className="photographs-container">
            <div className="photographs-list-container" >
                <Image.PreviewGroup>
                {
                    this.props.itemUrls.map(url => {
                        return <Image key={url} src={url} />
                    })
                }
                </Image.PreviewGroup>
            </div>
        </div>
    }
}