import { Action, Reducer } from "redux";
import { AppThunkAction } from ".";
import update from "immutability-helper";
import SpacesService from "../services/SpacesService";
import { getServerSideErrors } from "../utils/utils";
import { IWorkspaceSchedule } from "../models/Spaces/Interfaces/IWorkspaceSchedule";
import LookupService from "../services/LookupService";
import { ISelectListItemForAmenity } from "../models/Common/ISelectListItemForAmenity";

export module AddOrEditOfficeStore {
  export interface IState {
    loading: boolean;

    id: number;
    name?: string;
    address?: string;
    countryId?: string;
    cityId?: string;
    lat?: number;
    lng?: number;
    officeOffersHotDesks?: boolean;
    requireBooking?: boolean;
    maxSeats?: number;
    descriptionEn?: string;
    descriptionEs?: string;
    descriptionPt?: string;
    email?: string;
    phone?: string;
    wifiName?: string;
    wifiPassword?: string;
    accessInstructionsEn?: string;
    accessInstructionsEs?: string;
    accessInstructionsPt?: string;
    localeId?: string;
    schedule?: IWorkspaceSchedule[];
    images: string[];
    amenities: ISelectListItemForAmenity[];

    hasServerSideErrors: boolean;
    errors: string;
  }

  export enum Actions {
    ToggleLoading = "ADD_OR_EDIT_OFFICE_TOGGLE_LOADING",
    Initialize = "ADD_OR_EDIT_OFFICE_INITIALIZE",
    SetOfficeDetails = "ADD_OR_EDIT_OFFICE_SET_OFFICE_DETAILS",
    SetOfficeImages = "ADD_OR_EDIT_OFFICE_SET_OFFICE_IMAGES",
    SetOfficeSchedule = "ADD_OR_EDIT_OFFICE_SET_OFFICE_SCHEDULE",
    SetOfficeAmenities = "ADD_OR_EDIT_OFFICE_SET_OFFICE_AMENITIES",
    SaveOfficeDetails = "ADD_OR_EDIT_OFFICE_SAVE_OFFICE_DETAILS",
    SetErrors = "ADD_OR_EDIT_OFFICE_SET_ERRORS",
  }

  interface IInitialize {
    type: Actions.Initialize;
  }

  interface IToggleLoading {
    type: Actions.ToggleLoading;
    loading: boolean;
  }

  interface ISetOfficeDetails {
    type: Actions.SetOfficeDetails;
    id: number;
    officeDetails: any;
  }

  interface ISetOfficeImages {
    type: Actions.SetOfficeImages;
    images: string[];
  }

  interface ISetOfficeSchedule {
    type: Actions.SetOfficeSchedule;
    schedule: IWorkspaceSchedule[];
  }

  interface ISetOfficeAmenities {
    type: Actions.SetOfficeAmenities;
    amenities: ISelectListItemForAmenity[];
  }

  interface ISetErrors {
    type: Actions.SetErrors;
    hasServerSideErrors: boolean;
    errors: string;
  }

  type KnownAction =
    | IToggleLoading
    | IInitialize
    | ISetOfficeDetails
    | ISetOfficeImages
    | ISetOfficeSchedule
    | ISetErrors
    | ISetOfficeAmenities;

  export const actionCreators = {
    initializeCreatePage:
      (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        dispatch({ type: Actions.Initialize });
      },

    initializeEditPage:
      (id: number): AppThunkAction<KnownAction> =>
      async (dispatch, getState) => {
        dispatch({ type: Actions.ToggleLoading, loading: true });

        var details = await SpacesService.getSpaceDetailsForEdit(id).then(
          (response) => response.value
        );

        dispatch({
          type: Actions.SetOfficeDetails,
          id: id,
          officeDetails: details,
        });
        dispatch({ type: Actions.ToggleLoading, loading: false });
      },

    setOfficeImages:
      (images: string[]): AppThunkAction<KnownAction> =>
      async (dispatch, getState) => {
        dispatch({
          type: Actions.SetOfficeImages,
          images: images,
        });
      },

    setOfficeSchedule:
      (schedule: IWorkspaceSchedule[]): AppThunkAction<KnownAction> =>
      async (dispatch, getState) => {
        dispatch({
          type: Actions.SetOfficeSchedule,
          schedule: schedule,
        });
      },

    setOfficeAmenities:
      (amenities: ISelectListItemForAmenity[]): AppThunkAction<KnownAction> =>
      async (dispatch, getState) => {
        dispatch({
          type: Actions.SetOfficeAmenities,
          amenities: amenities,
        });
      },

    getOfficeAmenities:
      (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        var amenities = await LookupService.getAllAmenities().then(
          (res) => res.value
        );
        dispatch({
          type: Actions.SetOfficeAmenities,
          amenities: amenities,
        });
      },

    saveDetails:
      (
        id?: number,
        name?: string,
        companyId?: number,
        countryId?: string,
        cityId?: string,
        address?: string,
        lat?: number,
        lng?: number,
        requireBooking?: boolean,
        maxSeats?: number,
        descriptionEn?: string,
        descriptionEs?: string,
        descriptionPt?: string,
        email?: string,
        phone?: string,
        wifiName?: string,
        wifiPassword?: string,
        accessInstructionsEn?: string,
        accessInstructionsEs?: string,
        accessInstructionsPt?: string,
        localeId?: string
      ): AppThunkAction<KnownAction> =>
      async (dispatch, getState) => {
        var state = getState().addOrEditOffice;
        var schedule = state.schedule?.map((e, i) => {
          e = e.isClosed == true ? null : e;
          return e;
        });

        var details = {
          name: name,
          companyId: companyId,
          address: address,
          cityId: cityId,
          countryId: countryId,
          lat: lat,
          lng: lng,
          requireBooking: requireBooking,
          maxSeats: maxSeats,
          descriptionEn: !descriptionEn ? null : descriptionEn,
          descriptionEs: !descriptionEs ? null : descriptionEs,
          descriptionPt: !descriptionPt ? null : descriptionPt,
          email: !email ? null : email,
          phone: !phone ? null : phone,
          wifiName: !wifiName ? null : wifiName,
          wifiPassword: !wifiPassword ? null : wifiPassword,
          accessInstructionsEn: !accessInstructionsEn
            ? null
            : accessInstructionsEn,
          accessInstructionsEs: !accessInstructionsEs
            ? null
            : accessInstructionsEs,
          accessInstructionsPt: !accessInstructionsPt
            ? null
            : accessInstructionsPt,
          localeId: localeId,
          images: state.images,
          schedule: schedule,
          amenities: state.amenities,
        };
        dispatch({
          type: Actions.SetOfficeDetails,
          id: id,
          officeDetails: details,
        });

        var serverResponse;
        if (id == null) {
          serverResponse = await SpacesService.addOffice(details).then(
            (response) => response
          );
        } else {
          serverResponse = await SpacesService.updateOffice(id, details).then(
            (response) => response
          );
        }

        var errors = getServerSideErrors(serverResponse);
        var hasServerSideErrors = !errors ? false : true;
        dispatch({
          type: Actions.SetErrors,
          hasServerSideErrors: hasServerSideErrors,
          errors: errors,
        });
      },
  };

  const initialState: IState = {
    loading: false,

    id: null,
    name: "",
    address: "",
    countryId: "",
    cityId: "",
    lat: null,
    lng: null,
    officeOffersHotDesks: true,
    requireBooking: true,
    maxSeats: null,
    descriptionEn: "",
    descriptionEs: "",
    descriptionPt: "",
    email: "",
    phone: "",
    wifiName: "",
    wifiPassword: "",
    accessInstructionsEn: "",
    accessInstructionsEs: "",
    accessInstructionsPt: "",
    localeId: "",
    schedule: new Array(7).fill(undefined).map((e, i) => {
      return {
        openHour: null,
        closeHour: null,
        isClosed: false,
      } as IWorkspaceSchedule;
    }),
    images: [],
    amenities: [],

    hasServerSideErrors: null,
    errors: null,
  };

  export const reducer: Reducer<IState> = (
    currentState: IState,
    incomingAction: Action
  ) => {
    const action = incomingAction as KnownAction;

    switch (action.type) {
      case Actions.Initialize:
        return initialState;
      case Actions.ToggleLoading:
        return { ...currentState, loading: action.loading };
      case Actions.SetOfficeDetails:
        var scheduleClone = action.officeDetails.schedule.map((e, i) => {
          e =
            e == null
              ? ({
                  openHour: null,
                  closeHour: null,
                  isClosed: true,
                } as IWorkspaceSchedule)
              : e;
          return e;
        });
        return update(currentState, {
          id: { $set: action.id },
          name: { $set: action.officeDetails.name },
          address: { $set: action.officeDetails.address },
          countryId: { $set: action.officeDetails.countryId },
          cityId: { $set: action.officeDetails.cityId },
          lat: { $set: action.officeDetails.lat },
          lng: { $set: action.officeDetails.lng },
          officeOffersHotDesks: {
            $set: action.officeDetails.maxSeats == 0 ? false : true,
          },
          requireBooking: { $set: action.officeDetails.requireBooking },
          maxSeats: { $set: action.officeDetails.maxSeats },
          descriptionEn: { $set: action.officeDetails.descriptionEn },
          descriptionEs: { $set: action.officeDetails.descriptionEs },
          descriptionPt: { $set: action.officeDetails.descriptionPt },
          email: { $set: action.officeDetails.email },
          phone: { $set: action.officeDetails.phone },
          wifiName: { $set: action.officeDetails.wifiName },
          wifiPassword: { $set: action.officeDetails.wifiPassword },
          accessInstructionsEn: {
            $set: action.officeDetails.accessInstructionsEn,
          },
          accessInstructionsEs: {
            $set: action.officeDetails.accessInstructionsEs,
          },
          accessInstructionsPt: {
            $set: action.officeDetails.accessInstructionsPt,
          },
          localeId: { $set: action.officeDetails.localeId },
          schedule: { $set: scheduleClone },
          amenities: { $set: action.officeDetails.amenities },
          images: { $set: action.officeDetails.images },
        });
      case Actions.SetOfficeImages:
        return { ...currentState, images: action.images };
      case Actions.SetOfficeSchedule:
        return { ...currentState, schedule: action.schedule };
      case Actions.SetOfficeAmenities:
        return { ...currentState, amenities: action.amenities };
      case Actions.SetErrors:
        return update(currentState, {
          hasServerSideErrors: { $set: action.hasServerSideErrors },
          errors: { $set: action.errors },
        });
      default:
        return currentState || initialState;
    }
  };
}
