import React from "react";
import ManageAccounts from "../../../resources/AdminUI/ManageAccounts";
import FormInput from "../../Shared/FormInput";
import { EntityFieldInputType } from "../../../utils/reactUtils";
import { nameof } from "../../../utils/utils";
import { ValidatedComponent } from "../../FormValidations/Base/ValidatedComponent";
import bind from "bind-decorator";
import General from "../../../resources/AdminUI/General";
import { AddOrEditSpaceCredentialsValidator } from "../../FormValidations/Validators/AddOrEditSpaceCredentialsValidator";
import { connect } from "react-redux";
import { ApplicationState } from "../../../store";
import { ManageAccountStore } from "../../../store/ManageAccountsStore";
import Validations from "../../../resources/Common/Validations";
import { ISpaceGroupAccount } from "../../../models/ManageAccounts/ISpaceGroupAccount";
import { ICompanyAccount } from "../../../models/ManageAccounts/ICompanyAccount";

interface IProps {
    account: ISpaceGroupAccount & ICompanyAccount,
    hasServerSideErrors?: boolean;
    errors?: string;
    validator?: any;

    saveCompleted?: boolean;
    onCancelCallback: () => void;
    onSaveCallback: (account: ISpaceGroupAccount & ICompanyAccount) => void;
    resetErrors?: () => void
}

interface IState {
    account: ISpaceGroupAccount & ICompanyAccount
}

class ResetPasswordDialog extends ValidatedComponent<IProps, IState> {
    constructor(props) {
        super(props)

        this.state = {
            account: {
                id: this.props.account.id,
                email: this.props.account.email,
                password: null,
                groupId: this.props.account.groupId || null,
                companyId: this.props.account.companyId || null,
                isPasswordReset: true
            }
        }

        this.props.resetErrors();
    }

    @bind
    handleInputChange(name: string, data: any) {
        this.setState({
            account: {
                ...this.state.account,
                [name]: data
            }
        });
    }

    @bind
    handleOnResetPassword() {
        this.validate(() => {
            this.props.onSaveCallback(this.state.account)
        });
    }

    componentDidUpdate(prevProps: Readonly<IProps & IProps>, prevState: Readonly<IState & IState>, snapshot?: any): void {
        if (this.props.saveCompleted) {
            this.props.onCancelCallback();
        }
    }

    render() {
        return <div className="modal-container">

            <div className="modal-title">{ManageAccounts.Resources.resetPasswordDialogHeader}</div>

            <div className="modal-description">{ManageAccounts.Resources.resetPasswordDialogDesc}</div>

            <div className="form-line">
                <div className="line-info">
                    <div className="title in-modal">{ManageAccounts.Resources.newPasswordLabel}</div>
                </div>
                <div className="line-action">
                    <FormInput
                        placeholder={ManageAccounts.Resources.newPasswordLabel}
                        inputType={EntityFieldInputType.Password}
                        className={"form-control"}
                        name={nameof(s => s.password)}
                        onChange={this.handleInputChange}
                        errors={this.getFieldErrors(nameof(s => s.password))}
                        value={this.state.account.password}
                    />
                </div>
            </div>

            {this.props.hasServerSideErrors ? <div><span className="field-validation-error" style={{ color: "red" }}>{this.props.errors}</span></div> : <></>}

            {this.state.errors && Object.keys(this.state.errors).length ?
                <div>
                    < div >
                        <span className="field-validation-error" style={{ color: "red" }}>{Validations.Resources.notAllFieldsAreValid}</span></div>
                        {Object.values(this.state.errors).map(errorValues => {
                            return (errorValues as any).map(err => <span key={err} className="field-validation-error">{err}</span>)
                        })}
                </div>
                :
                <></>
            }

            <div className="form-line form-bottom-actions">
                <button className="btn-big btn-secondary" onClick={this.props.onCancelCallback}>{General.Resources.cancelButtonLabel}</button>
                <button className="btn-big btn-primary" onClick={this.handleOnResetPassword}>{ManageAccounts.Resources.resetPasswordDialogHeader}</button>
            </div>
        </div>
    }
}

export default connect(
    (state: ApplicationState, ownProps: IProps) => {
        return {
            ...ownProps,
            validator: AddOrEditSpaceCredentialsValidator,
            errors: state.manageAccounts.errors,
            hasServerSideErrors: state.manageAccounts.hasServerSideErrors,
            saveCompleted: state.manageAccounts.saveCompleted,
        }
    },
    {
        resetErrors: ManageAccountStore.actionCreators.reset
    }
)(ResetPasswordDialog as any)