import { Action, Reducer } from "redux";
import { AppThunkAction } from ".";
import BenefitService from "../services/BenefitsService";
import update from "immutability-helper";
import { BenefitItem } from "../models/Benefits/BenefitItem";
import { getServerSideErrors } from "../utils/utils";
import ClientsService from "../services/ClientsService";
import UsersService from "../services/UsersService";

export module BenefitsStore {

    export interface IState {
        loading: boolean;
        benefits: BenefitItem[];
        hasServerSideErrors: boolean;
        errors: string;
        userBenefits: BenefitItem[];
    }

    export enum Actions {
        Initialize = "BENEFITS_INITIALIZE_BENEFITS",
        ToggleLoading = "BENEFITS_TOGGLE_LOADING",
        SetBenefits = "BENEFITS_SET_BENEFIT",
        SetErrors = "BENEFITS_SET_ERRORS",
        RemoveBenefit = "BENEFITS_REMOVE_BENEFIT",
        SetUserBenefits = "BENEFITS_SET_USER_BENEFIT",
    }

    interface IInitialize {
        type: Actions.Initialize
    }

    interface IToggleLoading {
        type: Actions.ToggleLoading;
        loading: boolean;
    }

    interface ISetBenefits {
        type: Actions.SetBenefits,
        benefits: BenefitItem[]
    }

    interface ISetErrors {
        type: Actions.SetErrors,
        hasServerSideErrors: boolean,
        errors: string
    }

    interface IRemoveBenefit {
        type: Actions.RemoveBenefit,
        benefitId: number
    }

    interface ISetUserBenefits {
        type: Actions.SetUserBenefits,
        userBenefits: BenefitItem[]
    }

    type KnownAction = IToggleLoading | ISetBenefits | ISetErrors | IRemoveBenefit | ISetUserBenefits | IInitialize;

    export const actionCreators = {
        inititalizeBenefits : (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
            dispatch({type: Actions.Initialize})
        },
        getClientBenefits: (clientId: number): AppThunkAction<KnownAction> => async (dispatch, getState) => {
            dispatch({ type: Actions.ToggleLoading, loading: true });

            var result = await ClientsService.getBenefits(clientId).then(response => response.value);

            var benefits = BenefitService.mapBenefitsToBenefitItemsList(result);

            dispatch({ type: Actions.SetBenefits, benefits: benefits });

            dispatch({ type: Actions.ToggleLoading, loading: false });
        },
        deactivateBenefit: (benefitId: number, deactivateFromCurrentCycle: boolean): AppThunkAction<KnownAction> => async (dispatch, getState) => {
            var serverResponse = await BenefitService.deactivateBenefit(benefitId, deactivateFromCurrentCycle).then(response => response);
            var errors = getServerSideErrors(serverResponse);
            var hasServerSideErrors = errors ? true : false;

            if (hasServerSideErrors) {
                dispatch({
                    type: Actions.SetErrors, hasServerSideErrors: hasServerSideErrors, errors: errors
                });
            } else {
                dispatch({ type: Actions.RemoveBenefit, benefitId: benefitId });
            }
        },
        deleteBenefit: (benefitId: number): AppThunkAction<KnownAction> => async (dispatch, getState) => {
            var serverResponse = await BenefitService.deleteBenefit(benefitId).then(response => response);
            var errors = getServerSideErrors(serverResponse);
            var hasServerSideErrors = errors ? true : false;

            if (hasServerSideErrors) {
                dispatch({
                    type: Actions.SetErrors, hasServerSideErrors: hasServerSideErrors, errors: errors
                });
            } else {
                dispatch({ type: Actions.RemoveBenefit, benefitId: benefitId });
            }
        },
        getUserBenefits: (userId: number): AppThunkAction<KnownAction> => async (dispatch, getState) => {
            dispatch({ type: Actions.ToggleLoading, loading: true });

            var result = await UsersService.getUserBenefits(userId).then(response => response.value);
            var userBenefits = BenefitService.mapBenefitsToBenefitItemsList(result);

            dispatch({ type: Actions.SetUserBenefits, userBenefits: userBenefits });

            dispatch({ type: Actions.ToggleLoading, loading: false });
        }
    }

    const initialState: IState = {
        loading: false,
        benefits: [],
        hasServerSideErrors: false,
        errors: "",
        userBenefits: []
    }

    export const reducer: Reducer<IState> = (currentState: IState, incomingAction: Action) => {
        const action = incomingAction as KnownAction;

        switch (action.type) {
            case Actions.Initialize:
                return initialState;
            case Actions.ToggleLoading:
                return { ...currentState, loading: action.loading }
            case Actions.SetBenefits:
                return update(currentState, {
                    benefits: { $set: action.benefits }
                });
            case Actions.SetErrors:
                return update(currentState, {
                    hasServerSideErrors: { $set: action.hasServerSideErrors },
                    errors: { $set: action.errors },
                });
            case Actions.RemoveBenefit:
                var newBenefits = currentState.benefits.filter(e => e.id != action.benefitId)
                return update(currentState, {
                    benefits: { $set: newBenefits }
                });
            case Actions.SetUserBenefits:
                return update(currentState, {
                    userBenefits: { $set: action.userBenefits }
                });
            default:
                return currentState || initialState;
        }
    }

}