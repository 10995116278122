import React from "react";
import IPageIndex from "../../../models/Pagination/IPageIndex";
import { AdminLogItem } from "../../../models/AccountAndLogs/AdminLogItem";
import bind from "bind-decorator";
import update from "immutability-helper";
import { getPromiseFromAction } from "../../../utils/utils";
import { connect } from "react-redux";
import { ApplicationState } from "../../../store";
import { AdminLogsStore } from "../../../store/AdminLogsStore";
import PaginationBar from "../../Shared/PaginationBar";
import General from "../../../resources/AdminUI/General";
import Loading from "../../Shared/Loading";

interface IProps {
  adminLogs?: AdminLogItem[];
  loading?: boolean;
  hiddenColumns?: string[];
  isAdminLogsListHidden: boolean[];
  filters?: string;
  numberOfAdminLogs?: number;
  itemsPerPage?: number;
  firstIndexFromPage?: number;
  lastIndexFromPage?: number;
  pageIndexArray?: IPageIndex[];

  reload?: () => void;
  changeCurrentPage?: (currentPage: number) => void;
  initialize?: () => void;
}

interface IState {
  isAdminLogsListHidden: boolean[];
}

class AdminLogsTable extends React.PureComponent<any, IState> {
  constructor(props) {
    super(props);

    this.state = {
      isAdminLogsListHidden: [],
    };
  }

  public static defaultProps: Partial<IProps> = {
    loading: false,
  };

  tableClass: string = "table";
  hiddenColumns = ["id", "actionPayload", "adminUserName"];
  expandableColumns = ["actionPayload"];

  itemsPerPage: number = 15;
  maxPagesDisplayed: number = 3;
  pageNeighbours: number = 1;
  hasRefreshButton: boolean = false;

  @bind
  toggleIsExpanded(index) {
    this.setState(
      update(this.state, {
        isAdminLogsListHidden: {
          [index]: { $set: !this.state.isAdminLogsListHidden[index] },
        },
      })
    );
  }

  @bind
  changeCurrentPage(currentPage: number) {
    this.props.changeCurrentPage(currentPage);
    this.setState({ isAdminLogsListHidden: this.props.isAdminLogsListHidden });
  }

  componentDidMount(): void {
    if (!this.props.adminLogs || this.props.adminLogs.length == 0) {
      getPromiseFromAction(
        this.props.initialize(this.itemsPerPage, this.props.filters)
      ).then(() =>
        this.setState({
          isAdminLogsListHidden: this.props.isAdminLogsListHidden,
        })
      );
    } else if (this.props.filters) {
      getPromiseFromAction(this.props.reload()).then(() =>
        this.setState({
          isAdminLogsListHidden: this.props.isAdminLogsListHidden,
        })
      );
    } else {
      this.setState({
        isAdminLogsListHidden: this.props.isAdminLogsListHidden,
      });
    }
  }

  render() {
    if (this.props.loading) {
      return (
        <div>
          <Loading />
        </div>
      );
    }
    if (!this.props.adminLogs || this.props.adminLogs.length == 0) {
      return <div>{General.Resources.noElements}</div>;
    } else {
      return (
        <div>
          <div className="table-wrapper admin-logs-table">
            <table className={this.tableClass}>
              <thead>
                <tr>
                  <th></th>
                  {this.props.adminLogs[0].TrueProperties.filter(
                    (p) => this.hiddenColumns.indexOf(p) < 0
                  ).map((p) => (
                    <th key={p}>
                      {this.props.adminLogs[0].GetDisplayNameForProperty(p)}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {this.props.adminLogs.map((us, index) => {
                  const columCount =
                    us.TrueProperties.filter(
                      (p) => this.hiddenColumns.indexOf(p) < 0
                    ).length + 2;
                  return (
                    <React.Fragment key={us.id}>
                      <tr>
                        <td className="btn-row-expand">
                          <div onClick={() => this.toggleIsExpanded(index)}>
                            <span className="material-symbols-outlined">
                              {this.state.isAdminLogsListHidden.length > 0 &&
                              this.state.isAdminLogsListHidden[index]
                                ? "chevron_right"
                                : "expand_more"}
                            </span>
                          </div>
                        </td>
                        {us.TrueProperties.filter(
                          (p) => this.hiddenColumns.indexOf(p) < 0
                        ).map((p) => (
                          <td
                            data-label={
                              this.props.hasHeader
                                ? this.props.adminLogs[0].GetColumnNameForProperty(
                                    p
                                  )
                                : null
                            }
                            key={p}
                          >
                            {us.GetDisplayValueForProperty(p)}
                          </td>
                        ))}
                      </tr>
                      <tr
                        className={
                          this.state.isAdminLogsListHidden.length > 0 &&
                          this.state.isAdminLogsListHidden[index]
                            ? "hidden-row"
                            : null
                        }
                      >
                        {us.TrueProperties.filter(
                          (p) => this.expandableColumns.indexOf(p) >= 0
                        ).map((p) => (
                          <td
                            className="colspan-row"
                            colSpan={columCount}
                            data-label={
                              this.props.hasHeader
                                ? this.props.adminLogs[0].GetColumnNameForProperty(
                                    p
                                  )
                                : null
                            }
                            key={p}
                          >
                            {us.GetDisplayValueForProperty(p)}
                          </td>
                        ))}
                      </tr>
                    </React.Fragment>
                  );
                })}
              </tbody>
            </table>
          </div>
          <PaginationBar
            useItemsPerPageOptions={false}
            totalItems={this.props.numberOfAdminLogs}
            reload={this.props.reload}
            changeCurrentPage={this.changeCurrentPage}
            itemsPerPage={this.props.itemsPerPage}
            firstIndexFromPage={this.props.firstIndexFromPage}
            lastIndexFromPage={this.props.lastIndexFromPage}
            pageIndexArray={this.props.pageIndexArray}
            maxPagesDisplayed={this.maxPagesDisplayed}
            pageNeighbours={this.pageNeighbours}
            hasRefreshButton={this.hasRefreshButton}
          />
        </div>
      );
    }
  }
}

export default connect(
  (state: ApplicationState, ownProps: any) => {
    return state.adminLogs;
  },
  {
    reload: AdminLogsStore.actionCreators.reload,
    changeCurrentPage: AdminLogsStore.actionCreators.changeCurrentPage,
    initialize: AdminLogsStore.actionCreators.initialize,
  }
)(AdminLogsTable);
