import bind from 'bind-decorator';
import React from 'react'
import { Dropdown } from 'antd';
import IDropdownButtonItem from '../../models/Common/IDropdownButtonItem';

interface IProps {
    text?: string,
    icon?: any,
    items: IDropdownButtonItem[],
    className?: string
}

export default class DropdownButton extends React.PureComponent<IProps, any>{
    constructor(props) {
        super(props)
        this.state = {
            isOpen: false
        }
    }

    @bind
    handleOnClick() {
        this.setState({ isOpen: !this.state.isOpen });
    }

    render() {

        const menu = {
            items: this.props.items.map((e, i) => {
                return {
                    key: String(i),
                    label: <a onClick={e.action} className='dropdown-option'>
                        {e.icon && <span className='material-symbols-outlined'>{e.icon}</span>} {e.text}
                    </a>
                }
            })
        }

        return (
            <div className='dropdown-menu'>
                <Dropdown menu={menu} overlayClassName="dropdown-menu-overlay">
                    <button className={this.props.className}>
                        {this.props.text}
                        {this.props.icon && <span className='material-symbols-outlined'>{this.props.icon}</span>}
                    </button>
                </Dropdown>
            </div>
        );
    }
}