import * as React from "react";

export interface IProps {
    price: number;
    currency: string;
}

// TODO: use everywhere a price is displayed, also create a similar component for price input
export default class PriceLabel extends React.PureComponent<IProps, {}> {
    constructor(props) {
        super(props);
    }

    render() {
        return <span className="price-label">
            <span className="price">{this.props.price / 100}</span>
            &nbsp;
            <span className="currency">{this.props.currency}</span>
        </span>;
    }
}