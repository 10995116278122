import { Action, Reducer } from "redux";
import update from "immutability-helper";
import { AppThunkAction } from ".";
import { IAddOrEditClient } from "../models/Clients/Interfaces/IAddOrEditClient";
import ClientsService from "../services/ClientsService";
import { getServerSideErrors } from "../utils/utils";

export module AddOrEditClientStore {

    export interface IState {
        loading: boolean;
        clientDetails: IAddOrEditClient;
        hasServerSideErrors: boolean;
        errors: string;
        saveCompleted: boolean;
    }

    export enum Actions {
        ToggleLoading = "ADD_EDIT_CLIENT_TOGGLE_LOADING",
        Initialize = "ADD_EDIT_CLIENT_INITIALIZE",
        SetClientDetails = "ADD_EDIT_CLIENT_SET_CLIENT_DETAILS",
        SaveClientDetails = "ADD_EDIT_CLIENT_SAVE_CLIENT_DETAILS",
        SetErrors = "ADD_EDIT_CLIENT_SET_ERRORS",
        SaveCompleted = "ADD_EDIT_CLIENT_SAVE_COMPLETED",
        SetClientId = "ADD_EDIT_CLIENT_SET_CLIENT_ID"
    }

    interface IInitialize {
        type: Actions.Initialize;
    }

    interface IToggleLoading {
        type: Actions.ToggleLoading;
        loading: boolean;
    }

    interface ISetClientDetails {
        type: Actions.SetClientDetails;
        clientDetails: IAddOrEditClient;
    }

    interface ISaveClientDetails {
        type: Actions.SaveClientDetails;
        clientDetails: IAddOrEditClient;
    }

    interface ISetErrors {
        type: Actions.SetErrors,
        hasServerSideErrors: boolean,
        errors: string
    }

    interface ISaveCompleted {
        type: Actions.SaveCompleted,
        saveCompleted: boolean
    }

    interface ISetClientId {
        type: Actions.SetClientId,
        id: number
    }

    type KnownAction = IToggleLoading |
        IInitialize | ISetClientDetails | ISaveClientDetails | ISetErrors | ISaveCompleted | ISetClientId;

    export const actionCreators = {
        initializeCreatePage: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
            dispatch({ type: Actions.Initialize });
        },

        initializeEditPage: (id: number): AppThunkAction<KnownAction> => async (dispatch, getState) => {
            dispatch({ type: Actions.ToggleLoading, loading: true });

            var details: IAddOrEditClient = await ClientsService.getClientDetailsForEdit(id).then(response => response.value);

            dispatch({ type: Actions.SetClientDetails, clientDetails: details });

            dispatch({ type: Actions.ToggleLoading, loading: false });
        },
        saveClient: (clientDetails: IAddOrEditClient): AppThunkAction<KnownAction> => async (dispatch, getState) => {
            dispatch({ type: Actions.SetClientDetails, clientDetails: clientDetails });

            var serverResponse, clientId;

            if (clientDetails.id == null) {
                serverResponse = await ClientsService.addClient(clientDetails).then(response => response);
                clientId = serverResponse.value;
            } else {
                serverResponse = await ClientsService.updateClient(clientDetails).then(response => response);
                clientId = clientDetails.id;
            }

            var errors = getServerSideErrors(serverResponse);
            var hasServerSideErrors = !errors ? false : true;
            

            if (hasServerSideErrors) {
                dispatch({ type: Actions.SetErrors, hasServerSideErrors: hasServerSideErrors, errors: errors });
            } else {
                dispatch({ type: Actions.SetClientId, id: clientId });
                dispatch({ type: Actions.SaveCompleted, saveCompleted: true });
            }
        },
        resetError: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
            dispatch({ type: Actions.SetErrors, hasServerSideErrors: false, errors: '' });
            dispatch({ type: Actions.SaveCompleted, saveCompleted: false });
        }
    }

    const inititalClientDetails: IAddOrEditClient = {
        id: null,
        name: '',
        countryId: null,
        currency: null,
        legalClientName: '',
        industry: null,
        noEmployees: null,
        details: '',
        subscriptionId: null,
        subscriptionName: null,
        subscriptionPrice: 0,
        subscriptionStartDate: "",
        paymentRecurrenceValue: null,
        paymentRecurrenceUnit: null,
        accountManagerEmail: "",
        contactPhone: "",
        contactEmail: "",
        emailDomains: [],
        salesPersonEmail: "",
        cui: "",
        subscriptionMetadata: {
            vat: null,
            platformAccessFee: null,
            prepaid: null,
            minimumSpend: null
        }
    }

    const initialState: IState = {
        loading: false,
        clientDetails: inititalClientDetails,
        hasServerSideErrors: null,
        errors: null,
        saveCompleted: false
    }

    export const reducer: Reducer<IState> = (currentState: IState, incomingAction: Action) => {
        const action = incomingAction as KnownAction;

        switch (action.type) {
            case Actions.Initialize:
                return initialState;
            case Actions.ToggleLoading:
                return { ...currentState, loading: action.loading }
            case Actions.SetClientDetails:
                return update(currentState,
                    {
                        clientDetails: { $set: action.clientDetails }
                    });
            case Actions.SetErrors:
                return update(currentState,
                    {
                        hasServerSideErrors: { $set: action.hasServerSideErrors },
                        errors: { $set: action.errors },
                    });
            case Actions.SaveCompleted:
                return update(currentState,
                    {
                        saveCompleted: { $set: action.saveCompleted }
                    });
            case Actions.SetClientId:
                return {
                    ...currentState,
                    clientDetails: {
                        ...currentState.clientDetails,
                        id: action.id
                    }
                };
            default:
                return currentState || initialState;
        }
    }
}

