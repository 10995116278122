import React from "react";
import { IBenefit } from "../../../models/Benefits/Interfaces/IBenefit";
import BenefitsTable from "../../Shared/BenefitsTable";
import { BenefitItem } from "../../../models/Benefits/BenefitItem";
import General from "../../../resources/AdminUI/General";
import UserBenefits from "../../../resources/AdminUI/Benefits/UserBenefits";
import { connect } from "react-redux";
import { ApplicationState } from "../../../store";
import { BenefitsStore } from "../../../store/BenefitsStore";
import Loading from "../../Shared/Loading";

interface IProps {
  userId: number;
  userName: string;
  loading?: boolean;
  benefits?: IBenefit[];

  getUserBenefits?: (userId: number) => void;
}

class UserBenefitsContainer extends React.PureComponent<IProps, any> {
  constructor(props) {
    super(props);

    this.props.getUserBenefits(this.props.userId);
  }

  hiddenColumns = [
    "id",
    "value",
    "currency",
    "userUsageLimit",
    "renewIntervalUnitId",
    "nextRenewAt",
    "companyId",
    "subscriptionName",
    "voucherId",
    "startFromCurrentCycle",
    "isUnlimited",
    "renewMaxCycles",
    "actions",
    "hasUserLimit",
  ];

  render() {
    if (this.props.loading) {
      return (
        <div>
          <Loading />
        </div>
      );
    } else {
      return (
        <div>
          <div className="section-top">
            <div className="section-title">
              {UserBenefits.Resources.userServicesText}
            </div>
          </div>

          {this.props.benefits.length ? (
            <BenefitsTable
              userId={this.props.userId}
              beneficiaryName={this.props.userName}
              benefits={this.props.benefits as BenefitItem[]}
              hiddenColumns={this.hiddenColumns}
            />
          ) : (
            <p>{UserBenefits.Resources.noBenefitsText}</p>
          )}
        </div>
      );
    }
  }
}

export default connect(
  (state: ApplicationState, ownProps: IProps) => {
    return {
      ...ownProps,
      loading: state.benefits.loading,
      benefits: state.benefits.userBenefits,
    };
  },
  {
    getUserBenefits: BenefitsStore.actionCreators.getUserBenefits,
  }
)(UserBenefitsContainer as any);
