import bind from "bind-decorator";
import React from "react";
import { connect } from "react-redux";
import { ISelectListItemForAmenity } from "../../models/Common/ISelectListItemForAmenity";
import { IWorkspaceSchedule } from "../../models/Spaces/Interfaces/IWorkspaceSchedule";
import { ValidatedComponent } from "../FormValidations/Base/ValidatedComponent";
import General from "../../resources/AdminUI/General";
import { ISelectListItem } from "../../models/Common/ISelectListItem";
import { getPromiseFromAction, nameof } from "../../utils/utils";
import FormInput from "../Shared/FormInput";
import { EntityFieldInputType } from "../../utils/reactUtils";
import ImageGalleryUpload from "../Shared/ImageGalleryUpload";
import { AddOrEditRoomStore } from "../../store/AddOrEditRoomStore";
import { ApplicationState } from "../../store";
import ScheduleForm from "../Spaces/ScheduleForm";
import { RoomAmenitiesForm } from "../Spaces/AmenitiesForm";
import AddOrEditWorkspaceRoomForm from "../../resources/AdminUI/Rooms/AddOrEditWorkspaceRoomForm";
import { AddOrEditRoomFormValidator } from "../FormValidations/Validators/AddOrEditRoomFormValidator";
import { ScheduleTab } from "../Tabs/ScheduleTab";
import { RoomCategories } from "../../enums/Rooms/RoomCategories";
import DescriptionTextareaFormTabsComponent from "../Tabs/DescriptionTextareaFormTabsComponent";
import { ApplicationPathBuilder } from "../../enums/Common/ApplicationPaths";
import { NavigateFunction } from "react-router";
import { withRouter } from "../Shared/withRouter";
import ModalDialog from "../Shared/ModalDialog";
import Validations from "../../resources/Common/Validations";

export interface IProps {
  id?: number;
  workspaceId?: number;
  name?: string;
  externalBookingProviderRefId?: string;
  roomTypeId?: string;
  descriptionEn?: string;
  descriptionEs?: string;
  descriptionPt?: string;
  email?: string;
  phone?: string;
  schedule?: IWorkspaceSchedule[];
  workspaceSchedule?: IWorkspaceSchedule[];
  isWorkspaceScheduleUsed?: boolean;
  images?: string[];
  amenities?: ISelectListItemForAmenity[];
  maxGuests?: number;
  minHourlyBookingAmount?: number;
  minDailyBookingAmount?: number;
  hourPrice?: number;
  dayPrice?: number;
  monthPrice?: number;
  roomTypes?: ISelectListItem[];
  hasServerSideErrors?: boolean;
  errors?: string;
  navigate?: NavigateFunction;
  getRoomWorkspaceSchedule?: (schedule: IWorkspaceSchedule[]) => void;
  setRoomImages?: (images: string[]) => void;
  setRoomSchedule?: (schedule: IWorkspaceSchedule[]) => void;
  setRoomAmenities?: (amenities: ISelectListItemForAmenity[]) => void;

  saveDetails?: (
    id: number,
    workspaceId?: number,
    name?: string,
    externalBookingProviderRefId?: string,
    roomTypeId?: string,
    descriptionEn?: string,
    descriptionEs?: string,
    descriptionPt?: string,
    email?: string,
    phone?: string,
    maxGuests?: number,
    minHourlyBookingTime?: number,
    minDailyBookingTime?: number,
    hourPrice?: number,
    dayPrice?: number,
    monthPrice?: number,
    isWorkspaceScheduleUsed?: boolean
  ) => any;
}

export interface IState {
  id?: number;
  name?: string;
  externalBookingProviderRefId?: string;
  roomTypeId?: string;
  descriptionEn?: string;
  descriptionEs?: string;
  descriptionPt?: string;
  email?: string;
  phone?: string;
  maxGuests?: number;
  minHourlyBookingAmount?: number;
  minDailyBookingAmount?: number;
  isWorkspaceScheduleUsed?: boolean;
  roomCanBeBookedHourly?: boolean;
  roomCanBeBookedDaily?: boolean;
  roomCanBeBookedMonthly?: boolean;
  hourPrice?: number;
  dayPrice?: number;
  monthPrice?: number;
  isAmenitiesModalOpen?: boolean;
  isScheduleModalOpen?: boolean;
}

class AddOrEditRoomForm extends ValidatedComponent<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.id,
      name: this.props.name,
      externalBookingProviderRefId: this.props.externalBookingProviderRefId,
      roomTypeId: this.props.roomTypeId,
      descriptionEn: this.props.descriptionEn,
      descriptionEs: this.props.descriptionEs,
      descriptionPt: this.props.descriptionPt,
      email: this.props.email,
      phone: this.props.phone,
      maxGuests: this.props.maxGuests,
      minHourlyBookingAmount: this.props.minHourlyBookingAmount,
      minDailyBookingAmount: this.props.minDailyBookingAmount,
      roomCanBeBookedHourly: !this.props.hourPrice ? false : true,
      roomCanBeBookedDaily: !this.props.dayPrice ? false : true,
      roomCanBeBookedMonthly: !this.props.monthPrice ? false : true,
      hourPrice: this.props.hourPrice,
      dayPrice: this.props.dayPrice,
      monthPrice: this.props.monthPrice,
      isWorkspaceScheduleUsed: this.props.isWorkspaceScheduleUsed,
      isAmenitiesModalOpen: false,
      isScheduleModalOpen: false,
    };
  }

  @bind
  hideAmenitiesModal() {
    this.setState({ isAmenitiesModalOpen: false });
  }

  @bind
  showAmenitiesModal() {
    this.setState({ isAmenitiesModalOpen: true });
  }

  @bind
  hideScheduleModal() {
    this.setState({ isScheduleModalOpen: false });
  }

  @bind
  showScheduleModal() {
    this.setState({ isScheduleModalOpen: true });
  }

  @bind
  handleInputChange(name: string, data: any) {
    this.setState({
      [name]: data,
    });
  }

  @bind
  onKeyDownForPriceInput(e: any) {
    if (["e", "E", "+", "-"].includes(e.key)) {
      e.preventDefault();
    }
  }

  @bind
  onKeyDownForNumberInput(e: any) {
    if (["e", "E", "+", "-", "."].includes(e.key)) {
      e.preventDefault();
    }
  }

  @bind
  saveDetails() {
    this.validate(() => {
      getPromiseFromAction(
        this.props.saveDetails(
          this.props.id,
          this.props.workspaceId,
          this.state.name,
          this.state.externalBookingProviderRefId,
          this.state.roomTypeId,
          this.state.descriptionEn,
          this.state.descriptionEs,
          this.state.descriptionPt,
          this.state.email,
          this.state.phone,
          this.state.maxGuests,
          this.state.minHourlyBookingAmount,
          this.state.minDailyBookingAmount,
          this.state.hourPrice,
          this.state.dayPrice,
          this.state.monthPrice,
          this.state.isWorkspaceScheduleUsed
        )
      ).then((e) => {
        if (!this.props.hasServerSideErrors) {
          this.props.navigate(
            ApplicationPathBuilder.ViewSpaceDetails(
              this.props.workspaceId.toString()
            )
          );
        }
      });
    });
  }

  componentDidMount(): void {
    if (!this.props.schedule || this.props.schedule.length === 0) {
      this.handleInputChange(
        nameof((e) => e.isWorkspaceScheduleUsed),
        true
      );
    }
  }

  render() {
    return (
      <div className="form-container">
        <div className="form-line">
          <div className="line-info">
            <div className="title">
              {AddOrEditWorkspaceRoomForm.Resources.typeOfRoom}
              <span className="mandatory">*</span>
            </div>
          </div>

          <div className="line-action">
            <FormInput
              placeholder="Name"
              options={this.props.roomTypes}
              inputType={EntityFieldInputType.Radio}
              className={"form-control room_type_radio_box"}
              value={this.state.roomTypeId}
              name={nameof((s) => s.roomTypeId)}
              onChange={this.handleInputChange}
              errors={this.getFieldErrors(nameof((s) => s.roomTypeId))}
            />
          </div>
        </div>

        <div className="form-line">
          <div className="line-info">
            <div className="title">
              {AddOrEditWorkspaceRoomForm.Resources.roomName}
              <span className="mandatory">*</span>
            </div>
            <div className="description">
              {
                AddOrEditWorkspaceRoomForm.Resources
                  .nameWillBeVisibleInMobileApplication
              }
            </div>
          </div>
          <div className="line-action">
            <FormInput
              placeholder="Name"
              inputType={EntityFieldInputType.Text}
              className={"form-control"}
              value={this.state.name}
              name={nameof((s) => s.name)}
              onChange={this.handleInputChange}
              errors={this.getFieldErrors(nameof((s) => s.name))}
            />
          </div>
        </div>

        <div className="form-line">
          <div className="line-info">
            <div className="title">
              {AddOrEditWorkspaceRoomForm.Resources.externalCalendarId}
            </div>
          </div>
          <div className="line-action">
            <FormInput
              placeholder={
                AddOrEditWorkspaceRoomForm.Resources.externalCalendarId
              }
              inputType={EntityFieldInputType.Text}
              className={"form-control"}
              value={this.state.externalBookingProviderRefId}
              name={nameof((s) => s.externalBookingProviderRefId)}
              onChange={this.handleInputChange}
              errors={this.getFieldErrors(
                nameof((s) => s.externalBookingProviderRefId)
              )}
            />
          </div>
        </div>

        <h2 className="form-divider">
          {AddOrEditWorkspaceRoomForm.Resources.presentationDetails}
        </h2>

        <div className="form-line">
          <div className="line-info">
            <div className="title">
              {AddOrEditWorkspaceRoomForm.Resources.fullDescription}
            </div>
            <div className="description">
              {
                AddOrEditWorkspaceRoomForm.Resources
                  .recommendBetween200And400Characters
              }
            </div>
          </div>
          <div className="line-action">
            <DescriptionTextareaFormTabsComponent
              componentId={`room-description-${this.props.id}`}
              textEn={this.props.descriptionEn}
              nameEn={nameof((s) => s.descriptionEn)}
              placeholderEn={
                AddOrEditWorkspaceRoomForm.Resources.fullDescription
              }
              textEs={this.props.descriptionEs}
              nameEs={nameof((s) => s.descriptionEs)}
              placeholderEs={
                AddOrEditWorkspaceRoomForm.Resources.fullDescription
              }
              textPt={this.props.descriptionPt}
              namePt={nameof((s) => s.descriptionPt)}
              placeholderPt={
                AddOrEditWorkspaceRoomForm.Resources.fullDescription
              }
              onChangeCallback={this.handleInputChange}
            />
          </div>
        </div>

        <div className="form-line">
          <div className="line-info">
            <div className="title">
              {AddOrEditWorkspaceRoomForm.Resources.roomPhotographs}
            </div>
            <div className="description">
              {AddOrEditWorkspaceRoomForm.Resources.acceptedFormats}
            </div>
          </div>
          <div className="line-action">
            {this.getFieldErrors(nameof((s) => s.images)).map((err) => (
              <span key={err} className="field-validation-error">
                {err}
              </span>
            ))}
            <ImageGalleryUpload
              images={this.props.images}
              onSaveCallback={this.props.setRoomImages}
            />
          </div>
        </div>

        <div className="form-line">
          <div className="line-info">
            <div className="title">
              {AddOrEditWorkspaceRoomForm.Resources.contactInformation}
            </div>
            <div className="description">
              {
                AddOrEditWorkspaceRoomForm.Resources
                  .roomPhoneNumberAndEmailAddressWillBeDisplayedInAppForUsers
              }
            </div>
          </div>
          <div className="line-action">
            <FormInput
              placeholder={AddOrEditWorkspaceRoomForm.Resources.emailAddress}
              inputType={EntityFieldInputType.Text}
              className={"form-control"}
              value={this.state.email}
              name={nameof((s) => s.email)}
              onChange={this.handleInputChange}
              errors={this.getFieldErrors(nameof((s) => s.email))}
            />

            <FormInput
              placeholder={AddOrEditWorkspaceRoomForm.Resources.phoneNumber}
              inputType={EntityFieldInputType.Text}
              className={"form-control"}
              value={this.state.phone}
              name={nameof((s) => s.phone)}
              onChange={this.handleInputChange}
              errors={this.getFieldErrors(nameof((s) => s.phone))}
            />
          </div>
        </div>

        <div className="form-line">
          <div className="line-info">
            <div className="title">
              {AddOrEditWorkspaceRoomForm.Resources.roomAmenities}
            </div>
            <div className="description">
              {
                AddOrEditWorkspaceRoomForm.Resources
                  .selectFromTheListAndHighlightTheImportantOnes
              }
            </div>
          </div>
          <div className="line-action">
            <button
              className="btn-big btn-secondary in-form"
              onClick={() => this.showAmenitiesModal()}
            >
              {AddOrEditWorkspaceRoomForm.Resources.selectRoomAmenities}
            </button>
            <ModalDialog
              title={AddOrEditWorkspaceRoomForm.Resources.selectRoomAmenities}
              open={this.state.isAmenitiesModalOpen}
              onCancel={this.hideAmenitiesModal}
            >
              <RoomAmenitiesForm
                amenities={this.props.amenities}
                additionalInofrmation={
                  AddOrEditWorkspaceRoomForm.Resources
                    .pleaseSelectAllAvailableAmenitiesHighlightImportantAmenitiesAndTheyWillAppearOnTheMainSpaceDetailsScreen
                }
                onSaveCallback={this.props.setRoomAmenities}
                onCloseCallback={this.hideAmenitiesModal}
              ></RoomAmenitiesForm>
            </ModalDialog>

            {this.props.amenities && this.props.amenities.length > 0 ? (
              <div>
                <div className="form-selection-displayed">
                  <span>{General.Resources.highlighted}</span>
                  {this.props.amenities
                    .filter((e) => e.selected && e.isHighlighted)
                    .map((e) => e.text)
                    .join("| ")}
                </div>
                <div className="form-selection-displayed">
                  <span>{General.Resources.others}</span>
                  {this.props.amenities
                    .filter((e) => e.selected && !e.isHighlighted)
                    .map((e) => e.text)
                    .join("| ")}
                </div>
              </div>
            ) : null}
          </div>
        </div>

        <div className="form-line">
          <div className="line-info">
            <div className="title">
              {AddOrEditWorkspaceRoomForm.Resources.roomOpeningHours}
            </div>
          </div>
          <div className="line-action">
            <label className="form-check">
              <input
                type="checkbox"
                checked={this.state.isWorkspaceScheduleUsed}
                name={nameof((e) => e.isWorkspaceScheduleUsed)}
                onChange={(event) => {
                  this.handleInputChange(
                    nameof((e) => e.isWorkspaceScheduleUsed),
                    event.target.checked
                  );
                }}
              />
              <label>
                {AddOrEditWorkspaceRoomForm.Resources.useWorkspaceSchedule}
              </label>
            </label>
            <div>
              <button
                className={
                  this.state.isWorkspaceScheduleUsed
                    ? "btn-big btn-secondary disabled"
                    : "btn-big btn-secondary in-form"
                }
                disabled={this.state.isWorkspaceScheduleUsed}
                onClick={this.showScheduleModal}
              >
                {AddOrEditWorkspaceRoomForm.Resources.selectRoomSchedule}
              </button>
              <ModalDialog
                footer={null}
                title={AddOrEditWorkspaceRoomForm.Resources.selectRoomSchedule}
                open={this.state.isScheduleModalOpen}
                onCancel={this.hideScheduleModal}
              >
                <ScheduleForm
                  schedule={this.props.schedule ?? this.props.workspaceSchedule}
                  additionalInofrmation={
                    AddOrEditWorkspaceRoomForm.Resources
                      .pleaseCompleteEverydaysProgramIfTheRoomIsClosed
                  }
                  onSaveCallback={this.props.setRoomSchedule}
                  onCloseCallback={this.hideScheduleModal}
                ></ScheduleForm>
              </ModalDialog>

              {this.state?.isWorkspaceScheduleUsed ? (
                <div>
                  <ScheduleTab schedule={this.props?.workspaceSchedule} />
                </div>
              ) : (
                <div>
                  {this.props.schedule?.filter(
                    (e) =>
                      e.openHour == null && e.closeHour == null && !e.isClosed
                  ).length > 0 ? null : (
                    <ScheduleTab schedule={this.props?.schedule} />
                  )}
                </div>
              )}
            </div>
          </div>
        </div>

        <h2 className="form-divider">
          {AddOrEditWorkspaceRoomForm.Resources.bookingRequirements}
        </h2>

        <div className="form-line">
          <div className="line-info">
            <div className="title">
              {AddOrEditWorkspaceRoomForm.Resources.maximumNoOfGuests}
              <span className="mandatory">*</span>
            </div>
          </div>
          <div className="line-action">
            <FormInput
              placeholder={
                AddOrEditWorkspaceRoomForm.Resources.maximumNoOfGuests
              }
              inputType={EntityFieldInputType.Number}
              className={"form-control"}
              value={this.state.maxGuests}
              name={nameof((s) => s.maxGuests)}
              onChange={this.handleInputChange}
              onKeyDown={this.onKeyDownForNumberInput}
              errors={this.getFieldErrors(nameof((s) => s.maxGuests))}
            />
          </div>
        </div>

        <div className="form-line">
          <div className="line-info">
            <div className="title">
              {AddOrEditWorkspaceRoomForm.Resources.minimumHourlyBookingTime}
              <span className="mandatory">*</span>
            </div>
            <div className="description">
              {
                AddOrEditWorkspaceRoomForm.Resources
                  .ifRequiredEnterAMinimumBookingTimeForHourlyBookings
              }
            </div>
          </div>
          <div className="line-action">
            <FormInput
              placeholder={
                AddOrEditWorkspaceRoomForm.Resources.minimumHourlyBookingTime
              }
              inputType={EntityFieldInputType.Number}
              className={"form-control"}
              value={this.state.minHourlyBookingAmount}
              name={nameof((s) => s.minHourlyBookingAmount)}
              onChange={this.handleInputChange}
              onKeyDown={this.onKeyDownForNumberInput}
              errors={this.getFieldErrors(
                nameof((s) => s.minHourlyBookingAmount)
              )}
            />
          </div>
        </div>

        <div className="form-line">
          <div className="line-info">
            <div className="title">
              {AddOrEditWorkspaceRoomForm.Resources.minimumDailyBookingTime}
              <span className="mandatory">*</span>
            </div>
            <div className="description">
              {
                AddOrEditWorkspaceRoomForm.Resources
                  .ifRequiredEnterAMinimumBookingTimeForDailyBookings
              }
            </div>
          </div>
          <div className="line-action">
            <FormInput
              placeholder={
                AddOrEditWorkspaceRoomForm.Resources.minimumDailyBookingTime
              }
              inputType={EntityFieldInputType.Number}
              className={"form-control"}
              value={this.state.minDailyBookingAmount}
              name={nameof((s) => s.minDailyBookingAmount)}
              onChange={this.handleInputChange}
              onKeyDown={this.onKeyDownForNumberInput}
              errors={this.getFieldErrors(
                nameof((s) => s.minDailyBookingAmount)
              )}
            />
          </div>
        </div>

        <h2 className="form-divider">
          {AddOrEditWorkspaceRoomForm.Resources.roomPrices}
        </h2>

        <RoomPricesForm
          disabled={false}
          name={nameof((s) => s.hourPrice)}
          value={this.state.hourPrice / 100}
          placeholder={AddOrEditWorkspaceRoomForm.Resources.hourlyPrice}
          checkboxLabel={
            AddOrEditWorkspaceRoomForm.Resources.roomCanBeBookedHourly
          }
          checkboxName={nameof((s) => s.roomCanBeBookedHourly)}
          canBeBooked={this.state.roomCanBeBookedHourly}
          onChangeCallback={this.handleInputChange}
          onKeyDownForNumberInput={this.onKeyDownForPriceInput}
          errors={this.getFieldErrors(nameof((s) => s.hourPrice))}
        ></RoomPricesForm>

        <RoomPricesForm
          disabled={false}
          name={nameof((s) => s.dayPrice)}
          value={this.state.dayPrice / 100}
          placeholder={AddOrEditWorkspaceRoomForm.Resources.dailyPrice}
          checkboxLabel={
            AddOrEditWorkspaceRoomForm.Resources.roomCanBeBookedDaily
          }
          checkboxName={nameof((s) => s.roomCanBeBookedDaily)}
          canBeBooked={this.state.roomCanBeBookedDaily}
          onChangeCallback={this.handleInputChange}
          onKeyDownForNumberInput={this.onKeyDownForPriceInput}
          errors={this.getFieldErrors(nameof((s) => s.dayPrice))}
        ></RoomPricesForm>

        <RoomPricesForm
          disabled={false}
          name={nameof((s) => s.monthPrice)}
          value={this.state.monthPrice / 100}
          placeholder={AddOrEditWorkspaceRoomForm.Resources.monthlyPrice}
          checkboxLabel={
            AddOrEditWorkspaceRoomForm.Resources.roomCanBeBookedMonthly
          }
          checkboxName={nameof((s) => s.roomCanBeBookedMonthly)}
          canBeBooked={this.state.roomCanBeBookedMonthly}
          onChangeCallback={this.handleInputChange}
          onKeyDownForNumberInput={this.onKeyDownForPriceInput}
          errors={this.getFieldErrors(nameof((s) => s.monthPrice))}
        ></RoomPricesForm>

        {this.props.hasServerSideErrors ? (
          <div>
            <span className="field-validation-error" style={{ color: "red" }}>
              {this.props.errors}
            </span>
          </div>
        ) : (
          <></>
        )}

        {this.state.errors && Object.keys(this.state.errors).length ? (
          <div>
            <div>
              <span className="field-validation-error" style={{ color: "red" }}>
                {Validations.Resources.notAllFieldsAreValid}
              </span>
            </div>
            {Object.values(this.state.errors).map((errorValues) => {
              return (errorValues as any).map((err) => (
                <span key={err} className="field-validation-error">
                  {err}
                </span>
              ));
            })}
          </div>
        ) : (
          <></>
        )}

        <div className="form-line form-bottom-actions">
          <button
            className="btn-big btn-primary form-displayed-bottom"
            onClick={() => this.saveDetails()}
          >
            {General.Resources.save}
          </button>
        </div>
      </div>
    );
  }
}

interface IRoomPricesProps {
  disabled: boolean;
  name: string;
  value: number;
  placeholder: string;
  checkboxLabel: string;
  checkboxName: string;
  canBeBooked: boolean;
  errors: string[];
  onChangeCallback: (name: string, data: any) => void;
  onKeyDownForNumberInput: (e: any) => void;
}

class RoomPricesForm extends React.PureComponent<IRoomPricesProps, {}> {
  constructor(props) {
    super(props);
  }

  @bind
  onChange(name, value) {
    this.props.onChangeCallback(name, value * 100);
  }

  render() {
    return (
      <div className="form-line">
        <div className="line-info">
          <div>{this.props.placeholder}</div>
        </div>
        <div className="line-action">
          <div className="form-check">
            <input
              disabled={this.props.disabled}
              type="checkbox"
              checked={this.props.canBeBooked}
              onChange={(event) => {
                this.props.onChangeCallback(
                  this.props.checkboxName,
                  event.target.checked
                );
                if (!event.target.checked) {
                  this.onChange(this.props.name, 0);
                }
              }}
            />
            <label>{this.props.checkboxLabel}</label>
          </div>
          <FormInput
            placeholder={this.props.placeholder}
            inputType={EntityFieldInputType.Number}
            className={"form-control"}
            value={this.props.value}
            name={this.props.name}
            onChange={this.onChange}
            onKeyDown={this.props.onKeyDownForNumberInput}
            readOnly={this.props.disabled ? true : !this.props.canBeBooked}
            errors={this.props.errors}
          />
        </div>
      </div>
    );
  }
}

export default withRouter(
  connect(
    (state: ApplicationState, ownProps: IProps) => {
      return {
        validator: AddOrEditRoomFormValidator,
        ownProps,
        ...state.addOrEditRoom,
      };
    },
    {
      saveDetails: AddOrEditRoomStore.actionCreators.saveDetails,
      setRoomImages: AddOrEditRoomStore.actionCreators.setRoomImages,
      setRoomSchedule: AddOrEditRoomStore.actionCreators.setRoomSchedule,
      setRoomAmenities: AddOrEditRoomStore.actionCreators.setRoomAmenities,
    }
  )(AddOrEditRoomForm as any)
);
