import React from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../../store";
import PaginationBar from "../Shared/PaginationBar";
import General from "../../resources/AdminUI/General";
import { HotdeskBookingsItem } from "../../models/HotdeskBookings/HotdeskBookingsItem";
import { HotdeskBookingsGridStore } from "../../store/HotdeskBookingsGridStore";
import bind from "bind-decorator";
import update from "immutability-helper";
import { getPromiseFromAction } from "../../utils/utils";
import IPageIndex from "../../models/Pagination/IPageIndex";
import { ApplicationPathBuilder } from "../../enums/Common/ApplicationPaths";
import { NavigateFunction } from "react-router-dom";
import { withRouter } from "../Shared/withRouter";
import { bindActionCreators } from "redux";
import Loading from "../Shared/Loading";

interface IProps {
  hotdeskBookings?: HotdeskBookingsItem[];
  loading?: boolean;
  hiddenColumns?: string[];
  expandableColumns?: string[];
  isHotdeskBookingHiddenList: boolean[];
  filters?: string;
  numberOfHotdeskBookings?: number;
  itemsPerPage?: number;
  firstIndexFromPage?: number;
  lastIndexFromPage?: number;
  showActionsColumn?: boolean;
  pageIndexArray?: IPageIndex[];
  navigate?: NavigateFunction;
  reload?: () => void;
  changeCurrentPage?: (currentPage: number) => void;
  initialize?: () => void;
}

interface IState {
  isHotdeskBookingHiddenList: boolean[];
}

class HotdeskBookingsTable extends React.PureComponent<any, IState> {
  constructor(props) {
    super(props);

    this.state = {
      isHotdeskBookingHiddenList: [],
    };
  }
  public static defaultProps: Partial<IProps> = {
    loading: false,
  };
  tableClass: string = "table";
  itemsPerPage: number = 15;
  maxPagesDisplayed: number = 3;
  pageNeighbours: number = 1;
  hasRefreshButton: boolean = false;

  @bind
  toggleIsExpanded(index) {
    this.setState(
      update(this.state, {
        isHotdeskBookingHiddenList: {
          [index]: { $set: !this.state.isHotdeskBookingHiddenList[index] },
        },
      })
    );
  }

  @bind
  changeCurrentPage(currentPage: number) {
    this.props.changeCurrentPage(currentPage);
    this.setState({
      isHotdeskBookingHiddenList: this.props.isHotdeskBookingHiddenList,
    });
  }

  @bind
  goToEdit(id: number) {
    this.props.navigate(ApplicationPathBuilder.EditHotdeskBooking(id));
  }

  componentDidMount(): void {
    if (
      !this.props.hotdeskBookings ||
      (this.props.hotdeskBookings.length == 0 && !this.props.filters)
    ) {
      getPromiseFromAction(
        this.props.initialize(this.itemsPerPage, this.props.filters)
      ).then(() =>
        this.setState({
          isHotdeskBookingHiddenList: this.props.isHotdeskBookingHiddenList,
        })
      );
    } else {
      getPromiseFromAction(this.props.reload()).then(() =>
        this.setState({
          isHotdeskBookingHiddenList: this.props.isHotdeskBookingHiddenList,
        })
      );
    }
  }

  render() {
    if (this.props.loading) {
      return (
        <div>
          <Loading />
        </div>
      );
    }
    if (!this.props.hotdeskBookings || this.props.hotdeskBookings.length == 0) {
      return <div>{General.Resources.noElements}</div>;
    } else {
      return (
        <div>
          <div className="table-wrapper desks-logs-table">
            <table className={this.tableClass}>
              <thead>
                <tr>
                  <th></th>
                  {this.props.showActionsColumn ? (
                    <th>
                      <span>{General.Resources.actions}</span>
                    </th>
                  ) : null}
                  {this.props.hotdeskBookings[0].TrueProperties.filter(
                    (p) => this.props.hiddenColumns.indexOf(p) < 0
                  ).map((p) => (
                    <th key={p}>
                      {this.props.hotdeskBookings[0].GetDisplayNameForProperty(
                        p
                      )}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {this.props.hotdeskBookings.map((us, index) => {
                  const columCount =
                    us.TrueProperties.filter(
                      (p) => this.props.hiddenColumns.indexOf(p) < 0
                    ).length + 2;
                  return (
                    <React.Fragment key={us.id}>
                      <tr>
                        <td className="btn-row-expand">
                          <div onClick={() => this.toggleIsExpanded(index)}>
                            <span className="material-symbols-outlined">
                              {this.state.isHotdeskBookingHiddenList.length >
                                0 &&
                              this.state.isHotdeskBookingHiddenList[index]
                                ? "chevron_right"
                                : "expand_more"}
                            </span>
                          </div>
                        </td>
                        {this.props.showActionsColumn ? (
                          <td className="row-actions">
                            <button
                              className="btn-table-action"
                              onClick={() => this.goToEdit(us.id)}
                            >
                              <span className="material-symbols-outlined">
                                edit
                              </span>
                            </button>
                          </td>
                        ) : null}
                        {us.TrueProperties.filter(
                          (p) => this.props.hiddenColumns.indexOf(p) < 0
                        ).map((p) => (
                          <td
                            data-label={
                              this.props.hasHeader
                                ? this.props.hotdeskBookings[0].GetColumnNameForProperty(
                                    p
                                  )
                                : null
                            }
                            key={p}
                          >
                            {us.GetDisplayValueForProperty(p)}
                          </td>
                        ))}
                      </tr>
                      <tr
                        className={
                          this.state.isHotdeskBookingHiddenList.length > 0 &&
                          this.state.isHotdeskBookingHiddenList[index]
                            ? "hidden-row"
                            : null
                        }
                      >
                        {us.TrueProperties.filter(
                          (p) => this.props.expandableColumns.indexOf(p) >= 0
                        ).map((p) => (
                          <td
                            className="colspan-row"
                            colSpan={columCount}
                            data-label={
                              this.props.hasHeader
                                ? this.props.hotdeskBookings[0].GetColumnNameForProperty(
                                    p
                                  )
                                : null
                            }
                            key={p}
                          >
                            {us.GetDisplayValueForProperty(p)}
                          </td>
                        ))}
                      </tr>
                    </React.Fragment>
                  );
                })}
              </tbody>
            </table>
          </div>
          <PaginationBar
            useItemsPerPageOptions={false}
            totalItems={this.props.numberOfHotdeskBookings}
            reload={this.props.reload}
            changeCurrentPage={this.changeCurrentPage}
            itemsPerPage={this.props.itemsPerPage}
            firstIndexFromPage={this.props.firstIndexFromPage}
            lastIndexFromPage={this.props.lastIndexFromPage}
            pageIndexArray={this.props.pageIndexArray}
            maxPagesDisplayed={this.maxPagesDisplayed}
            pageNeighbours={this.pageNeighbours}
            hasRefreshButton={this.hasRefreshButton}
          />
        </div>
      );
    }
  }
}

export default withRouter(
  connect(
    (state: ApplicationState, ownProps: any) => {
      return state.hotdeskBookingsGrid[ownProps.componentId] || {};
    },
    (dispatch, ownProps: any) =>
      bindActionCreators(
        {
          ...HotdeskBookingsGridStore.getActionCreators(ownProps.componentId),
        },
        dispatch
      )
  )(HotdeskBookingsTable)
);
