import bind from "bind-decorator";
import React from "react";
import { connect } from "react-redux";
import { ValidatedComponent } from "../FormValidations/Base/ValidatedComponent";
import General from "../../resources/AdminUI/General";
import { getPromiseFromAction, nameof } from "../../utils/utils";
import FormInput from "../Shared/FormInput";
import { EntityFieldInputType } from "../../utils/reactUtils";
import { ApplicationState } from "../../store";
import { AddOrEditHotdeskBookingStore } from "../../store/AddOrEditHotdeskBookingStore";
import { AddOrEditHotdeskBookingFormValidator } from "../FormValidations/Validators/AddOrEditHotdeskBookingFormValidator";
import AddOrEditHotdeskBooking from "../../resources/AdminUI/HotdeskBookings/AddOrEditHotdeskBooking";
import { HotdeskBookingStatuses } from "../../enums/HotdeskBookings/HotdeskBookingStatuses";
import AntdDateTimePickerFormWrapper from "../Shared/AntdDateTimePickerFormWrapper";
import { ApplicationPaths } from "../../enums/Common/ApplicationPaths";
import { NavigateFunction } from "react-router-dom";
import { withRouter } from "../Shared/withRouter";
import { IAntdSelectListItem } from "../../models/Common/IAntdSelectListItem";
import { Select } from "antd";
import Validations from "../../resources/Common/Validations";

export interface IProps {
    isEditMode: boolean;
    id?: number;
    userId?: number;
    workspaceId?: number;
    statusId?: string;

    checkinAt?: string;
    checkoutAt?: string;
    cancelledAt?: string;
    bookingAt?: string;

    checkinAtTime?: string;
    checkoutAtTime?: string;
    cancelledAtTime?: string;
    bookingAtTime?: string;

    statuses?: IAntdSelectListItem[];

    hasServerSideErrors?: boolean;
    errors?: string;

    navigate?: NavigateFunction;

    saveDetails?: (id: number, userId: number, workspaceId: number, statusId: string,
        checkinAt?: string, checkoutAt?: string, cancelledAt?: string, bookingAt?: string,
        checkinAtTime?: string, checkoutAtTime?: string, cancelledAtTime?: string, bookingAtTime?: string) => any;
}

export interface IState {
    id?: number;
    userId?: number;
    workspaceId?: number;
    statusId?: string;

    checkinAt?: any;
    checkoutAt?: string;
    cancelledAt?: string;
    bookingAt?: string;

    checkinAtTime?: string;
    checkoutAtTime?: string;
    cancelledAtTime?: string;
    bookingAtTime?: string;
}

class AddOrEditHotdeskBookingForm extends ValidatedComponent<IProps, IState>{
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.id,
            userId: this.props.userId,
            workspaceId: this.props.workspaceId,
            statusId: this.props.statusId,
            checkinAt: this.props.checkinAt,
            checkoutAt: this.props.checkoutAt,
            cancelledAt: this.props.cancelledAt,
            bookingAt: this.props.bookingAt,
            checkinAtTime: this.props.checkinAtTime,
            checkoutAtTime: this.props.checkoutAtTime,
            cancelledAtTime: this.props.cancelledAtTime,
            bookingAtTime: this.props.bookingAtTime,
        };

    }


    @bind
    handleInputChange(name: string, data: any) {
        this.setState({
            [name]: data
        });
    }

    @bind
    onKeyDownForNumberInput(e: any) {
        if (["e", "E", "+", "-", "."].includes(e.key)) {
            e.preventDefault();
        }
    }

    @bind
    saveDetails() {
        this.validate(() => {
            getPromiseFromAction(this.props.saveDetails(
                this.props.id,
                this.state.userId,
                this.state.workspaceId,
                this.state.statusId,
                this.state.checkinAt,
                this.state.checkoutAt,
                this.state.cancelledAt,
                this.state.bookingAt,
                this.state.checkinAtTime,
                this.state.checkoutAtTime,
                this.state.cancelledAtTime,
                this.state.bookingAtTime
            )).then(() => {
                if (!this.props.hasServerSideErrors) {
                    this.props.navigate(ApplicationPaths.DesksLogs);
                }
            });
        });
    }

    goToDeskLogs() {
        this.props.navigate(ApplicationPaths.DesksLogs);
    }

    render() {

        return <div className="form-container">

            <div className="form-line">
                <div className="line-info">
                    <div className="title">{AddOrEditHotdeskBooking.Resources.userId}<span className="mandatory">*</span></div>
                </div>
                <div className="line-action">
                    <FormInput
                        placeholder={AddOrEditHotdeskBooking.Resources.userId}
                        inputType={EntityFieldInputType.Number}
                        className={"form-control"}
                        value={this.state.userId}
                        name={nameof(s => s.userId)}
                        readOnly={this.props.isEditMode}
                        onChange={this.handleInputChange}
                        onKeyDown={this.onKeyDownForNumberInput}
                        errors={this.getFieldErrors(nameof(s => s.userId))}
                    />
                </div>
            </div>

            <div className="form-line">
                <div className="line-info">
                    <div className="title">{AddOrEditHotdeskBooking.Resources.spaceId}<span className="mandatory">*</span></div>
                </div>
                <div className="line-action">
                    <FormInput
                        placeholder={AddOrEditHotdeskBooking.Resources.spaceId}
                        inputType={EntityFieldInputType.Number}
                        className={"form-control"}
                        value={this.state.workspaceId}
                        name={nameof(s => s.workspaceId)}
                        readOnly={this.props.isEditMode}
                        onChange={this.handleInputChange}
                        onKeyDown={this.onKeyDownForNumberInput}
                        errors={this.getFieldErrors(nameof(s => s.workspaceId))}
                    />
                </div>
            </div>

            <div className="form-line">
                <div className="line-info">
                    <div className="title">{AddOrEditHotdeskBooking.Resources.status}<span className="mandatory">*</span></div>
                </div>
                <div className="line-action">
                    <div className="input-group form-select">
                        <Select
                            options={this.props.statuses}
                            onChange={(value: string, option: any) => {
                                this.handleInputChange(nameof(s => s.statusId), value);
                            }}
                            value={this.state.statusId ? this.state.statusId.toString() : null}
                            placeholder={General.Resources.select}
                        ></Select>
                    </div>
                    {this.getFieldErrors(nameof(s => s.statusId)).map(err => <span key={err} className="field-validation-error">{err}</span>)}
                </div>
            </div>



            {this.state.statusId && (this.state.statusId == HotdeskBookingStatuses.Scheduled.toString() || !this.props.isEditMode) ?
                <AntdDateTimePickerFormWrapper
                    labelTitle={AddOrEditHotdeskBooking.Resources.bookingDateAndTime}
                    labelSubtitle={AddOrEditHotdeskBooking.Resources.youMustChooseBasedOnSpaceTimezone}
                    isRequired={true}
                    date={this.state.bookingAt}
                    dateName={nameof(s => s.bookingAt)}
                    time={this.state.bookingAtTime}
                    timeName={nameof(s => s.bookingAtTime)}
                    errors={this.getFieldErrors(nameof(s => s.bookingDateAndTime))}
                    handleOnChange={this.handleInputChange}
                />
                : null
            }

            {this.state.statusId == HotdeskBookingStatuses.Cancelled.toString() ?
                <AntdDateTimePickerFormWrapper
                    labelTitle={AddOrEditHotdeskBooking.Resources.cancelledDateAndTime}
                    labelSubtitle={AddOrEditHotdeskBooking.Resources.youMustChooseBasedOnSpaceTimezone}
                    isRequired={true}
                    date={this.state.cancelledAt}
                    dateName={nameof(s => s.cancelledAt)}
                    time={this.state.cancelledAtTime}
                    timeName={nameof(s => s.cancelledAtTime)}
                    errors={this.getFieldErrors(nameof(s => s.cancelledDateAndTime))}
                    handleOnChange={this.handleInputChange}
                /> : null
            }

            {this.state.statusId == HotdeskBookingStatuses.Checkedin.toString() || this.state.statusId == HotdeskBookingStatuses.Ended.toString() ?
                <AntdDateTimePickerFormWrapper
                    labelTitle={AddOrEditHotdeskBooking.Resources.checkinDateAndTime}
                    labelSubtitle={AddOrEditHotdeskBooking.Resources.youMustChooseBasedOnSpaceTimezone}
                    isRequired={true}
                    date={this.state.checkinAt}
                    dateName={nameof(s => s.checkinAt)}
                    time={this.state.checkinAtTime}
                    timeName={nameof(s => s.checkinAtTime)}
                    errors={this.getFieldErrors(nameof(s => s.checkinDateAndTime))}
                    handleOnChange={this.handleInputChange}
                /> : null
            }

            {
                this.state.statusId == HotdeskBookingStatuses.Ended.toString() ?
                    <AntdDateTimePickerFormWrapper
                        labelTitle={AddOrEditHotdeskBooking.Resources.checkoutDateAndTime}
                        labelSubtitle={AddOrEditHotdeskBooking.Resources.youMustChooseBasedOnSpaceTimezone}
                        isRequired={true}
                        date={this.state.checkoutAt}
                        dateName={nameof(s => s.checkoutAt)}
                        time={this.state.checkoutAtTime}
                        timeName={nameof(s => s.checkoutAtTime)}
                        errors={this.getFieldErrors(nameof(s => s.checkoutDateAndTime))}
                        handleOnChange={this.handleInputChange}
                    /> : null
            }

            {this.props.hasServerSideErrors ? <div><span className="field-validation-error" style={{ color: "red" }}>{this.props.errors}</span></div> : <></>}

            {this.state.errors && Object.keys(this.state.errors).length ?
                <div>
                    < div >
                        <span className="field-validation-error" style={{ color: "red" }}>{Validations.Resources.notAllFieldsAreValid}</span></div>
                        {Object.values(this.state.errors).map(errorValues => {
                            return (errorValues as any).map(err => <span key={err} className="field-validation-error">{err}</span>)
                        })}
                </div>
                :
                <></>
            }

            <div className="form-line form-bottom-actions">
                <button className="btn-big btn-secondary" onClick={() => this.goToDeskLogs()}>{General.Resources.cancelButtonLabel}</button>
                <button className="btn-big btn-primary" onClick={() => this.saveDetails()}>{General.Resources.save}</button>
            </div>
        </div>


    }
}


export default withRouter(connect(
    (state: ApplicationState, ownProps: IProps) => {
        return {
            validator: AddOrEditHotdeskBookingFormValidator,
            ...state.addOrEditHotdeskBooking,
            ownProps
        }
    },
    {
        saveDetails: AddOrEditHotdeskBookingStore.actionCreators.saveDetails
    }
)(AddOrEditHotdeskBookingForm as any));