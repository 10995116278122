import { Action, Reducer } from "redux";
import { AppThunkAction } from ".";
import { ISubscription } from "../models/Subscriptions/Interfaces/ISubscription";
import update from "immutability-helper";
import { getServerSideErrors } from "../utils/utils";
import SubscriptionsService from "../services/SubscriptionsService";
import SubscriptionItem from "../models/Subscriptions/SubscriptionItem";
import ClientsService from "../services/ClientsService";

export module SubscriptionStore {

    export interface IState {
        loading: boolean;
        subscription: ISubscription;
        hasServerSideErrors: boolean;
        errors: string;
    }

    export enum Actions {
        InitializeSubscription = "SUBSCRIPTION_INITIALIZE",
        ToggleLoading = "SUBSCRIPTION_TOGGLE_LOADING",
        SetSubscription = "SUBSCRIPTION_SET_SUBSCRIPTION",
        AddSubscription = "SUBSCRIPTION_ADD_SUBSCRIPTION",
        RemoveSubscription = "SUBSCRIPTION_REMOVE_SUBSCRIPTION",
        SetErrors = "SUBSCRIPTION_SET_ERRORS",
    }

    interface IInitialize {
        type: Actions.InitializeSubscription
    }

    interface IToggleLoading {
        type: Actions.ToggleLoading;
        loading: boolean;
    }

    interface ISetSubscription {
        type: Actions.SetSubscription,
        subscription: ISubscription
    }

    interface IAddSubscription {
        type: Actions.AddSubscription,
        subscription: ISubscription
    }

    interface ISetErrors {
        type: Actions.SetErrors,
        hasServerSideErrors: boolean,
        errors: string
    }

    interface IRemoveSubscription {
        type: Actions.RemoveSubscription
    }

    type KnownAction = IToggleLoading | ISetSubscription | ISetErrors | IAddSubscription | IRemoveSubscription | IInitialize;

    export const actionCreators = {
        initializeSubscription: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
            dispatch({ type: Actions.InitializeSubscription })
        },
        getClientSubscription: (clientId: number): AppThunkAction<KnownAction> => async (dispatch, getState) => {
            dispatch({ type: Actions.ToggleLoading, loading: true });

            var result = await ClientsService.getSubscription(clientId).then(response => response.value);
            var subscription: SubscriptionItem = SubscriptionsService.mapSubscriptionToSubscriptionItem(result);

            dispatch({ type: Actions.SetSubscription, subscription: subscription.id.valueOf() ? subscription : inititalSubscription });

            dispatch({ type: Actions.ToggleLoading, loading: false });
        },
        deactivateSubscription: (subscriptionId: number): AppThunkAction<KnownAction> => async (dispatch, getState) => {

            var serverResponse = await SubscriptionsService.deactivateSubscription(subscriptionId).then(response => response);

            var errors = getServerSideErrors(serverResponse);
            var hasServerSideErrors = errors ? true : false;

            if (hasServerSideErrors) {
                dispatch({
                    type: Actions.SetErrors, hasServerSideErrors: hasServerSideErrors, errors: errors
                });
            } else {
                dispatch({ type: Actions.RemoveSubscription })
            }
        },
        addOrEditSubscription: (subscription: ISubscription): AppThunkAction<KnownAction> => async (dispatch, getState) => {
            if (subscription.id) {
                var serverResponse = await SubscriptionsService.editSubscription(subscription).then(response => response);
            } else {
                var serverResponse = await SubscriptionsService.addSubscription(subscription).then(response => response);
            }

            var errors = getServerSideErrors(serverResponse);
            var hasServerSideErrors = errors ? true : false;

            if (hasServerSideErrors) {
                dispatch({
                    type: Actions.SetErrors, hasServerSideErrors: hasServerSideErrors, errors: errors
                });
            }
        },
        reset: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
            dispatch({ type: Actions.SetErrors, hasServerSideErrors: false, errors: '' });
        }
    }

    const inititalSubscription: ISubscription = {
        id: null,
        name: '',
        price: 0,
        currency: null,
        startsAt: null,
        renewIntervalUnit: null,
        renewIntervalValue: null,
        lastRenewDate: null,
        nextRenewDate: null,
        companyId: null,
        metadata: {
          vat: null,
          platformAccessFee: null,
          prepaid: null,
          minimumSpend: null
        }
    }

    const initialState: IState = {
        loading: false,
        subscription: inititalSubscription,
        hasServerSideErrors: false,
        errors: ""
    }

    export const reducer: Reducer<IState> = (currentState: IState, incomingAction: Action) => {
        const action = incomingAction as KnownAction;

        switch (action.type) {
            case Actions.InitializeSubscription:
                return initialState;
            case Actions.ToggleLoading:
                return { ...currentState, loading: action.loading }
            case Actions.SetSubscription:
                return update(currentState,
                    {
                        subscription: { $set: action.subscription }
                    });
            case Actions.SetErrors:
                return update(currentState,
                    {
                        hasServerSideErrors: { $set: action.hasServerSideErrors },
                        errors: { $set: action.errors },
                    });
            case Actions.RemoveSubscription:
                return update(currentState,
                    {
                        subscription: { $set: inititalSubscription }
                    });
            default:
                return currentState || initialState;
        }
    }
}