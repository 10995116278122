import { Input, Tag, Tooltip } from "antd";
import React, { useState, useRef } from "react";
import { PlusOutlined, PlusCircleOutlined } from "@ant-design/icons";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { InputRef } from "antd/es/input"; // Ant Design Input Ref
import "react-phone-number-input/style.css";

interface IProps {
  tags: string[];
  buttonText: string;
  onListChange?: (list: string[]) => void;
  type?: "email" | "phone" | "text";
}

const TagButton: React.FC<IProps> = ({
  tags: initialTags,
  buttonText,
  onListChange,
  type = "text",
}) => {
  const [tags, setTags] = useState<string[]>(initialTags);
  const [inputValue, setInputValue] = useState<string>("");
  const [isInputVisible, setIsInputVisible] = useState<boolean>(false);
  const [inputError, setInputError] = useState<string>("");

  const inputRef = useRef<InputRef>(null);
  const phoneInputRef = useRef<any>(null);

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleValidation(inputValue);
    }
  };

  const handleValidation = (inputTargetValue: string) => {
    if (type === "phone") {
      handleValidatePhoneNumber(inputTargetValue);
    } else if (type === "email") {
      handleValidateEmail(inputTargetValue);
    } else {
      handleInputConfirm();
    }
  };

  const handleValidatePhoneNumber = (inputTargetValue: string) => {
    if (!isValidPhoneNumber(inputTargetValue)) {
      setInputError("Invalid phone number");
    } else {
      setInputError("");
      handleInputConfirm();
    }
  };

  const handleValidateEmail = (inputTargetValue: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(inputTargetValue)) {
      setInputError("Invalid email address");
    } else {
      setInputError("");
      handleInputConfirm();
    }
  };

  const handleClose = (removedTag: string) => {
    const newTags = tags.filter((tag) => tag !== removedTag);
    setTags(newTags);
    onListChange && onListChange(newTags);
  };

  const showInput = () => {
    setIsInputVisible(true);
    setTimeout(() => {
      if (type === "phone") {
        phoneInputRef.current?.focus();
      } else {
        inputRef.current?.focus();
      }
    }, 0); // Small delay to ensure the input is rendered before focusing
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (inputError) {
      if (type === "email") {
        if (emailRegex.test(e.target.value)) {
          setInputError("");
        }
      } else {
        setInputError("");
      }
    }
  };

  const handlePhoneInputChange = (value: string) => {
    setInputValue(value);

    if (inputError && isValidPhoneNumber(value)) {
      setInputError("");
    }
  };

  const handleInputConfirm = () => {
    if (inputValue && tags.indexOf(inputValue) === -1) {
      const newTags = [...tags, inputValue];
      setTags(newTags);
      onListChange && onListChange(newTags);
    }
    setIsInputVisible(false);
    setInputValue("");
    setInputError("");
  };

  const handleBlur = () => {
    handleValidation(inputValue);
  };

  const contentByType = () => {
    if (type === "phone") {
      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ position: "relative", width: "100%" }}>
            <PhoneInput
              ref={phoneInputRef}
              placeholder="Enter phone number"
              value={inputValue}
              onChange={handlePhoneInputChange}
              onKeyDown={handleKeyPress}
              onBlur={handleBlur}
              international={true}
              countryCallingCodeEditable={false}
            />
            {inputError ? (
              <span className="field-validation-error tag-btn-message">
                {inputError}
              </span>
            ) : (
              <span className="tag-btn-warning-message tag-btn-message">
                Press <PlusCircleOutlined /> to add the phone number
              </span>
            )}
          </div>
          <Tooltip title="Add phone number">
            <PlusCircleOutlined
              onClick={() => handleValidation(inputValue)}
              style={{ marginLeft: 8 }}
            />
          </Tooltip>
        </div>
      );
    }

    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <div style={{ position: "relative", width: "100%" }}>
          <Input
            ref={inputRef}
            type={type ?? "text"}
            size="middle"
            style={{ width: 200 }}
            placeholder={type === "email" ? "Enter email" : "Enter text"}
            value={inputValue}
            onChange={handleInputChange}
            onBlur={handleBlur}
            onPressEnter={handleKeyPress}
          />
          {inputError ? (
            <span className="field-validation-error tag-btn-message tag-btn-message-email">
              {inputError}
            </span>
          ) : (
            <span className="tag-btn-warning-message tag-btn-message tag-btn-message-email">
              Press <PlusCircleOutlined /> to add{" "}
              {type === "email" ? "email" : "value"}
            </span>
          )}
        </div>
        <Tooltip title={`Add ${type === "email" ? "email" : "value"}`}>
          <PlusCircleOutlined
            onClick={() => handleValidation(inputValue)}
            style={{ marginLeft: 8 }}
          />
        </Tooltip>
      </div>
    );
  };

  return (
    <div>
      {isInputVisible ? (
        contentByType()
      ) : (
        <Tag
          onClick={showInput}
          style={{ borderStyle: "dashed", padding: "10px", cursor: "pointer" }}
        >
          <PlusOutlined />
          {buttonText}
        </Tag>
      )}

      <div style={{ marginTop: 32 }}>
        {tags.map((tag) => (
          <Tag
            key={tag}
            closable
            onClose={(e) => {
              e.preventDefault();
              handleClose(tag);
            }}
            style={{ marginBottom: 8 }}
          >
            {tag}
          </Tag>
        ))}
      </div>
    </div>
  );
};

export default TagButton;
