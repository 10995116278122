import React from "react"
import { ISubscription } from "../../../models/Subscriptions/Interfaces/ISubscription"
import { firstLetterToLowerCase, nameof } from "../../../utils/utils"
import FormInput from "../../Shared/FormInput"
import { EntityFieldInputType } from "../../../utils/reactUtils"
import bind from "bind-decorator"
import { DatePicker, Select } from "antd"
import dayjs from "dayjs"
import { ValidatedComponent } from "../../FormValidations/Base/ValidatedComponent"
import { ApplicationState } from "../../../store"
import { connect } from "react-redux"
import { LookupStore } from "../../../store/LookupStore"
import General from "../../../resources/AdminUI/General"
import { AddOrEditSubscriptionValidator } from "../../FormValidations/Validators/AddOrEditSubscriptionValidator"
import { SubscriptionStore } from "../../../store/SubscriptionStore"
import Subscriptions from "../../../resources/AdminUI/Subscriptions/Subscriptions"
import { IAntdSelectListItem } from "../../../models/Common/IAntdSelectListItem"
import { RenewalTypes } from "../../../enums/Clients/RenewalTypes"
import RenewalTypesList from "../../../resources/AdminUI/RenewalTypesList"
import Validations from "../../../resources/Common/Validations"

interface IProps {
    subscription?: ISubscription,
    clientId: number,
    currencies?: IAntdSelectListItem[]

    hasServerSideErrors?: boolean;
    errors?: string;

    getCurrencies?: () => void,
    onCancelCallback: () => void,
    onSaveCallback: (subsciption: ISubscription) => any,
    reset?: () => void
}

interface IState {
    subscription: ISubscription
}

class AddOrEditSubscriptionForm extends ValidatedComponent<IProps, IState> {
    constructor(props) {
        super(props)

        this.state = {
            subscription: {
                id: this.props.subscription?.id,
                name: this.props.subscription?.name,
                price: 0,
                currency: this.props.subscription?.currency?.valueOf(),
                startsAt: this.props.subscription?.startsAt,
                renewIntervalUnit: this.props.subscription?.renewIntervalUnit,
                renewIntervalValue: this.props.subscription?.renewIntervalValue,
                lastRenewDate: this.props.subscription?.lastRenewDate,
                nextRenewDate: this.props.subscription?.nextRenewDate,
                companyId: this.props.clientId,
                metadata: this.props.subscription?.metadata || { platformAccessFee: null, prepaid: null, minimumSpend: null, vat: null }
            }
        }

        this.props.getCurrencies();
        this.props.reset();
    }

    @bind
    handleInputChange(name: string, data: any) {
        this.setState({
            subscription: {
                ...this.state.subscription,
                [name]: data
            }
        });
    }

    @bind
    handlePriceInputChange(name: string, data: any) {
        this.setState({
            subscription: {
                ...this.state.subscription,
                [name]: data ? data * 100 : null
            }
        });
    }

    @bind
    handleMetadataChange(name: string, data: any) {
        this.setState({
            subscription: {
                ...this.state.subscription,
                metadata: {
                    ...this.state.subscription.metadata,
                    [name]: data * 1
                }
            }
        });
    }

    @bind
    handleMetadataPriceChange(name: string, data: any) {
        this.setState({
            subscription: {
                ...this.state.subscription,
                metadata: {
                    ...this.state.subscription.metadata,
                    [name]: data ? data * 100 : null
                }
            }
        });
    }

    @bind
    handleOnPaymentUnitChange(name: string, data: any) {
        var valueParsed = parseInt(data)
        this.setState({
            subscription: {
                ...this.state.subscription,
                [name]: valueParsed
            }
        });
    }

    @bind
    onKeyDownForNumberInput(e: any) {
        if (["e", "E", "+", "-", "."].includes(e.key)) {
            e.preventDefault();
        }
    }

    @bind
    onKeyDownForPriceInput(e: any) {
        if (["e", "E", "+", "-"].includes(e.key)) {
            e.preventDefault();
        }
    }

    @bind
    handleOnDateChange(value, name) {
        this.setState({
            subscription: {
                ...this.state.subscription,
                [name]: value?.toISOString()
            }
        });
    }

    @bind
    getPlanRecurence() {
        let planRecurenceOptions: IAntdSelectListItem[] = []

        const keys = Object.keys(RenewalTypes).filter(k => typeof RenewalTypes[k] === "number");

        keys.forEach(key => {
            let value = RenewalTypes[key].toString();
            planRecurenceOptions.push({
                value: value,
                label: RenewalTypesList.Resources[firstLetterToLowerCase(key)]
            } as IAntdSelectListItem);
        });

        return planRecurenceOptions;
    }

    @bind
    handleOnSaveButtonClick() {
        this.validate(() => {
            this.props.onSaveCallback(this.state.subscription)
        });
    }

    handleOnCancelButtonClick() {
        this.props.onCancelCallback();
    }

    render() {
        return <div className="modal-container">

            <div className="modal-title">
                <div>{this.props.subscription.id ? Subscriptions.Resources.editPricingPlanText : Subscriptions.Resources.addPricingPlanText}</div>
            </div>

            <div className="modal-description">
                {
                    !this.state.subscription.id &&
                    Subscriptions.Resources.addPricingPlanDescText
                }
            </div>

            <div className="form-line">
                <div className="line-info">
                    <div className="title in-modal">{Subscriptions.Resources.pricingPlanText}<span className="mandatory">*</span></div>
                </div>
                <div className="line-action">
                    <FormInput
                        placeholder={Subscriptions.Resources.pricingPlanText}
                        inputType={EntityFieldInputType.Text}
                        className={"form-control"}
                        value={this.state.subscription.name == null ? null : this.state.subscription.name}
                        name={nameof(s => s.name)}
                        onChange={this.handleInputChange}
                        errors={this.getFieldErrors(nameof(s => s.name))}
                    />
                </div>
            </div>

            <div className="form-line">
                <div className="line-info">
                    <div className="title in-modal">{Subscriptions.Resources.currencyNameText}<span className="mandatory">*</span></div>
                </div>
                <div className="line-action">
                    <div className="input-group form-select">
                        <Select
                            options={this.props.currencies}
                            onChange={(value, option) => { this.handleInputChange(nameof(e => e.currency), value) }}
                            value={this.state.subscription.currency}
                            showSearch
                            placeholder={Subscriptions.Resources.selectCurrencyText}
                            optionFilterProp="label"
                            disabled={this.props.subscription.id == null ? false : true}
                        ></Select>
                    </div>
                    {this.getFieldErrors(nameof(s => s.currency)).map(err => <span key={err} className="field-validation-error">{err}</span>)}
                </div>
            </div>

            <div className="form-line">
                <div className="line-info">
                    <div className="title in-modal">{Subscriptions.Resources.pricingPlanAccessFee}</div>
                </div>
                <div className="line-action">
                    <FormInput
                        placeholder={Subscriptions.Resources.pricingPlanAccessFee}
                        inputType={EntityFieldInputType.Number}
                        className={"form-control"}
                        value={this.state.subscription.metadata.platformAccessFee == null ? null : this.state.subscription.metadata.platformAccessFee / 100}
                        name={nameof(s => s.platformAccessFee)}
                        onChange={this.handleMetadataPriceChange}
                        errors={this.getFieldErrors(nameof(s => s.platformAccessFee))}
                        onKeyDown={this.onKeyDownForPriceInput}
                    />
                </div>
            </div>

            <div className="form-line">
                <div className="line-info">
                    <div className="title in-modal">{Subscriptions.Resources.pricingPlanMonthlyPrepaid}</div>
                </div>
                <div className="line-action">
                    <FormInput
                        placeholder={Subscriptions.Resources.pricingPlanMonthlyPrepaid}
                        inputType={EntityFieldInputType.Number}
                        className={"form-control"}
                        value={this.state.subscription.metadata.prepaid == null ? null : this.state.subscription.metadata.prepaid / 100}
                        name={nameof(s => s.prepaid)}
                        onChange={this.handleMetadataPriceChange}
                        errors={this.getFieldErrors(nameof(s => s.prepaid))}
                        onKeyDown={this.onKeyDownForPriceInput}
                    />
                </div>
            </div>

            <div className="form-line">
                <div className="line-info">
                    <div className="title in-modal">{Subscriptions.Resources.pricingPlanMininumSpend}</div>
                </div>
                <div className="line-action">
                    <FormInput
                        placeholder={Subscriptions.Resources.pricingPlanMininumSpend}
                        inputType={EntityFieldInputType.Number}
                        className={"form-control"}
                        value={this.state.subscription.metadata.minimumSpend == null ? null : this.state.subscription.metadata.minimumSpend / 100}
                        name={nameof(s => s.minimumSpend)}
                        onChange={this.handleMetadataPriceChange}
                        errors={this.getFieldErrors(nameof(s => s.minimumSpend))}
                        onKeyDown={this.onKeyDownForPriceInput}
                    />
                </div>
            </div>

            <div className="form-line">
                <div className="line-info">
                    <div className="title in-modal">{Subscriptions.Resources.pricingPlanVAT}</div>
                </div>
                <div className="line-action">
                    <FormInput
                        placeholder={Subscriptions.Resources.pricingPlanVAT}
                        inputType={EntityFieldInputType.Number}
                        className={"form-control"}
                        value={this.state.subscription.metadata.vat}
                        name={nameof(s => s.vat)}
                        onChange={this.handleMetadataChange}
                        errors={this.getFieldErrors(nameof(s => s.vat))}
                        onKeyDown={this.onKeyDownForPriceInput}
                    />
                </div>
            </div>

            {
                !this.state.subscription.id &&

                <div>
                    <div className="form-line">
                        <div className="line-info">
                            <div className="title in-modal">{Subscriptions.Resources.pricingPlanStartDateText}<span className="mandatory">*</span></div>
                        </div>
                        <div className="line-action">
                            <DatePicker
                                showTime={{ format: 'HH:mm' }}
                                format="YYYY-MM-DD HH:mm"
                                value={this.state.subscription.startsAt ? dayjs(this.state.subscription.startsAt) : null}
                                onChange={(value, name) =>
                                    this.handleOnDateChange(value, nameof(s => s.startsAt))
                                } />
                            {this.getFieldErrors(nameof(s => s.startsAt)).map(err => <span key={err} className="field-validation-error">{err}</span>)}
                        </div>

                    </div>

                    <div className="form-line">
                        <div className="line-info">
                            <div className="title in-modal">{Subscriptions.Resources.paymentRenewalTypeText}<span className="mandatory">*</span> & {General.Resources.value}<span className="mandatory">*</span></div>
                        </div>

                        <div className="line-action inline-inputs">
                            <div className="input-group">
                                <span className="input-group-text">{General.Resources.every}</span>
                                <input
                                    type={EntityFieldInputType.Number}
                                    placeholder={Subscriptions.Resources.paymentRenewalValueText}
                                    className={`form-control`}
                                    onChange={(e) => this.handleInputChange(e.currentTarget.name, e.currentTarget.value)}
                                    name={nameof(s => s.renewIntervalValue)}
                                    onKeyDown={this.onKeyDownForNumberInput}
                                    value={this.state.subscription.renewIntervalValue || ""}
                                />
                            </div>

                            <div className="input-group form-select">
                                <Select
                                    options={this.getPlanRecurence()}
                                    onChange={(value: any, option: any) => {
                                        this.handleOnPaymentUnitChange(nameof(s => s.renewIntervalUnit), value);
                                    }}
                                    value={this.state.subscription.renewIntervalUnit?.toString()}
                                    placeholder={Subscriptions.Resources.selectRenewalTypeText}
                                ></Select>
                            </div>

                            <div className="break" />

                            {this.getFieldErrors(nameof(s => s.renewIntervalValue)).map(err => <span key={err} className="field-validation-error">{err}</span>)}

                            {this.getFieldErrors(nameof(s => s.renewIntervalUnit)).map(err => <span key={err} className="field-validation-error">{err}</span>)}
                        </div>
                    </div>
                </div>
            }

            {this.props.hasServerSideErrors ? <div><span className="field-validation-error" style={{ color: "red" }}>{this.props.errors}</span></div> : <></>}

            {this.state.errors && Object.keys(this.state.errors).length ?
                <div>
                    < div >
                        <span className="field-validation-error" style={{ color: "red" }}>{Validations.Resources.notAllFieldsAreValid}</span></div>
                        {Object.values(this.state.errors).map(errorValues => {
                            return (errorValues as any).map(err => <span key={err} className="field-validation-error">{err}</span>)
                        })}
                </div>
                :
                <></>
            }

            <div className="form-line form-bottom-actions">
                <button className="btn-big btn-secondary" onClick={this.props.onCancelCallback}>{General.Resources.cancelButtonLabel}</button>
                <button className="btn-big btn-primary" onClick={this.handleOnSaveButtonClick}>
                    {
                        this.props.subscription ? General.Resources.save : Subscriptions.Resources.addPricingPlanText
                    }
                </button>
            </div>
        </div>
    }
}

export default connect(
    (state: ApplicationState, ownProps: IProps) => {
        return {
            validator: AddOrEditSubscriptionValidator,
            currencies: state.lookup.currencies,
            hasServerSideErrors: state.subscription.hasServerSideErrors,
            errors: state.subscription.errors,
            subscription: state.subscription.subscription,
            ...ownProps
        }
    },
    {
        getCurrencies: LookupStore.actionCreators.getCurrencies,
        reset: SubscriptionStore.actionCreators.reset
    }
)(AddOrEditSubscriptionForm as any)