import React from "react";
import { ApplicationState } from "../../store";
import { connect } from "react-redux";
import {
  String_Format,
  firstLetterToLowerCase,
  getPromiseFromAction,
} from "../../utils/utils";
import Workspaces from "../../resources/AdminUI/Spaces/Workspaces";
import RoomDetailsTabsComponent from "./RoomDetailsTabsComponent";
import { AppSettings } from "../../utils/settings";
import { RoomCategories } from "../../enums/Rooms/RoomCategories";
import RoomTypes from "../../resources/AdminUI/Rooms/RoomTypes";
import bind from "bind-decorator";
import { RoomDetailsStore } from "../../store/RoomDetailsStore";
import General from "../../resources/AdminUI/General";
import Rooms from "../../resources/AdminUI/Rooms/Rooms";
import { ApplicationPathBuilder } from "../../enums/Common/ApplicationPaths";
import NewImageGalleryViewer from "../Shared/NewImageGalleryViewer";
import { Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import ErrorMessages from "../../resources/Common/ErrorMessages";
import { NavigateFunction } from "react-router";
import { withRouter } from "../Shared/withRouter";
import Loading from "../Shared/Loading";
const { confirm } = Modal;

interface IProps {
  details: any;
  isDeactivated: boolean;
  currency: string;
  loading?: boolean;
  hasServerSideErrors?: boolean;
  serverSideErrors?: string;

  navigate?: NavigateFunction;
  changeIsDeactivated?: (value: boolean) => void;

  getAmenities?: (id: number) => void;
  deactivateRoom?: (id: number) => any;
  activateRoom?: (id: number) => any;
  onOpenChangeActivationModalCallback?: () => void;
  onCloseChangeActivationModalCallback?: () => void;
}

interface IState {
  isDeactivated: boolean;

  isDeactivateModalOpen: boolean;
}

class RoomDetails extends React.PureComponent<IProps, IState> {
  constructor(props) {
    super(props);
    this.props.getAmenities(this.props.details.id);
    this.state = {
      isDeactivated: this.props.isDeactivated,
      isDeactivateModalOpen: false,
    };
  }

  @bind
  showDeactivateModal() {
    this.props.onOpenChangeActivationModalCallback();
    confirm({
      title: Workspaces.Resources.areYouSure,
      icon: <ExclamationCircleOutlined />,
      content: (
        <div>
          <p>{Rooms.Resources.deactivateRoomConfirmation}</p>
        </div>
      ),
      okText: Rooms.Resources.deactivateRoom,
      okType: "danger",
      cancelText: General.Resources.cancelButtonLabel,
      onOk: () => {
        this.deactivateRoom();
      },
      onCancel: () => {
        this.props.onCloseChangeActivationModalCallback();
      },
    });
  }

  @bind
  showActivateModal() {
    this.props.onOpenChangeActivationModalCallback();
    confirm({
      title: Workspaces.Resources.areYouSure,
      okText: Rooms.Resources.activateRoom,
      okType: "danger",
      cancelText: General.Resources.cancelButtonLabel,
      onOk: () => {
        this.activateRoom();
      },
      onCancel: () => {
        this.props.onCloseChangeActivationModalCallback();
      },
    });
  }

  @bind
  getRoomTypeName() {
    let value = RoomCategories[this.props.details.roomTypeId].toString();
    let text = RoomTypes.Resources[firstLetterToLowerCase(value)];
    return text;
  }

  @bind
  goToEditRoom() {
    this.props.navigate(
      ApplicationPathBuilder.EditRoom(this.props.details.id.toString())
    );
  }

  @bind
  deactivateRoom() {
    getPromiseFromAction(this.props.deactivateRoom(this.props.details.id)).then(
      (res) => {
        if (!this.props.hasServerSideErrors) {
          this.props.changeIsDeactivated(true);
          this.props.onCloseChangeActivationModalCallback();
        } else {
          Modal.error({
            title: ErrorMessages.Resources.generalErrorMessage,
          });
        }
      }
    );
  }

  @bind
  activateRoom() {
    getPromiseFromAction(this.props.activateRoom(this.props.details.id)).then(
      (res) => {
        if (!this.props.hasServerSideErrors) {
          this.props.changeIsDeactivated(false);
          this.props.onCloseChangeActivationModalCallback();
        } else {
          Modal.error({
            title: ErrorMessages.Resources.generalErrorMessage,
          });
        }
      }
    );
  }

  render() {
    if (this.props.loading) {
      return (
        <div>
          <Loading />
        </div>
      );
    }
    return (
      <div className="modal-container">
        <div className="modal-title">{Rooms.Resources.roomDetails}</div>

        <div className="room-details-container">
          <div className="gallery">
            <NewImageGalleryViewer
              itemUrls={this.props.details.images.map((e) => {
                return AppSettings.CommonSettings.ImageBaseUrl + e;
              })}
            ></NewImageGalleryViewer>
          </div>

          <div className="info">
            <div className="room-type">{this.getRoomTypeName()}</div>
            <div className="room-name">{this.props.details.name}</div>

            <div className="db_info">
              <div className="status">
                {!this.state.isDeactivated
                  ? General.Resources.active
                  : General.Resources.notActive}
              </div>
              <div className="id">
                {" "}
                |{" "}
                {String_Format(
                  Workspaces.Resources.idLabel,
                  this.props.details.id
                )}{" "}
                |{" "}
                {String_Format(
                  Rooms.Resources.createdOn,
                  this.props.details.createdAt
                )}
              </div>
            </div>

            <div className="room-info-rows">
              <div className="info-row">
                <div className="icon">
                  <span className="material-symbols-outlined">group</span>
                </div>
                <div className="info">
                  {this.props.details.maxGuests
                    ? String_Format(
                        Rooms.Resources.maxGuests,
                        this.props.details.maxGuests
                      )
                    : "-"}
                </div>
              </div>

              <div className="info-row">
                <div className="icon">
                  <span className="material-symbols-outlined">payments</span>
                </div>
                <div className="info">
                  {!this.props.details.hourPrice ? null : (
                    <div style={{ marginRight: "8px" }}>
                      {" "}
                      {String_Format(
                        Rooms.Resources.priceHourly,
                        parseInt(this.props.details.hourPrice) / 100,
                        this.props.currency
                      )}
                    </div>
                  )}
                  {!this.props.details.dayPrice ? null : (
                    <div style={{ marginRight: "8px" }}>
                      {" "}
                      {String_Format(
                        Rooms.Resources.priceDaily,
                        parseInt(this.props.details.dayPrice) / 100,
                        this.props.currency
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="form-line form-bottom-actions">
              {!this.state.isDeactivated ? (
                <button
                  className="btn-big btn-secondary"
                  onClick={this.showDeactivateModal}
                >
                  {Rooms.Resources.deactivateRoom}
                </button>
              ) : (
                <button
                  className="btn-big btn-secondary"
                  onClick={this.showActivateModal}
                >
                  {Rooms.Resources.activateRoom}
                </button>
              )}
              <button
                className="btn-big btn-primary"
                onClick={this.goToEditRoom}
              >
                {Rooms.Resources.editRoomInfo}
              </button>
            </div>
          </div>
        </div>

        <div className="room-tabs">
          <RoomDetailsTabsComponent
            componentId={`room-details-${this.props.details.id}`}
            descriptionEn={this.props.details.descriptionEn}
            descriptionEs={this.props.details.descriptionEs}
            descriptionPt={this.props.details.descriptionPt}
            amenities={this.props.details.amenities}
            schedule={this.props.details.schedule}
            phone={this.props.details.phone}
            email={this.props.details.email}
          ></RoomDetailsTabsComponent>
        </div>

        <div>
          {this.props.hasServerSideErrors ? (
            <span className="field-validation-error" style={{ color: "red" }}>
              {this.props.serverSideErrors}
            </span>
          ) : (
            <></>
          )}
        </div>
      </div>
    );
  }
}

export default withRouter(
  connect(
    (state: ApplicationState, ownProps: IProps) => {
      return {
        ...ownProps,
        amenities: state.roomDetails.amenities,
        loading: state.roomDetails.loading,
      };
    },
    {
      getAmenities: RoomDetailsStore.actionCreators.getAmenities,
      deactivateRoom: RoomDetailsStore.actionCreators.deactivateRoom,
      activateRoom: RoomDetailsStore.actionCreators.activateRoom,
    }
  )(RoomDetails as any)
);
