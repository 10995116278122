import { Action, Reducer } from "redux";
import { AppThunkAction } from ".";
import { ITeam } from "../models/Teams/Interfaces/ITeam";
import update from "immutability-helper";
import { getServerSideErrors } from "../utils/utils";
import TeamsService from "../services/TeamsService";
import { BookingRestrictionTypes } from "../enums/Teams/BookingRestrictionTypes";
import SpacesService from "../services/SpacesService";
import { ChangeHotdeskBookingPricesDTO } from "../models/Spaces/Interfaces/ChangeHotdeskBookingPricesDTO";

export module ChangeHotdeskBookingPricesStore {

    export interface IState {
        loading: boolean;
        hasServerSideErrors: boolean;
        errors: string;
    }

    export enum Actions {
        ToggleLoading = "CHANGE_HOTDESK_BOOKING_PRICES_TOGGLE_LOADING",
        Initialize = "CHANGE_HOTDESK_BOOKING_PRICES_INITIALIZE",
        SetErrors = "CHANGE_HOTDESK_BOOKING_PRICES_SET_ERRORS",
  
      }

    interface IToggleLoading {
        type: Actions.ToggleLoading;
        loading: boolean;
    }

    interface IInitialize {
        type: Actions.Initialize;
    }

    interface ISetErrors {
        type: Actions.SetErrors,
        hasServerSideErrors: boolean,
        errors: string
    }

    type KnownAction = IToggleLoading | IInitialize | ISetErrors ;

    export const actionCreators = {
        initialize: () => async (dispatch, getState) => {
            dispatch({ type: Actions.Initialize });
        },

        onSave: (action: ChangeHotdeskBookingPricesDTO & {spaceId: number}): AppThunkAction<KnownAction> => async (dispatch, getState) => {
            dispatch({ type: Actions.ToggleLoading, loading: true });

            let spaceId = action.spaceId;

            let day = action.startDate.split("-")[0];
            let month = action.startDate.split("-")[1];
            let year = action.startDate.split("-")[2];
            action.startDate = `${year}-${month}-${day}`;

            var serverResponse = await SpacesService.changeHotdeskBookingPrices(spaceId, action).then(response => response);

            var errors = getServerSideErrors(serverResponse);
            var hasServerSideErrors = errors ? true : false;

            if (hasServerSideErrors) {
                dispatch({
                    type: Actions.SetErrors,
                    hasServerSideErrors: hasServerSideErrors,
                    errors: errors
                });
            }
        },

    }

    const initialState: IState = {
        loading: false,
        hasServerSideErrors: false,
        errors: ""
    }

    export const reducer: Reducer<IState> = (currentState: IState, incomingAction: Action) => {
        const action = incomingAction as KnownAction;

        switch (action.type) {
            case Actions.ToggleLoading:
                return { ...currentState, loading: action.loading }
            case Actions.Initialize:
                return initialState
            case Actions.SetErrors:
                return update(currentState,
                    {
                        hasServerSideErrors: { $set: action.hasServerSideErrors },
                        errors: { $set: action.errors },
                    });
            default:
                return currentState || initialState;
        }
    }
}