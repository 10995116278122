import React from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../../../store";
import PaginationBar from "../../Shared/PaginationBar";
import { UserItem } from "../../../models/Users/UserItem";
import IPageIndex from "../../../models/Pagination/IPageIndex";
import { UsersGridStore } from "../../../store/UsersGridStore";
import General from "../../../resources/AdminUI/General";
import { ApplicationPathBuilder } from "../../../enums/Common/ApplicationPaths";
import bind from "bind-decorator";
import UsersGrid from "../../../resources/AdminUI/Users/UsersGrid";
import { getPromiseFromAction } from "../../../utils/utils";
import ChangeUserTeamForm from "../Forms/ChangeUserTeamForm";
import { Modal } from "antd";
import ErrorMessages from "../../../resources/Common/ErrorMessages";
import { NavigateFunction } from "react-router";
import { withRouter } from "../../Shared/withRouter";
import { bindActionCreators } from "redux";
import ModalDialog from "../../Shared/ModalDialog";
import { TeamsStore } from "../../../store/TeamsStore";
import Loading from "../../Shared/Loading";

interface IProps {
  componentId: string;

  users?: UserItem[];
  numberOfUsers?: number;
  loading?: boolean;

  hiddenColumns?: string[];
  filters?: string;

  itemsPerPage?: number;
  firstIndexFromPage?: number;
  lastIndexFromPage?: number;
  pageIndexArray?: IPageIndex[];

  hasServerSideErrors?: boolean;

  navigate?: NavigateFunction;

  initialize?: (itemsPerPage: number, filter: string) => void;
  reload?: () => void;
  changeCurrentPage?: (currentPage: number) => void;
  changeUserTeam?: (teamId: number, userId: number) => any;
}

class UsersTable extends React.Component<IProps, any> {
  constructor(props) {
    super(props);
    this.state = {
      isChangeTeamModalOpen: false,
      selectedUser: null,
    };
  }

  public static defaultProps: Partial<IProps> = {
    loading: false,
  };

  itemsPerPage: number = 15;
  maxPagesDisplayed: number = 3;
  pageNeighbours: number = 1;
  hasRefreshButton: boolean = false;
  tableClass: string = "table";

  @bind
  goToUserDetails(userId: number) {
    this.props.navigate(ApplicationPathBuilder.ViewUserDetails(userId));
  }

  @bind
  showChangeTeamModal(user: UserItem) {
    this.setState({ isChangeTeamModalOpen: true, selectedUser: user });
  }

  @bind
  hideChangeTeamModal() {
    this.setState({ isChangeTeamModalOpen: false, selectedUser: null });
  }

  @bind
  handleChangeUserTeam(teamId: number, userId: number) {
    getPromiseFromAction(this.props.changeUserTeam(teamId, userId)).then(() => {
      if (this.props.hasServerSideErrors) {
        Modal.error({
          title: ErrorMessages.Resources.generalErrorMessage,
        });
      } else {
        this.props.reload();
        this.hideChangeTeamModal();
      }
    });
  }

  componentDidMount(): void {
    if (
      !this.props.users ||
      (this.props.users.length == 0 && !this.props.filters)
    ) {
      this.props.initialize(this.itemsPerPage, this.props.filters);
    } else {
      this.props.reload();
    }
  }

  componentDidUpdate(
    prevProps: Readonly<IProps>,
    prevState: Readonly<any>,
    snapshot?: any
  ): void {
    if (prevProps.componentId != this.props.componentId) {
      if (!this.props.users || this.props.users.length == 0) {
        this.props.initialize(this.itemsPerPage, this.props.filters);
      } else if (this.props.filters) {
        this.props.reload();
      }
    }
  }

  render() {
    if (this.props.loading) {
      return (
        <div>
          <Loading />
        </div>
      );
    } else if (!this.props.users || this.props.users.length == 0) {
      return <div>{General.Resources.noElements}</div>;
    } else {
      return (
        <div>
          <div className="table-wrapper users-table">
            <table className={this.tableClass}>
              <thead>
                <tr>
                  <th className="row-actions">
                    <span>{General.Resources.actions}</span>
                  </th>
                  {this.props.users[0].TrueProperties.filter(
                    (p) => this.props.hiddenColumns.indexOf(p) < 0
                  ).map((p) => (
                    <th key={p}>
                      {this.props.users[0].GetDisplayNameForProperty(p)}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {this.props.users.map((us) => {
                  return (
                    <tr key={us.id}>
                      <td className="row-actions">
                        <button
                          className="btn-table-action"
                          onClick={() => this.goToUserDetails(us.id)}
                        >
                          <span className="material-symbols-outlined">
                            visibility
                          </span>
                        </button>

                        {this.props.hiddenColumns.filter(
                          (c) => c === "changeTeamAction"
                        ).length == 0 && (
                          <div className="change-team-button">
                            <button
                              onClick={() => this.showChangeTeamModal(us)}
                            >
                              {UsersGrid.Resources.changeTeamAction}
                            </button>
                            <ModalDialog
                              title={null}
                              open={
                                this.state.isChangeTeamModalOpen &&
                                this.state.selectedUser == us
                              }
                              onCancel={this.hideChangeTeamModal}
                            >
                              <ChangeUserTeamForm
                                userId={us.id}
                                userName={us.email}
                                currentTeamId={us.teamId}
                                currentTeamName={us.teamName}
                                clientId={us.companyId}
                                onCancelCallback={this.hideChangeTeamModal}
                                onSaveCallback={this.handleChangeUserTeam}
                              />
                            </ModalDialog>
                          </div>
                        )}
                      </td>
                      {us.TrueProperties.filter(
                        (p) => this.props.hiddenColumns.indexOf(p) < 0
                      ).map((p) => (
                        <td key={p}>{us.GetDisplayValueForProperty(p)}</td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div>
            <PaginationBar
              useItemsPerPageOptions={false}
              totalItems={this.props.numberOfUsers}
              reload={this.props.reload}
              changeCurrentPage={this.props.changeCurrentPage}
              itemsPerPage={this.props.itemsPerPage}
              firstIndexFromPage={this.props.firstIndexFromPage}
              lastIndexFromPage={this.props.lastIndexFromPage}
              pageIndexArray={this.props.pageIndexArray}
              maxPagesDisplayed={this.maxPagesDisplayed}
              pageNeighbours={this.pageNeighbours}
              hasRefreshButton={this.hasRefreshButton}
            />
          </div>
        </div>
      );
    }
  }
}

export default withRouter(
  connect(
    (state: ApplicationState, ownProps: IProps) => {
      const componentState = state.users[ownProps.componentId];

      return {
        users: componentState?.users,
        filters: componentState?.filters,
        numberOfUsers: componentState?.numberOfUsers,
        loading: componentState?.loading,
        firstIndexFromPage: componentState?.firstIndexFromPage,
        lastIndexFromPage: componentState?.lastIndexFromPage,
        pageIndexArray: componentState?.pageIndexArray,
        ...ownProps,
      };
    },
    (dispatch, ownProps: IProps) =>
      bindActionCreators(
        {
          ...UsersGridStore.getActionCreators(ownProps.componentId),
          changeUserTeam: TeamsStore.actionCreators.addUserToTeam,
        },
        dispatch
      )
  )(UsersTable as any)
);
