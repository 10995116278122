import { ICountryItem } from "../models/CountriesAndCities/ICountryItem";
import { Action, Reducer } from "redux";
import { AppThunkAction } from ".";
import update from "immutability-helper";
import CountriesAndCitiesService from "../services/CountriesAndCitiesService";
import { ICityItem } from "../models/CountriesAndCities/ICityItem";
import { getServerSideErrors } from "../utils/utils";

export module CountriesAndCitiesStore {

    export interface IState {
        loading: boolean;
        loadingCities: boolean,
        countriesList: ICountryItem[];
        selectedCountry: ICountryItem;
        citiesList: ICityItem[];
        hasServerSideErrors: boolean;
        errors: string;
        saveCompleted: boolean;
    }

    export enum Actions {
        ToggleLoading = "COUNTRIES_AND_CITIES_TOGGLE_LOADING",
        ToggleLoadingCities = "COUNTRIES_AND_CITIES_TOGGLE_LOADING_CITIES",
        SetCountries = "COUNTRIES_AND_CITIES_SET_COUNTRIES",
        ChangeSelectedCountry = "COUNTRIES_AND_CITIES_CHANGE_SELECTED_COUNTRY",
        SetCities = "COUNTRIES_AND_CITIES_SET_CITIES",
        SetErrors = "COUNTRIES_AND_CITIES_SET_ERRORS",
        SaveCompleted = "COUNTRIES_AND_CITIES_SAVE_COMPLETED",
    }

    interface IToggleLoading {
        type: Actions.ToggleLoading,
        loading: boolean
    }

    interface IToggleLoadingCities {
        type: Actions.ToggleLoadingCities,
        loadingCities: boolean
    }

    interface ISetCountriesDetails {
        type: Actions.SetCountries,
        countriesList: ICountryItem[]
    }

    interface IChangeSelectedCountry {
        type: Actions.ChangeSelectedCountry,
        selectedCountry: ICountryItem
    }

    interface ISetCitiesDetails {
        type: Actions.SetCities,
        citiesList: ICityItem[]
    }

    interface ISetErrors {
        type: Actions.SetErrors,
        hasServerSideErrors: boolean,
        errors: string
    }

    interface ISaveCompleted {
        type: Actions.SaveCompleted,
        saveCompleted: boolean,
    }

    type KnownAction = IToggleLoading | ISetCountriesDetails
        | IChangeSelectedCountry | ISetCitiesDetails | ISetErrors | ISaveCompleted | IToggleLoadingCities;

    export const actionCreators = {
        initializePage: (countryId: number): AppThunkAction<KnownAction> => async (dispatch, getState) => {
            dispatch({ type: Actions.ToggleLoading, loading: true });
            dispatch({ type: Actions.ToggleLoadingCities, loadingCities: true });

            var countries = await CountriesAndCitiesService.getCountriesList().then(response => response.value);

            dispatch({ type: Actions.SetCountries, countriesList: countries });

            dispatch({ type: Actions.ChangeSelectedCountry, selectedCountry: countries.find(it => it.id == countryId) });

            var cities = await CountriesAndCitiesService.getCitiesForCountry(countryId).then(response => response.value);

            dispatch({ type: Actions.SetCities, citiesList: cities });

            dispatch({ type: Actions.ToggleLoadingCities, loadingCities: false });
            dispatch({ type: Actions.ToggleLoading, loading: false });
        },
        changeSelectedCountry: (country: ICountryItem): AppThunkAction<KnownAction> => async (dispatch, getState) => {
            dispatch({ type: Actions.ChangeSelectedCountry, selectedCountry: country })

            dispatch({ type: Actions.ToggleLoadingCities, loadingCities: true });

            var cities = await CountriesAndCitiesService.getCitiesForCountry(country.id).then(response => response.value);

            dispatch({ type: Actions.SetCities, citiesList: cities });

            dispatch({ type: Actions.ToggleLoadingCities, loadingCities: false });
        },
        saveCity: (city: ICityItem): AppThunkAction<KnownAction> => async (dispatch, getState) => {
            var serverResponse;
            if (city.id == 0) {
                serverResponse = await CountriesAndCitiesService.addCity(city).then(response => response);
            }
            else {
                serverResponse = await CountriesAndCitiesService.updateCity(city).then(response => response);
            }

            var errors = getServerSideErrors(serverResponse);
            var hasServerSideErrors = errors ? true : false;

            dispatch({ type: Actions.SetErrors, hasServerSideErrors: hasServerSideErrors, errors: errors });

            if (!hasServerSideErrors) {
                var cities = await CountriesAndCitiesService.getCitiesForCountry(city.countryId).then(response => response.value);

                dispatch({ type: Actions.SetCities, citiesList: cities });

                dispatch({ type: Actions.SaveCompleted, saveCompleted: true });
            }
        },
        reset: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
            dispatch({ type: Actions.SetErrors, hasServerSideErrors: false, errors: '' });
            dispatch({ type: Actions.SaveCompleted, saveCompleted: false });
        },
    }

    const initialState: IState = {
        loading: false,
        loadingCities: false,
        countriesList: [],
        selectedCountry: null,
        citiesList: [],
        hasServerSideErrors: false,
        errors: "",
        saveCompleted: false
    }

    export const reducer: Reducer<IState> = (currentState: IState, incomingAction: Action) => {
        const action = incomingAction as KnownAction;

        switch (action.type) {
            case Actions.ToggleLoading:
                return update(currentState,
                    {
                        loading: { $set: action.loading }
                    });
            case Actions.ToggleLoadingCities:
                return update(currentState,
                    {
                        loadingCities: { $set: action.loadingCities }
                    });
            case Actions.SetCountries:
                return update(currentState,
                    {
                        countriesList: { $set: action.countriesList }
                    });
            case Actions.ChangeSelectedCountry:
                return update(currentState,
                    {
                        selectedCountry: { $set: action.selectedCountry }
                    });
            case Actions.SetCities:
                return update(currentState,
                    {
                        citiesList: { $set: action.citiesList }
                    });
            case Actions.SetErrors:
                return update(currentState,
                    {
                        hasServerSideErrors: { $set: action.hasServerSideErrors },
                        errors: { $set: action.errors },
                    });
            case Actions.SaveCompleted:
                return update(currentState, {
                    saveCompleted: { $set: action.saveCompleted }
                })
            default:
                return currentState || initialState;
        }
    }
}