import React from "react";
import { ITeam } from "../../models/Teams/Interfaces/ITeam";
import FormInput from "../Shared/FormInput";
import { EntityFieldInputType } from "../../utils/reactUtils";
import bind from "bind-decorator";
import { getPromiseFromAction, nameof } from "../../utils/utils";
import General from "../../resources/AdminUI/General";
import { connect } from "react-redux";
import { ApplicationState } from "../../store";
import { ValidatedComponent } from "../FormValidations/Base/ValidatedComponent";
import { AddOrEditTeamValidator } from "../FormValidations/Validators/AddOrEditTeamValidator";
import { BookingRestrictionTypes } from "../../enums/Teams/BookingRestrictionTypes";
import { BookingRestrictionCategories } from "../../enums/Teams/BookingRestrictionCategories";
import { ISelectListItem } from "../../models/Common/ISelectListItem";
import AddOrEditTeam from "../../resources/AdminUI/Teams/AddOrEditTeam";
import { IAntdSelectListItem } from "../../models/Common/IAntdSelectListItem";
import { Select } from "antd";
import { withRouter } from "../Shared/withRouter";
import { AddOrEditTeamStore } from "../../store/AddOrEditTeamStore";
import { ApplicationPathBuilder } from "../../enums/Common/ApplicationPaths";
import { NavigateFunction } from "react-router";
import Validations from "../../resources/Common/Validations";

interface IProps {
    team?: ITeam,
    spaceCategories: ISelectListItem[],
    weekDays: ISelectListItem[],
    countries?: IAntdSelectListItem[],
    cities?: IAntdSelectListItem[],

    hasServerSideErrors?: boolean;
    errors?: string;

    navigate?: NavigateFunction,

    addOrEditTeam: (team: ITeam) => any
}

interface IState {
    team?: ITeam
}

class AddOrEditTeamForm extends ValidatedComponent<IProps, IState> {
    constructor(props) {
        super(props)

        this.state = {
            team: {
                id: this.props.team.id,
                name: this.props.team.name,
                defaultTeam: this.props.team.defaultTeam,
                description: this.props.team.description,
                companyId: this.props.team.companyId,
                createdAt: this.props.team.createdAt,
                spaceTypeRestrictionsType: this.props.team.spaceTypeRestrictionsType,
                countryRestrictionsType: this.props.team.countryRestrictionsType,
                cityRestrictionsType: this.props.team.cityRestrictionsType,
                weekDayRestrictionsType: this.props.team.weekDayRestrictionsType,
                spaceRestrictionsType: this.props.team.spaceRestrictionsType,
                countryIds: this.props.team.countryIds,
                cityIds: this.props.team.cityIds,
                spaceTypeIds: this.props.team.spaceTypeIds,
                weekDayIds: this.props.team.weekDayIds,
                spaceIds: this.props.team.spaceIds,
                benefits: this.props.team.benefits,
                maxDailyCheckins: this.props.team.maxDailyCheckins,
            }
        }

    }

    @bind
    handleInputChange(name: string, data: any) {
        this.setState({
            team: {
                ...this.state.team,
                [name]: data
            }
        });
    }

    @bind
    onMultipleSelectChange(name: string, data: ISelectListItem[]) {
        var selectedIds = data.map(item => item.value);
        this.setState({
            team: {
                ...this.state.team,
                [name]: selectedIds
            }
        });
    }

    @bind
    handleOnSaveButtonClick() {
        this.validate(() => {
            getPromiseFromAction(this.props.addOrEditTeam(this.state.team)).then(() => {
                this.props.navigate(ApplicationPathBuilder.ViewCustomerDetails(this.props.team.companyId));
            });
        })
    }
    @bind
    handleOnCancleButtonClick() {
        this.props.navigate(ApplicationPathBuilder.ViewCustomerDetails(this.props.team.companyId));
    }

    @bind
    setRestrictionsType(bookingRestrictionType: BookingRestrictionTypes, bookingRestrictionCategory: BookingRestrictionCategories) {
        switch (bookingRestrictionCategory.toString()) {
            case BookingRestrictionCategories.SpaceType.toString():
                this.setState({
                    team: {
                        ...this.state.team,
                        spaceTypeIds: [],
                        spaceTypeRestrictionsType: bookingRestrictionType
                    }
                });
                break;
            case BookingRestrictionCategories.Country.toString():
                this.setState({
                    team: {
                        ...this.state.team,
                        countryIds: [],
                        countryRestrictionsType: bookingRestrictionType
                    }
                });
                break;
            case BookingRestrictionCategories.City.toString():
                this.setState({
                    team: {
                        ...this.state.team,
                        cityIds: [],
                        cityRestrictionsType: bookingRestrictionType
                    }
                });
                break;
            case BookingRestrictionCategories.Space.toString():
                this.setState({
                    team: {
                        ...this.state.team,
                        spaceIds: [],
                        spaceRestrictionsType: bookingRestrictionType
                    }
                });
                break;
            case BookingRestrictionCategories.WeekDay.toString():
                this.setState({
                    team: {
                        ...this.state.team,
                        weekDayIds: [],
                        weekDayRestrictionsType: bookingRestrictionType
                    }
                });
                break;

        }
    }

    render() {
        return <div className="form-container">

            <div className="form-line">
                <div className="line-info">
                    <div className="title">{AddOrEditTeam.Resources.name}<span className="mandatory">*</span></div>
                </div>
                <div className="line-action">
                    <FormInput
                        placeholder={AddOrEditTeam.Resources.name}
                        inputType={EntityFieldInputType.Text}
                        className={"form-control"}
                        value={this.state.team.name}
                        name={nameof(s => s.name)}
                        onChange={this.handleInputChange}
                        errors={this.getFieldErrors(nameof(s => s.name))}
                    />
                </div>
            </div>

            <div className="form-line">
                <div className="line-info">
                    <div className="title">{AddOrEditTeam.Resources.description}</div>
                </div>
                <div className="line-action">
                    <FormInput
                        placeholder={AddOrEditTeam.Resources.description}
                        inputType={EntityFieldInputType.Text}
                        className={"form-control"}
                        value={this.state.team.description}
                        name={nameof(s => s.description)}
                        onChange={this.handleInputChange}
                        errors={this.getFieldErrors(nameof(s => s.description))}
                    />
                </div>
            </div>

            <div className="form-line">
                <div className="line-info">
                    <div className="title">{AddOrEditTeam.Resources.seeTypeOfSpace}<span className="mandatory">*</span></div>
                    <div className="description">{AddOrEditTeam.Resources.chooseTypesOfSpacesEmployeesCanSeeInPluriaApp}</div>
                </div>
                <div className="line-action">
                    <div className="team-modal-tabs">
                        <div
                            onClick={() => this.setRestrictionsType(BookingRestrictionTypes.None, BookingRestrictionCategories.SpaceType)}
                            className={this.state.team.spaceTypeRestrictionsType == BookingRestrictionTypes.None ? "tab-selected" : "tab"}>{AddOrEditTeam.Resources.none}</div>
                        <div
                            onClick={() => this.setRestrictionsType(BookingRestrictionTypes.Allow, BookingRestrictionCategories.SpaceType)}
                            className={this.state.team.spaceTypeRestrictionsType == BookingRestrictionTypes.Allow ? "tab-selected" : "tab"}>{AddOrEditTeam.Resources.allow}</div>
                        <div
                            onClick={() => this.setRestrictionsType(BookingRestrictionTypes.Deny, BookingRestrictionCategories.SpaceType)}
                            className={this.state.team.spaceTypeRestrictionsType == BookingRestrictionTypes.Deny ? "tab-selected" : "tab"}>{AddOrEditTeam.Resources.deny}</div>
                    </div>

                    {this.state.team.spaceTypeRestrictionsType == BookingRestrictionTypes.Allow || this.state.team.spaceTypeRestrictionsType == BookingRestrictionTypes.Deny ?
                        <>
                            <FormInput
                                inputType={EntityFieldInputType.Checkbox}
                                options={this.props.spaceCategories.map(op => { op.selected = this.state.team.spaceTypeIds.findIndex(e => e.toString() == op.value.toString()) >= 0; return op; })}
                                className={"check-group"}
                                name={nameof(e => e.spaceTypeIds)}
                                value={this.state.team.spaceTypeIds}
                                onChange={(name: string, data: any) => {
                                    this.setState({
                                        team: {
                                            ...this.state.team,
                                            spaceTypeIds: data.map(e => Number(e.value))
                                        }
                                    })
                                }}
                                errors={this.getFieldErrors(nameof(e => e.spaceTypeIds))}
                            />
                        </> : null}
                </div>
            </div>

            <div className="form-line">
                <div className="line-info">
                    <div className="title">{AddOrEditTeam.Resources.restrictionsForBookingInCountries}<span className="mandatory">*</span></div>
                    <div className="description">{AddOrEditTeam.Resources.chooseInWhichCountriesEmployeeCanMakeBookings}</div>
                </div>
                <div className="line-action">
                    <div className="team-modal-tabs">
                        <div
                            onClick={() => this.setRestrictionsType(BookingRestrictionTypes.None, BookingRestrictionCategories.Country)}
                            className={this.state.team.countryRestrictionsType == BookingRestrictionTypes.None ? "tab-selected" : "tab"}>{AddOrEditTeam.Resources.none}</div>
                        <div
                            onClick={() => this.setRestrictionsType(BookingRestrictionTypes.Allow, BookingRestrictionCategories.Country)}
                            className={this.state.team.countryRestrictionsType == BookingRestrictionTypes.Allow ? "tab-selected" : "tab"}>{AddOrEditTeam.Resources.allow}</div>
                        <div
                            onClick={() => this.setRestrictionsType(BookingRestrictionTypes.Deny, BookingRestrictionCategories.Country)}
                            className={this.state.team.countryRestrictionsType == BookingRestrictionTypes.Deny ? "tab-selected" : "tab"}>{AddOrEditTeam.Resources.deny}</div>
                    </div>

                    {this.state.team.countryRestrictionsType == BookingRestrictionTypes.Allow || this.state.team.countryRestrictionsType == BookingRestrictionTypes.Deny ?
                        <>
                            <div className="input-group form-select">
                                <Select
                                    allowClear
                                    mode="multiple"
                                    options={this.props.countries}
                                    onChange={(values, option) => {
                                        var countryIds = values.map(Number) as number[];
                                        this.setState({
                                            team: {
                                                ...this.state.team,
                                                countryIds: countryIds
                                            }
                                        });
                                    }}
                                    placeholder={General.Resources.select}
                                    value={this.props.countries.filter(op => this.state.team.countryIds.findIndex(e => e.toString() == op.value.toString()) >= 0)}
                                    showSearch
                                    optionFilterProp="label"
                                ></Select>
                                {this.getFieldErrors(nameof(s => s.countryIds)).map(err => <span key={err} className="field-validation-error">{err}</span>)}
                            </div>
                        </> : null}

                </div>

            </div>

            <div className="form-line">
                <div className="line-info">
                    <div className="title">{AddOrEditTeam.Resources.restrictionsForBookingInCities}<span className="mandatory">*</span></div>
                    <div className="description">{AddOrEditTeam.Resources.chooseInWhichCitiesEmployeesCanMakeBookingsInPluriaApp}</div>
                </div>
                <div className="line-action">
                    <div className="team-modal-tabs">
                        <div
                            onClick={() => this.setRestrictionsType(BookingRestrictionTypes.None, BookingRestrictionCategories.City)}
                            className={this.state.team.cityRestrictionsType == BookingRestrictionTypes.None ? "tab-selected" : "tab"}>{AddOrEditTeam.Resources.none}</div>
                        <div
                            onClick={() => this.setRestrictionsType(BookingRestrictionTypes.Allow, BookingRestrictionCategories.City)}
                            className={this.state.team.cityRestrictionsType == BookingRestrictionTypes.Allow ? "tab-selected" : "tab"}>{AddOrEditTeam.Resources.allow}</div>
                        <div
                            onClick={() => this.setRestrictionsType(BookingRestrictionTypes.Deny, BookingRestrictionCategories.City)}
                            className={this.state.team.cityRestrictionsType == BookingRestrictionTypes.Deny ? "tab-selected" : "tab"}>{AddOrEditTeam.Resources.deny}</div>
                    </div>

                    {this.state.team.cityRestrictionsType == BookingRestrictionTypes.Allow || this.state.team.cityRestrictionsType == BookingRestrictionTypes.Deny ?
                        <>
                            <div className="input-group form-select">
                                <Select
                                    allowClear
                                    mode="multiple"
                                    options={this.props.cities}
                                    onChange={(values, option) => {
                                        var cityIds = values.map(Number) as number[];
                                        this.setState({
                                            team: {
                                                ...this.state.team,
                                                cityIds: cityIds
                                            }
                                        });
                                    }}
                                    placeholder={General.Resources.select}
                                    value={this.props.cities.filter(op => this.state.team.cityIds.findIndex(e => e.toString() == op.value.toString()) >= 0)}
                                    showSearch
                                    optionFilterProp="label"
                                ></Select>
                                {this.getFieldErrors(nameof(s => s.cityIds)).map(err => <span key={err} className="field-validation-error">{err}</span>)}
                            </div>
                        </> : null}
                </div>
            </div>

            <div className="form-line">
                <div className="line-info">
                    <div className="title">{AddOrEditTeam.Resources.restrictionsForBookingInSpaces}<span className="mandatory">*</span></div>
                    <div className="description">{AddOrEditTeam.Resources.chooseInWhichSpacesEmployeesCanMakeBookingsInPluriaApp}</div>
                </div>
                <div className="line-action">
                    <div className="team-modal-tabs">
                        <div
                            onClick={() => this.setRestrictionsType(BookingRestrictionTypes.None, BookingRestrictionCategories.Space)}
                            className={this.state.team.spaceRestrictionsType == BookingRestrictionTypes.None ? "tab-selected" : "tab"}>{AddOrEditTeam.Resources.none}</div>
                        <div
                            onClick={() => this.setRestrictionsType(BookingRestrictionTypes.Allow, BookingRestrictionCategories.Space)}
                            className={this.state.team.spaceRestrictionsType == BookingRestrictionTypes.Allow ? "tab-selected" : "tab"}>{AddOrEditTeam.Resources.allow}</div>
                        <div
                            onClick={() => this.setRestrictionsType(BookingRestrictionTypes.Deny, BookingRestrictionCategories.Space)}
                            className={this.state.team.spaceRestrictionsType == BookingRestrictionTypes.Deny ? "tab-selected" : "tab"}>{AddOrEditTeam.Resources.deny}</div>
                    </div>

                    {this.state.team.spaceRestrictionsType == BookingRestrictionTypes.Allow || this.state.team.spaceRestrictionsType == BookingRestrictionTypes.Deny ?
                        <>
                            <FormInput
                                placeholder={AddOrEditTeam.Resources.restrictionsForBookingInSpaces}
                                inputType={EntityFieldInputType.Text}
                                className={"form-control"}
                                name={nameof(s => s.spaceIds)}
                                value={this.state.team.spaceIds}
                                onChange={(name: string, data: any) => {
                                    var spaceIds = data.split(",").filter(Number).map(Number);
                                    this.handleInputChange(name, spaceIds);
                                }}
                                errors={this.getFieldErrors(nameof(s => s.spaceIds))}
                            />
                        </> : null}

                </div>
            </div>

            <div className="form-line">
                <div className="line-info">
                    <div className="title">{AddOrEditTeam.Resources.excludeWeekDays}<span className="mandatory">*</span></div>
                    <div className="description">{AddOrEditTeam.Resources.chooseInWhichDaysEmployeesCanMakeBookingsInPluriaApp}</div>
                </div>
                <div className="line-action">
                    <div className="team-modal-tabs">
                        <div
                            onClick={() => this.setRestrictionsType(BookingRestrictionTypes.None, BookingRestrictionCategories.WeekDay)}
                            className={this.state.team.weekDayRestrictionsType == BookingRestrictionTypes.None ? "tab-selected" : "tab"}>{AddOrEditTeam.Resources.none}</div>
                        <div
                            onClick={() => this.setRestrictionsType(BookingRestrictionTypes.Allow, BookingRestrictionCategories.WeekDay)}
                            className={this.state.team.weekDayRestrictionsType == BookingRestrictionTypes.Allow ? "tab-selected" : "tab"}>{AddOrEditTeam.Resources.allow}</div>
                        <div
                            onClick={() => this.setRestrictionsType(BookingRestrictionTypes.Deny, BookingRestrictionCategories.WeekDay)}
                            className={this.state.team.weekDayRestrictionsType == BookingRestrictionTypes.Deny ? "tab-selected" : "tab"}>{AddOrEditTeam.Resources.deny}</div>
                    </div>

                    {this.state.team.weekDayRestrictionsType == BookingRestrictionTypes.Allow || this.state.team.weekDayRestrictionsType == BookingRestrictionTypes.Deny ?
                        <>
                            <FormInput
                                inputType={EntityFieldInputType.Checkbox}
                                options={this.props.weekDays.map(op => { op.selected = this.state.team.weekDayIds.findIndex(e => e.toString() == op.value.toString()) >= 0; return op; })}
                                className="check-group"
                                name={nameof(e => e.weekDayIds)}
                                value={this.state.team.weekDayIds}
                                onChange={(name: string, data: any) => {
                                    this.setState({
                                        team: {
                                            ...this.state.team,
                                            weekDayIds: data.map(e => Number(e.value))
                                        }
                                    })
                                }}
                                errors={this.getFieldErrors(nameof(e => e.weekDayIds))}
                            />
                        </> : null}
                </div>
            </div>

            <div className="form-line">
                <div className="line-info">
                    <div className="title">{AddOrEditTeam.Resources.maxNrOfDailyCheckins}<span className="mandatory">*</span></div>
                </div>
                <div className="line-action">
                    <FormInput
                        placeholder={AddOrEditTeam.Resources.maxNrOfDailyCheckins}
                        inputType={EntityFieldInputType.Number}
                        className={"form-control"}
                        name={nameof(s => s.maxDailyCheckins)}
                        value={this.state.team.maxDailyCheckins}
                        onChange={this.handleInputChange}
                        errors={this.getFieldErrors(nameof(s => s.maxDailyCheckins))}
                    />
                </div>
            </div>

            {this.props.hasServerSideErrors ? <div><span className="field-validation-error" style={{ color: "red" }}>{this.props.errors}</span></div> : <></>}

            {this.state.errors && Object.keys(this.state.errors).length ?
                <div>
                    < div >
                        <span className="field-validation-error" style={{ color: "red" }}>{Validations.Resources.notAllFieldsAreValid}</span></div>
                        {Object.values(this.state.errors).map(errorValues => {
                            return (errorValues as any).map(err => <span key={err} className="field-validation-error">{err}</span>)
                        })}
                </div>
                :
                <></>
            }

            <div className="form-line form-bottom-actions">
                <button className="btn-big btn-secondary" onClick={this.handleOnCancleButtonClick}>{General.Resources.cancelButtonLabel}</button>
                <button className="btn-big btn-primary" onClick={this.handleOnSaveButtonClick}>{General.Resources.save}</button>
            </div>
        </div>
    }
}

export default withRouter(connect(
    (state: ApplicationState, ownProps: IProps) => {
        return {
            validator: AddOrEditTeamValidator,
            ...ownProps,
            hasServerSideErrors: state.addOrEditTeam.hasServerSideErrors,
            errors: state.addOrEditTeam.errors,
            countries: state.lookup.allCountries,
            cities: state.lookup.cities,
            team: state.addOrEditTeam.team
        }
    },
    {
        addOrEditTeam: AddOrEditTeamStore.actionCreators.addOrEditTeam
    }
)(AddOrEditTeamForm as any));