import * as React from "react";
import { ISelectListItem } from "../../models/Common/ISelectListItem";
import { EntityFieldInputType } from "../../utils/reactUtils";
import bind from "bind-decorator";
import DatePicker from "react-datepicker";
import Loader from "../Shared/Loader";
import dayjs from "dayjs";

interface IProps {
  inputType?: EntityFieldInputType;
  placeholder?: string;
  label?: string;
  leadingText?: string;
  className?: string;
  errors?: string[];
  options?: ISelectListItem[];
  value?: any;
  readOnly?: boolean;
  hidden?: boolean;
  refreshData?: () => any;
  isDataLoading?: boolean;
  isFocused?: boolean;
  cursorPosition?: number;

  onChange?: (propName: string, data: any, cursorPosition?: number) => void;
  onKeyDown?: (e: React.FormEvent<HTMLInputElement>) => void;

  name: string;
}

export default class FormInput extends React.PureComponent<IProps, {}> {
  constructor(props) {
    super(props);
    if (this.props.inputType == EntityFieldInputType.Date) {
      if (!this.props.value) {
        this.initialValue = "";
      } else {
        this.initialValue = dayjs(new Date(this.props.value))
          .format("YYYY-MM-DD HH:mm")
          .replace("T", " ");
      }
    }
  }

  initialValue: string = "";
  initialSelectedValue: string = "";
  input: HTMLInputElement;

  public static defaultProps: Partial<IProps> = {
    inputType: "text" as EntityFieldInputType,
    errors: [],
  };

  componentDidMount() {
    if (
      this.props.isFocused &&
      this.props.inputType &&
      (this.props.inputType == EntityFieldInputType.Text ||
        this.props.inputType == EntityFieldInputType.Number ||
        this.props.inputType == EntityFieldInputType.Password)
    ) {
      this.input.focus();
      this.input.selectionEnd = this.props.cursorPosition;
    }
  }

  @bind
  onCheckboxChange(e: React.FormEvent<HTMLInputElement>) {
    var copy = this.props.options;

    copy.map((u) => {
      if (u.value == e.currentTarget.value) u.selected = !u.selected;
    });

    this.props.onChange(
      this.props.name,
      copy.filter((c) => c.selected)
    );
  }

  @bind
  onDateInputChange(propName: string, e: Date) {
    this.props.onChange(
      propName,
      dayjs(new Date(e)).format("YYYY-MM-DD HH:mm").replace(" ", "T")
    );
  }

  @bind
  onInputChange(e: React.FormEvent<HTMLInputElement>) {
    var value;
    var selectionEnd = null;
    if (
      this.props.inputType == EntityFieldInputType.Text ||
      this.props.inputType == EntityFieldInputType.Number ||
      this.props.inputType == EntityFieldInputType.Password
    ) {
      selectionEnd = this.input.selectionEnd;
    }
    if (e.currentTarget.type == "file") {
      var url = URL.createObjectURL(e.currentTarget.files[0]);
      var uploadedFile = { formFile: e.currentTarget.files[0], fileUrl: url };
      this.props.onChange(e.currentTarget.name, uploadedFile, selectionEnd);
    } else {
      value = e.currentTarget.value;
      this.props.onChange(e.currentTarget.name, value, selectionEnd);
    }
  }

  @bind
  onImageInputChange(e: React.FormEvent<HTMLInputElement>) {
    var fileUrl = URL.createObjectURL(e.currentTarget.files[0]);
    var uploadedImage = {
      formFile: e.currentTarget.files[0],
      fileUrl: fileUrl,
    };
    this.props.onChange(this.props.name, uploadedImage, null);
  }
  @bind
  onImageInputRemove(e) {
    this.props.onChange(this.props.name, { formFile: null, fileUrl: "" }, null);
  }

  render() {
    if (
      this.props.inputType == EntityFieldInputType.Text ||
      this.props.inputType == EntityFieldInputType.Number ||
      this.props.inputType == EntityFieldInputType.Password
    ) {
      return (
        <div className="form-item">
          {this.props.label ? <label>{this.props.label}</label> : null}
          <div className="input-group">
            {this.props.leadingText ? (
              <span className="input-group-text">{this.props.leadingText}</span>
            ) : null}
            <input
              type={this.props.inputType.toString()}
              ref={(node) => (this.input = node)}
              placeholder={this.props.placeholder}
              className={`${this.props.className} ${
                this.props.errors.length > 0 ? "input-validation-error" : ""
              }`}
              onChange={this.props.readOnly ? null : this.onInputChange}
              name={this.props.name}
              autoComplete={
                this.props.inputType == EntityFieldInputType.Password
                  ? "new-password"
                  : ""
              }
              defaultValue={this.props.value}
              readOnly={this.props.readOnly}
              lang="en"
              hidden={this.props.hidden}
              onKeyDown={this.props.onKeyDown}
            />
          </div>
          {this.props.errors.map((err) => (
            <span key={err} className="field-validation-error">
              {err}
            </span>
          ))}
        </div>
      );
    }
    if (this.props.inputType == EntityFieldInputType.File) {
      var fileUrl =
        this.props.value.formFile && this.props.value.formFile.name.trim() != ""
          ? URL.createObjectURL(this.props.value.formFile)
          : this.props.value.fileUrl;
      var fileLabel =
        this.props.value.formFile && this.props.value.formFile.name.trim() != ""
          ? this.props.value.formFile.name
          : this.props.label;
      return (
        <div className="form-item">
          {this.props.label ? <label>{this.props.label}</label> : null}
          {fileUrl ? (
            <a target="_" href={fileUrl}>
              Download {fileLabel}
            </a>
          ) : null}
          <div className="form-upload">
            <input
              type={this.props.inputType.toString()}
              placeholder={this.props.placeholder}
              className={`${
                this.props.errors.length > 0 ? "input-validation-error" : ""
              }`}
              onChange={this.props.readOnly ? null : this.onInputChange}
              name={this.props.name}
              readOnly={this.props.readOnly}
              hidden={this.props.hidden}
            />
            <div className="form-upload-wrapper">
              <label>
                {this.props.value.formFile &&
                this.props.value.formFile.name.trim() != ""
                  ? this.props.value.formFile.name
                  : "No File Chosen"}
              </label>
              {this.props.value.formFile &&
              this.props.value.formFile.name.trim() != "" ? (
                <></>
              ) : (
                <span>Choose File</span>
              )}
            </div>
          </div>
          {this.props.errors.map((err) => (
            <span key={err} className="field-validation-error">
              {err}
            </span>
          ))}
        </div>
      );
    }
    if (this.props.inputType == EntityFieldInputType.Image) {
      var imageUrl =
        this.props.value.formFile && this.props.value.formFile.name.trim() != ""
          ? URL.createObjectURL(this.props.value.formFile)
          : this.props.value.fileUrl;
      return (
        <div className="form-item">
          {this.props.label ? <label>{this.props.label}</label> : null}
          <div className="form-upload">
            {imageUrl ? (
              <div>
                <img width="250px" src={imageUrl} />
                <span onClick={this.onImageInputRemove}>X</span>
              </div>
            ) : (
              <div>
                <input
                  type="file"
                  placeholder={this.props.placeholder}
                  className={`${
                    this.props.errors.length > 0 ? "input-validation-error" : ""
                  }`}
                  onChange={
                    this.props.readOnly ? null : this.onImageInputChange
                  }
                  name={this.props.name}
                  readOnly={this.props.readOnly}
                  hidden={this.props.hidden}
                />
                <div className="form-upload-wrapper">
                  <label>
                    {this.props.value.formFile &&
                    this.props.value.formFile.name.trim() != ""
                      ? this.props.value.formFile.name
                      : "No File Chosen"}
                  </label>
                </div>
              </div>
            )}
          </div>
          {this.props.errors.map((err) => (
            <span key={err} className="field-validation-error">
              {err}
            </span>
          ))}
        </div>
      );
    }
    if (this.props.inputType == EntityFieldInputType.Radio) {
      if (this.props.options && this.props.options.length > 0) {
        return (
          <div className="form-item">
            {this.props.label ? <label>{this.props.label}</label> : null}
            <div className={this.props.className}>
              {this.props.options.map((op) => {
                return (
                  <div
                    className="radio"
                    style={{ display: "flex", gap: "8px" }}
                    key={op.value}
                  >
                    <input
                      type="radio"
                      onChange={this.props.readOnly ? null : this.onInputChange}
                      value={op.value}
                      name={this.props.name}
                      readOnly={this.props.readOnly}
                      checked={this.props.value.toString() == op.value}
                    />{" "}
                    {op.text} <span></span>
                  </div>
                );
              })}
              {this.props.errors.map((err) => (
                <span key={err} className="field-validation-error">
                  {err}
                </span>
              ))}
            </div>
          </div>
        );
      } else {
        if (this.props.refreshData && !this.props.isDataLoading) {
          this.props.refreshData();
        }
        return <Loader show={true} />;
      }
    }
    if (this.props.inputType == EntityFieldInputType.Date) {
      return (
        <div className="form-item">
          {this.props.label ? <label>{this.props.label}</label> : null}
          <br />
          <DatePicker
            className={`${this.props.className} ${
              this.props.readOnly ? "readonly-date" : ""
            }`}
            name={this.props.name}
            showTimeSelect={true}
            value={this.initialValue}
            selected={new Date(this.initialValue)}
            timeFormat="HH:mm"
            dateFormat="dd-MM-yyyy HH:mm"
            //utcOffset="3" trebuie facut cu ceva automat/dinamic
            onChangeRaw={(e) => {
              e.preventDefault();
            }}
            disabled={this.props.readOnly}
            onChange={(e) => {
              if (!this.props.readOnly) {
                this.onDateInputChange(this.props.name, e);
              }
            }}
          />
          {this.props.errors.map((err) => (
            <span key={err} className="field-validation-error">
              {err}
            </span>
          ))}
        </div>
      );
    }
    if (this.props.inputType == EntityFieldInputType.Checkbox) {
      if (this.props.options && this.props.options.length > 0) {
        return (
          <div className="form-item">
            {this.props.label ? <label>{this.props.label}</label> : null}
            <div className={this.props.className}>
              {this.props.options.map((ch) => {
                return (
                  <label
                    key={ch.value}
                    style={{ display: "flex", gap: "8px" }}
                    className="checkbox"
                  >
                    <input
                      type="checkbox"
                      onChange={
                        this.props.readOnly || ch.disabled
                          ? null
                          : this.onCheckboxChange
                      }
                      checked={ch.selected || ch.value == this.props.value}
                      value={ch.value}
                      readOnly={this.props.readOnly || ch.disabled}
                      name={this.props.name}
                    />
                    {ch.text}
                    <span></span>
                  </label>
                );
              })}
            </div>
            {this.props.errors.map((err) => (
              <span key={err} className="field-validation-error">
                {err}
              </span>
            ))}
          </div>
        );
      } else {
        if (this.props.refreshData && !this.props.isDataLoading) {
          this.props.refreshData();
        }
        return <Loader show={true} />;
      }
    }
    return null;
  }
}
