
import bind from 'bind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';
import { v4 } from 'uuid';
import { ISaveForm } from '../../models/Common/ISaveForm';
import { ApplicationState } from '../../store';
import { String_Format, nameof } from '../../utils/utils';
import { ValidatedComponent } from '../FormValidations/Base/ValidatedComponent';
import DnDGrid from './DnDGrid';
import Gallery from '../../resources/AdminUI/Gallery';
import SpacesService from '../../services/SpacesService';
import Validations from '../../resources/Common/Validations';
import { AppSettings } from '../../utils/settings';

interface IProps {
    images?: string[],
    onSaveCallback?: (newOrderedImages: string[]) => void
}

interface IState {
    currentImages: string[],
    errors: string
}

export default class ImageGalleryUpload extends ValidatedComponent<IProps, IState> implements ISaveForm {
    constructor(props) {
        super(props);
        this.state = {
            currentImages: this.props.images ? this.props.images : null,
            errors: ""
        };

    }

    static getFileExtension(filename: any) {
        var parts = filename.split('.');
        return parts[parts.length - 1];
    }
    
    static hasFileExtension(file: any, ...acceptedExtensions: string[]) {
        var fileExtension = ImageGalleryUpload.getFileExtension(file.name);
        return acceptedExtensions.indexOf(fileExtension) != -1;
    }


    @bind
    onSave() {
        return this.validate(
            () => this.props.onSaveCallback(this.state.currentImages)
        )
    }

    acceptedFormats: string[] = ['jpg', 'jpeg', 'png', 'webp']

    @bind
    uploadImagesWithInput(e) {
        var imagesToBeSaved = [];

        var errors = [];
        [...e.target.files].map(img => {
            if (ImageGalleryUpload.hasFileExtension(img, ...this.acceptedFormats)) {
                if (img.size / (1024 ** 2) < 2) {
                    imagesToBeSaved.push({ id: v4(), file: img });
                } else {
                    errors.push(String_Format(Validations.Resources.fileIsTooLarge, img.name));
                }
            } else {
                errors.push(String_Format(Validations.Resources.theFileSelectedIsNotTheCorrectFormat, img.name) );
            }
        });

        this.setState({errors: errors.join(", ")});

        if(imagesToBeSaved.length > 0){
            SpacesService.saveSpaceGallery(imagesToBeSaved).then(
                result => {
                    var newCurrentImages = [...this.state.currentImages];
                    result.value.map(r => {
                        newCurrentImages.push(r);
                    })
    
                    this.setState({ ...this.state, currentImages: [...newCurrentImages] });
                    this.props.onSaveCallback(newCurrentImages);
                }
            );
        }
    }

    @bind
    uploadImagesWithDragDrop(e) {
        e.preventDefault();
        var imagesToBeSaved = [];
        var errors = [];
        if (e.dataTransfer.items) {
            [...e.dataTransfer.items].map((item, i) => {
                if (item.kind === 'file') {
                    const file = item.getAsFile();
                    if (ImageGalleryUpload.hasFileExtension(file, ...this.acceptedFormats)) {
                        if (file.size / (1024 ** 2) < 2) {
                            imagesToBeSaved.push({ id: v4(), file: file });
                        }
                        else {
                            errors.push(String_Format(Validations.Resources.fileIsTooLarge, file.name));
                        }
                    } else {
                        errors.push(String_Format(Validations.Resources.theFileSelectedIsNotTheCorrectFormat, file.name) );
                    }
                }
            });
        } else {
            [...e.dataTransfer.files].map((file, i) => {
                if (ImageGalleryUpload.hasFileExtension(file, ...this.acceptedFormats)) {
                    if (file.size / (1024 ** 2) < 2) {
                        imagesToBeSaved.push({ id: v4(), file: file });
                    }
                    else {
                        errors.push(String_Format(Validations.Resources.fileIsTooLarge, file.name));
                    }
                } else {
                    errors.push(String_Format(Validations.Resources.theFileSelectedIsNotTheCorrectFormat, file.name) );
                }
            });
        }

        this.setState({errors: errors.join(", ")});

        if(imagesToBeSaved.length > 0){
            SpacesService.saveSpaceGallery(imagesToBeSaved).then(
                result => {
                    var newCurrentImages = [...this.state.currentImages];
                    result.value.map(r => {
                        newCurrentImages.push(r);
                    })
    
                    this.setState({ ...this.state, currentImages: [...newCurrentImages] });
                    this.props.onSaveCallback(newCurrentImages);
                }
            );
        }
    }

    @bind
    handleDrag(e) {
        e.preventDefault();
        e.stopPropagation();
    }
    @bind
    removeImage(img: string) {
        var newCurrentImages = [...this.state.currentImages];
        newCurrentImages.splice(newCurrentImages.indexOf(img), 1);
        this.setState({ ...this.state, currentImages: newCurrentImages });
        this.props.onSaveCallback(newCurrentImages);
    }

    @bind
    changeImageOrder(newImages) {
        this.setState({ ...this.state, currentImages: newImages });
        this.props.onSaveCallback(newImages);
    }

    render() {
        return <div className='i-gallery' draggable="true">
            <div className='i-gallery-upload' onDrop={this.uploadImagesWithDragDrop} onDragOver={this.handleDrag}>
                <div className='i-upload-label'>{Gallery.Resources.dropYourImage}
                    <span className='i-upload-btn'>
                        <span className='i-upload-btn-label'>{Gallery.Resources.browse}</span>
                        <input type='file' onChange={this.uploadImagesWithInput} data-multiple-caption="{count} files selected" multiple />
                    </span>
                </div>
                <div className='i-upload-info'>{Gallery.Resources.supportsJPGandPNGfiles}</div>
            </div>

            <div className='i-gallery-title'>{Gallery.Resources.photoOrder}</div>
            <div className='i-gallery-detail'>{Gallery.Resources.dragAndDropPhotosPlaceInTheOrderYouLikeThemToAppear}</div>

            <div style={{ color: "red" }}>
                {this.getFieldErrors(nameof(state => state.images)).map(err => <span key={err} style={{ color: "red" }} className="field-validation-error">{err}</span>)}
                {this.state.errors}
            </div>

            <div className='i-gallery-list'>
                <DnDGrid items={this.state.currentImages} onChange={this.changeImageOrder} getItemId={(img) => this.state.currentImages.indexOf(img) + 1}
                    getGridElementInnerContent={(image, propsForHandle) =>
                        <div className='i-gallery-item'>
                            <div className='i-gallery-photo'>
                                <div  {...propsForHandle}>
                                    <img src={AppSettings.CommonSettings.ImageBaseUrl + image} />
                                </div>
                                <a className="i-gallery-delete-btn" style={{ cursor: "pointer" }} onClick={() => this.removeImage(image)}><span className="material-symbols-outlined">delete</span></a>
                            </div>
                        </div>
                    } />
            </div>
        </div>
    }
}

var component = connect(
    (state: ApplicationState) => {
        return {
            images: []
        }
    }, null, null, { forwardRef: true }
)(ImageGalleryUpload as any);

export var ImageGalleryUploadComponent = (component as any as typeof ImageGalleryUpload);