import { Action, Reducer } from "redux";
import update from "immutability-helper";
import { AppThunkAction } from ".";
import AccountsAndLogsService from "../services/AccountsAndLogsService";
import { getServerSideErrors } from "../utils/utils";

export module AdminAccountsStore {
    export interface IState {
        loading: boolean;
        adminAccounts: any[],
        hasServerSideErrors: boolean,
        errors: string,
    }

    export enum Actions {
        ToggleLoading = "ADMIN_ACCOUNTS_TOGGLE_LOADING",
        SetAdminAccounts = "ADMIN_ACCOUNTS_SET_ADMIN_ACCOUNTS",
        SetErrors = "ADMIN_ACCOUNTS_SET_ERRORS"
    }

    export interface IToggleLoading {
        type: Actions.ToggleLoading,
        loading: boolean
    }

    export interface ISetAdminAccounts {
        type: Actions.SetAdminAccounts,
        adminAccounts: any[]
    }

    interface ISetErrors {
        type: Actions.SetErrors,
        hasServerSideErrors: boolean,
        errors: string
    }

    type KnownAction = IToggleLoading | ISetAdminAccounts | ISetErrors;

    export const actionCreators = {
        getAdminAccounts: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
            dispatch({ type: Actions.ToggleLoading, loading: true });

            var adminUsers = await AccountsAndLogsService.getAdminAccounts().then(response => response.value);

            dispatch({ type: Actions.SetAdminAccounts, adminAccounts: adminUsers });

            dispatch({ type: Actions.ToggleLoading, loading: false });
        },
        saveAdminAccount: (name: string, email: string, password: string, isChangePassword?: boolean, id?: number): AppThunkAction<KnownAction> => async (dispatch, getState) => {
            var serverResponse, adminData;

            if (id) {
                adminData = {
                    name: name,
                    email: email,
                    password: password,
                    isChangePassword: isChangePassword
                }
                serverResponse = await AccountsAndLogsService.editAdminAccount(id, adminData).then(response => response);
            } else {
                adminData = {
                    name: name,
                    email: email,
                    password: password
                }
                serverResponse = await AccountsAndLogsService.addAdminAccount(adminData).then(response => response);
            }
        
            var errors = getServerSideErrors(serverResponse);
            var hasServerSideErrors = errors ? true : false;

            dispatch({ type: Actions.SetErrors, hasServerSideErrors: hasServerSideErrors, errors: errors });
        },
        deactivateAdminAccount: (id: number): AppThunkAction<KnownAction> => async (dispatch, getState) => {
            var serverResponse = await AccountsAndLogsService.deactivateAdminAccount(id).then(response => response);

            var errors = getServerSideErrors(serverResponse);
            var hasServerSideErrors = errors ? true : false;

            if (hasServerSideErrors) {
                dispatch({
                    type: Actions.SetErrors, hasServerSideErrors: hasServerSideErrors, errors: errors
                });
            }
        },
        activateAdminAccount: (id: number): AppThunkAction<KnownAction> => async (dispatch, getState) => {
            var serverResponse = await AccountsAndLogsService.activateAdminAccount(id).then(response => response);

            var errors = getServerSideErrors(serverResponse);
            var hasServerSideErrors = errors ? true : false;

            if (hasServerSideErrors) {
                dispatch({
                    type: Actions.SetErrors, hasServerSideErrors: hasServerSideErrors, errors: errors
                });
            }
        }
    }

    const initialState: IState = {
        loading: false,
        adminAccounts: [],
        errors: '',
        hasServerSideErrors: false
    }

    export const reducer: Reducer<IState> = (currentState: IState, incomingAction: Action) => {
        const action = incomingAction as KnownAction;
        switch (action.type) {
            case Actions.ToggleLoading:
                return update(currentState,
                    {
                        loading: { $set: action.loading }
                    });
            case Actions.SetAdminAccounts:
                return update(currentState,
                    {
                        adminAccounts: { $set: action.adminAccounts }
                    });
            case Actions.SetErrors:
                return update(currentState,
                    {
                        hasServerSideErrors: { $set: action.hasServerSideErrors },
                        errors: { $set: action.errors },
                    });
            default:
                return currentState || initialState;
        }
    }
}