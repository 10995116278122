import bind from "bind-decorator";
import React from "react";
import FormInput from "../Shared/FormInput";
import { EntityFieldInputType } from "../../utils/reactUtils";
import { getPromiseFromAction, nameof } from "../../utils/utils";
import { ValidatedComponent } from "../FormValidations/Base/ValidatedComponent";
import { connect } from "react-redux";
import { ApplicationState } from "../../store";
import ImageGalleryUpload from "../Shared/ImageGalleryUpload";
import General from "../../resources/AdminUI/General";
import { IWorkspaceSchedule } from "../../models/Spaces/Interfaces/IWorkspaceSchedule";
import ScheduleForm from "./ScheduleForm";
import { OfficeAmenitiesForm } from "./AmenitiesForm";
import { ISelectListItemForAmenity } from "../../models/Common/ISelectListItemForAmenity";
import { ScheduleTab } from "../Tabs/ScheduleTab";
import AccessIntructionsFormTabsComponent from "../Tabs/AccessIntructionsFormTabsComponent";
import DescriptionTextareaFormTabsComponent from "../Tabs/DescriptionTextareaFormTabsComponent";
import AddOrEditOffice from "../../resources/AdminUI/Spaces/AddOrEditOffice";
import { AddOrEditOfficeStore } from "../../store/AddOrEditOfficeStore";
import { AddOrEditOfficeFormValidator } from "../FormValidations/Validators/AddOrEditOfficeFormValidator";
import { IAntdSelectListItem } from "../../models/Common/IAntdSelectListItem";
import { Select } from "antd";
import { NavigateFunction } from "react-router";
import { withRouter } from "../Shared/withRouter";
import ModalDialog from "../Shared/ModalDialog";
import Validations from "../../resources/Common/Validations";

export interface IProps {
  id?: number;
  name?: string;
  companyId?: number;
  address?: string;
  lat?: number;
  lng?: number;
  countryId?: string;
  cityId?: string;
  officeOffersHotDesks?: boolean;
  maxSeats?: number;
  requireBooking?: boolean;
  descriptionEn?: string;
  descriptionEs?: string;
  descriptionPt?: string;
  email?: string;
  phone?: string;
  wifiName?: string;
  wifiPassword?: string;
  accessInstructionsEn?: string;
  accessInstructionsEs?: string;
  accessInstructionsPt?: string;
  localeId?: string;
  schedule?: IWorkspaceSchedule[];
  images?: string[];
  countries?: IAntdSelectListItem[];
  cities?: IAntdSelectListItem[];
  amenities?: ISelectListItemForAmenity[];
  languages?: IAntdSelectListItem[];

  hasServerSideErrors?: boolean;
  errors?: string;

  navigate?: NavigateFunction;

  setOfficeImages?: (images: string[]) => void;
  setOfficeSchedule?: (images: IWorkspaceSchedule[]) => void;
  setOfficeAmenities?: (amenities: ISelectListItemForAmenity[]) => void;

  saveDetails?: (
    id?: number,
    name?: string,
    companyId?: number,
    countryId?: string,
    cityId?: string,
    address?: string,
    lat?: number,
    lng?: number,
    requireBooking?: boolean,
    maxSeats?: number,
    descriptionEn?: string,
    descriptionEs?: string,
    descriptionPt?: string,
    email?: string,
    phone?: string,
    wifiName?: string,
    wifiPassword?: string,
    accessInstructionsEn?: string,
    accessInstructionsEs?: string,
    accessInstructionsPt?: string,
    localeId?: string
  ) => any;
}

export interface IState {
  id?: number;
  name?: string;
  categoryId?: string;
  address?: string;
  countryId?: string;
  cityId?: string;
  lat?: number;
  lng?: number;
  officeOffersHotDesks?: boolean;
  requireBooking?: boolean;
  maxSeats?: number;
  descriptionEn?: string;
  descriptionEs?: string;
  descriptionPt?: string;
  email?: string;
  phone?: string;
  wifiName?: string;
  wifiPassword?: string;
  accessInstructionsEn?: string;
  accessInstructionsEs?: string;
  accessInstructionsPt?: string;
  localeId?: string;

  isAmenitiesModalOpen?: boolean;
  isScheduleModalOpen?: boolean;
}

class AddOrEditOfficeForm extends ValidatedComponent<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.id,
      name: this.props.name,
      address: this.props.address,
      countryId: this.props.countryId,
      cityId: this.props.cityId,
      lat: this.props.lat,
      lng: this.props.lng,
      officeOffersHotDesks: this.props.officeOffersHotDesks,
      requireBooking: this.props.requireBooking,
      maxSeats: this.props.maxSeats,
      descriptionEn: this.props.descriptionEn,
      descriptionEs: this.props.descriptionEs,
      descriptionPt: this.props.descriptionPt,
      email: this.props.email,
      phone: this.props.phone,
      wifiName: this.props.wifiName,
      wifiPassword: this.props.wifiPassword,
      accessInstructionsEn: this.props.accessInstructionsEn,
      accessInstructionsEs: this.props.accessInstructionsEs,
      accessInstructionsPt: this.props.accessInstructionsPt,
      localeId: this.props.localeId,

      isAmenitiesModalOpen: false,
      isScheduleModalOpen: false,
    };
  }

  @bind
  hideAmenitiesModal() {
    this.setState({ isAmenitiesModalOpen: false });
  }

  @bind
  showAmenitiesModal() {
    this.setState({ isAmenitiesModalOpen: true });
  }

  @bind
  hideScheduleModal() {
    this.setState({ isScheduleModalOpen: false });
  }

  @bind
  showScheduleModal() {
    this.setState({ isScheduleModalOpen: true });
  }

  @bind
  handleInputChange(name: string, data: any) {
    this.setState({
      [name]: data,
    });
  }

  @bind
  handlePriceInputChange(name: string, data: any) {
    this.setState({
      [name]: data * 100,
    });
  }

  @bind
  handleSearchDropDownInputChange(name: string, data: any) {
    if (data.length > 0) {
      this.setState({
        [name]: data[0].value,
      });
    } else {
      this.setState({
        [name]: "",
      });
    }
  }

  @bind
  onKeyDownForNumberInput(e: any) {
    if (["e", "E", "+", "-", "."].includes(e.key)) {
      e.preventDefault();
    }
  }

  @bind
  onKeyDownForPriceInput(e: any) {
    if (["e", "E", "+", "-", "."].includes(e.key)) {
      e.preventDefault();
    }
  }

  @bind
  onKeyDownForLatAndLngNumberInput(e: any) {
    if (["e", "E", "+"].includes(e.key)) {
      e.preventDefault();
    }
  }

  @bind
  saveDetails() {
    this.validate(() => {
      var maxCapacityPerDay = this.state.officeOffersHotDesks
        ? this.state.maxSeats
        : 0;
      getPromiseFromAction(
        this.props.saveDetails(
          this.state.id,
          this.state.name,
          this.props.companyId,
          this.state.countryId,
          this.state.cityId,
          this.state.address,
          this.state.lat,
          this.state.lng,
          this.state.requireBooking,
          maxCapacityPerDay,
          this.state.descriptionEn,
          this.state.descriptionEs,
          this.state.descriptionPt,
          this.state.email,
          this.state.phone,
          this.state.wifiName,
          this.state.wifiPassword,
          this.state.accessInstructionsEn,
          this.state.accessInstructionsEs,
          this.state.accessInstructionsPt,
          this.state.localeId
        )
      ).then(() => {
        this.props.navigate(-1);
      });
    });
  }

  onCancel() {
    this.props.navigate(-1);
  }

  render() {
    return (
      <div className="form-container">
        <div className="form-line">
          <div className="line-info">
            <div className="title">
              {AddOrEditOffice.Resources.officeName}
              <span className="mandatory">*</span>
            </div>
            <div className="description">
              {AddOrEditOffice.Resources.nameVisibleInMobileApplication}
            </div>
          </div>
          <div className="line-action">
            <FormInput
              placeholder={AddOrEditOffice.Resources.officeName}
              inputType={EntityFieldInputType.Text}
              className={"form-control"}
              value={this.state.name}
              name={nameof((s) => s.name)}
              onChange={this.handleInputChange}
              errors={this.getFieldErrors(nameof((s) => s.name))}
            />
          </div>
        </div>

        <div className="form-line">
          <div className="line-info">
            <div className="title">
              {AddOrEditOffice.Resources.officeAddress}
              <span className="mandatory">*</span>
            </div>
          </div>
          <div className="line-action">
            <div className="input-group form-select">
              <Select
                options={this.props.countries}
                onChange={(value, option) => {
                  this.setState({ cityId: "", countryId: value.toString() });
                }}
                placeholder={AddOrEditOffice.Resources.selectCountry}
                value={
                  this.state.countryId ? this.state.countryId.toString() : null
                }
                showSearch
                optionFilterProp="label"
              ></Select>
              {this.getFieldErrors(nameof((s) => s.countryId)).map((err) => (
                <span key={err} className="field-validation-error">
                  {err}
                </span>
              ))}
            </div>
            <div className="input-group form-select">
              <Select
                options={
                  this.state.countryId
                    ? this.props.cities.filter((e) =>
                        this.state.countryId
                          ? e.group == this.state.countryId
                          : true
                      )
                    : this.props.cities
                }
                onChange={(value, option) => {
                  this.setState({ cityId: value.toString() });
                }}
                placeholder={AddOrEditOffice.Resources.selectCity}
                value={this.state.cityId ? this.state.cityId.toString() : null}
                showSearch
                optionFilterProp="label"
              ></Select>
              {this.getFieldErrors(nameof((s) => s.cityId)).map((err) => (
                <span key={err} className="field-validation-error">
                  {err}
                </span>
              ))}
            </div>
            <FormInput
              placeholder={AddOrEditOffice.Resources.completeAddress}
              inputType={EntityFieldInputType.Text}
              className={"form-control"}
              value={this.state.address}
              name={nameof((s) => s.address)}
              onChange={this.handleInputChange}
              errors={this.getFieldErrors(nameof((s) => s.address))}
            />
          </div>
        </div>

        <div className="form-line">
          <div className="line-info">
            <div className="title">
              {AddOrEditOffice.Resources.gpsCoordinates}
              <span className="mandatory">*</span>
            </div>
            <div className="description">
              {AddOrEditOffice.Resources.distanceAndMapLocationAreBasedOnThem}
            </div>
          </div>
          <div className="line-action">
            <FormInput
              placeholder={AddOrEditOffice.Resources.latitude}
              inputType={EntityFieldInputType.Number}
              className={"form-control"}
              value={this.state.lat}
              name={nameof((s) => s.lat)}
              onChange={this.handleInputChange}
              onKeyDown={this.onKeyDownForLatAndLngNumberInput}
              errors={this.getFieldErrors(nameof((s) => s.lat))}
            />
            <FormInput
              placeholder={AddOrEditOffice.Resources.longitude}
              inputType={EntityFieldInputType.Number}
              className={"form-control"}
              value={this.state.lng}
              name={nameof((s) => s.lng)}
              onChange={this.handleInputChange}
              onKeyDown={this.onKeyDownForLatAndLngNumberInput}
              errors={this.getFieldErrors(nameof((s) => s.lng))}
            />
          </div>
        </div>

        <div className="form-line">
          <div className="line-info">
            <div className="title">
              {AddOrEditOffice.Resources.doesOfficeOffersHotDesks}
              <span className="mandatory">*</span>
            </div>
          </div>
          <div className="line-action checkbox">
            <div>
              <input
                type="checkbox"
                name={nameof((s) => s.officeOffersHotDesks)}
                checked={this.state.officeOffersHotDesks}
                onChange={(event) => {
                  this.setState({
                    officeOffersHotDesks: event.target.checked,
                    requireBooking: event.target.checked,
                  });
                }}
              />
            </div>
            <div>Does this office offer desks?</div>
          </div>
        </div>

        <div className="form-line">
          <div className="line-info">
            <div className="title">
              {AddOrEditOffice.Resources.thisOfficeRequiresBooking}
              <span className="mandatory">*</span>
            </div>
            <div className="description">
              {
                AddOrEditOffice.Resources
                  .selectIfTheOfficeRequiresBookingCompleteMaxDesksAvailable
              }
            </div>
          </div>

          <div className="line-action">
            <label className="form-check">
              <input
                type="checkbox"
                name={nameof((s) => s.requireBooking)}
                checked={this.state.requireBooking}
                disabled={!this.state.officeOffersHotDesks}
                onChange={(event) => {
                  this.setState({
                    requireBooking: event.target.checked,
                  });
                }}
              />
              <label>
                {this.state.requireBooking
                  ? AddOrEditOffice.Resources.yesThisOfficeRequiresPriorBooking
                  : AddOrEditOffice.Resources
                      .noThisIsAWalkInOfficeAndDoesNotRequireBooking}
              </label>
            </label>

            <FormInput
              placeholder={AddOrEditOffice.Resources.maximumCapacityPerDay}
              inputType={EntityFieldInputType.Number}
              className={"form-control"}
              value={this.state.maxSeats}
              name={nameof((s) => s.maxSeats)}
              onChange={this.handleInputChange}
              onKeyDown={this.onKeyDownForNumberInput}
              readOnly={
                !this.state.officeOffersHotDesks || !this.state.requireBooking
              }
              errors={this.getFieldErrors(nameof((s) => s.maxSeats))}
            />
          </div>
        </div>

        <div className="form-line">
          <div className="line-info">
            <div className="title">
              {AddOrEditOffice.Resources.wifiInfoAndAccess}
            </div>
            <div className="description">
              {
                AddOrEditOffice.Resources
                  .anAutomatedEmailWillBeSentToUserWithWiFiDetailsAndBuildingInfo
              }
            </div>
          </div>
          <div className="line-action">
            <FormInput
              placeholder={AddOrEditOffice.Resources.wifiNetwork}
              inputType={EntityFieldInputType.Text}
              className={"form-control"}
              value={this.state.wifiName}
              name={nameof((s) => s.wifiName)}
              onChange={this.handleInputChange}
              errors={this.getFieldErrors(nameof((s) => s.wifiName))}
            />

            <FormInput
              placeholder={AddOrEditOffice.Resources.wifiPassword}
              inputType={EntityFieldInputType.Text}
              className={"form-control"}
              value={this.state.wifiPassword}
              name={nameof((s) => s.wifiPassword)}
              onChange={this.handleInputChange}
              errors={this.getFieldErrors(nameof((s) => s.wifiPassword))}
            />

            <AccessIntructionsFormTabsComponent
              componentId={`office-access-instructions-${this.props.id}`}
              textEn={this.props.accessInstructionsEn}
              nameEn={nameof((s) => s.accessInstructionsEn)}
              placeholderEn={
                AddOrEditOffice.Resources.buildingAccessInfoWithExamples
              }
              textEs={this.props.accessInstructionsEs}
              nameEs={nameof((s) => s.accessInstructionsEs)}
              placeholderEs={
                AddOrEditOffice.Resources.buildingAccessInfoWithExamples
              }
              textPt={this.props.accessInstructionsPt}
              namePt={nameof((s) => s.accessInstructionsPt)}
              placeholderPt={
                AddOrEditOffice.Resources.buildingAccessInfoWithExamples
              }
              onChangeCallback={this.handleInputChange}
            />
          </div>
        </div>

        <div className="form-line">
          <div className="line-info">
            <div className="title">
              {AddOrEditOffice.Resources.emailLanguage}
            </div>
            <div className="description">
              {
                AddOrEditOffice.Resources
                  .chooseInWhichLanguageTheEmailsShouldBeSent
              }
            </div>
          </div>
          <div className="line-action">
            <div className="input-group">
              <Select
                options={this.props.languages}
                onChange={(value: string, option: any) => {
                  this.handleInputChange(
                    nameof((s) => s.localeId),
                    value
                  );
                }}
                value={
                  this.state.localeId ? this.state.localeId.toString() : null
                }
                placeholder={General.Resources.select}
              ></Select>
            </div>
            {this.getFieldErrors(nameof((s) => s.localeId)).map((err) => (
              <span key={err} className="field-validation-error">
                {err}
              </span>
            ))}
          </div>
        </div>

        <h2 className="form-divider">
          {AddOrEditOffice.Resources.presentationDetails}
        </h2>

        <div className="form-line">
          <div className="line-info">
            <div className="title">
              {AddOrEditOffice.Resources.fullDescription}
            </div>
            <div className="description">
              {AddOrEditOffice.Resources.recommendBetween200And400Characters}
            </div>
          </div>
          <div className="line-action">
            <DescriptionTextareaFormTabsComponent
              componentId={`office-description-${this.props.id}`}
              textEn={this.props.descriptionEn}
              nameEn={nameof((s) => s.descriptionEn)}
              placeholderEn={AddOrEditOffice.Resources.fullDescription}
              textEs={this.props.descriptionEs}
              nameEs={nameof((s) => s.descriptionEs)}
              placeholderEs={AddOrEditOffice.Resources.fullDescription}
              textPt={this.props.descriptionPt}
              namePt={nameof((s) => s.descriptionPt)}
              placeholderPt={AddOrEditOffice.Resources.fullDescription}
              onChangeCallback={this.handleInputChange}
            />
          </div>
        </div>

        <div className="form-line">
          <div className="line-info">
            <div className="title">
              {AddOrEditOffice.Resources.publicContactInformation}
            </div>
            <div className="description">
              {
                AddOrEditOffice.Resources
                  .officePhoneNumberAndEmailAddressWillBeDisplayedInAppForUsers
              }
            </div>
          </div>
          <div className="line-action">
            <FormInput
              placeholder={AddOrEditOffice.Resources.emailAddress}
              inputType={EntityFieldInputType.Text}
              className={"form-control"}
              value={this.state.email}
              name={nameof((s) => s.email)}
              onChange={this.handleInputChange}
              errors={this.getFieldErrors(nameof((s) => s.email))}
            />

            <FormInput
              placeholder={AddOrEditOffice.Resources.phoneNumber}
              inputType={EntityFieldInputType.Text}
              className={"form-control"}
              value={this.state.phone}
              name={nameof((s) => s.phone)}
              onChange={this.handleInputChange}
              errors={this.getFieldErrors(nameof((s) => s.phone))}
            />
          </div>
        </div>

        <div className="form-line">
          <div className="line-info">
            <div className="title">
              {AddOrEditOffice.Resources.officePhotographs}
            </div>
            <div className="description">
              {AddOrEditOffice.Resources.acceptedFormats}
            </div>
          </div>
          <div className="line-action">
            {this.getFieldErrors(nameof((s) => s.images)).map((err) => (
              <span key={err} className="field-validation-error">
                {err}
              </span>
            ))}
            <ImageGalleryUpload
              images={this.props.images}
              onSaveCallback={this.props.setOfficeImages}
            />
          </div>
        </div>

        <div className="form-line">
          <div className="line-info">
            <div className="title">
              {AddOrEditOffice.Resources.officeAmenities}
            </div>
            <div className="description">
              {
                AddOrEditOffice.Resources
                  .selectFromTheListAndHighlightTheImportantOnes
              }
            </div>
          </div>
          <div className="line-action">
            <button
              className="btn-big btn-secondary in-form"
              onClick={() => this.showAmenitiesModal()}
            >
              {AddOrEditOffice.Resources.selectAmenities}
            </button>
            <ModalDialog
              title={AddOrEditOffice.Resources.selectOfficeAmenities}
              open={this.state.isAmenitiesModalOpen}
              onCancel={this.hideAmenitiesModal}
            >
              <OfficeAmenitiesForm
                additionalInofrmation={
                  AddOrEditOffice.Resources
                    .pleaseSelectAllAvailableAmenitiesHighlightImportantAmenitiesAndTheyWillAppearOnTheMainSpaceDetailsScreen
                }
                onSaveCallback={this.props.setOfficeAmenities}
                onCloseCallback={this.hideAmenitiesModal}
              ></OfficeAmenitiesForm>
            </ModalDialog>
            {this.props.amenities && this.props.amenities.length > 0 ? (
              <div>
                <div className="form-selection-displayed">
                  <span>{General.Resources.highlighted}</span>
                  {this.props.amenities
                    .filter((e) => e.selected && e.isHighlighted)
                    .map((e) => e.text)
                    .join("| ")}
                </div>
                <div className="form-selection-displayed">
                  <span>{General.Resources.others}</span>
                  {this.props.amenities
                    .filter((e) => e.selected && !e.isHighlighted)
                    .map((e) => e.text)
                    .join("| ")}
                </div>
              </div>
            ) : null}
          </div>
        </div>

        <div className="form-line">
          <div className="line-info">
            <div className="title">
              {AddOrEditOffice.Resources.officeOpeningHours}
            </div>
          </div>
          <div className="line-action">
            <button
              className="btn-big btn-secondary in-form"
              onClick={this.showScheduleModal}
            >
              {AddOrEditOffice.Resources.selectSchedule}
            </button>
            <ModalDialog
              title={AddOrEditOffice.Resources.setOfficeProgram}
              open={this.state.isScheduleModalOpen}
              onCancel={this.hideScheduleModal}
            >
              <ScheduleForm
                schedule={this.props.schedule}
                additionalInofrmation={
                  AddOrEditOffice.Resources
                    .pleaseCompleteEverydaysProgramIfTheOfficeIsClosedCheckTheClosedOption
                }
                onSaveCallback={this.props.setOfficeSchedule}
                onCloseCallback={this.hideScheduleModal}
              ></ScheduleForm>
            </ModalDialog>
            <div>
              {this.getFieldErrors(nameof((s) => s.schedule)).map((err) => (
                <span key={err} className="field-validation-error">
                  {err}
                </span>
              ))}
            </div>
            <div style={{ display: "grid" }}>
              {this.props.schedule?.filter(
                (e) => e.openHour == null && e.closeHour == null && !e.isClosed
              ).length > 0 ? null : (
                <ScheduleTab schedule={this.props.schedule} />
              )}
            </div>
          </div>
        </div>

        {this.props.hasServerSideErrors ? (
          <div>
            <span className="field-validation-error" style={{ color: "red" }}>
              {this.props.errors}
            </span>
          </div>
        ) : (
          <></>
        )}

        {this.state.errors && Object.keys(this.state.errors).length ? (
          <div>
            <div>
              <span className="field-validation-error" style={{ color: "red" }}>
                {Validations.Resources.notAllFieldsAreValid}
              </span>
            </div>
            {Object.values(this.state.errors).map((errorValues) => {
              return (errorValues as any).map((err) => (
                <span key={err} className="field-validation-error">
                  {err}
                </span>
              ));
            })}
          </div>
        ) : (
          <></>
        )}

        <div className="form-line form-bottom-actions">
          <button
            className="btn-big btn-secondary"
            onClick={() => this.onCancel()}
          >
            {General.Resources.cancelButtonLabel}
          </button>
          <button
            className="btn-big btn-primary form-displayed-bottom"
            onClick={() => this.saveDetails()}
          >
            {General.Resources.save}
          </button>
        </div>
      </div>
    );
  }
}

export default withRouter(
  connect(
    (state: ApplicationState, ownProps: IProps) => {
      return {
        validator: AddOrEditOfficeFormValidator,
        countries: state.lookup.countries,
        cities: state.lookup.cities,
        languages: state.lookup.languages.map((op) => {
          op.selected = op.value == state.addOrEditSpace.localeId;
          return op;
        }),
        ...state.addOrEditOffice,
      };
    },
    {
      saveDetails: AddOrEditOfficeStore.actionCreators.saveDetails,
      setOfficeImages: AddOrEditOfficeStore.actionCreators.setOfficeImages,
      setOfficeSchedule: AddOrEditOfficeStore.actionCreators.setOfficeSchedule,
      setOfficeAmenities:
        AddOrEditOfficeStore.actionCreators.setOfficeAmenities,
    }
  )(AddOrEditOfficeForm as any)
);
