import { Action, Reducer } from "redux";
import { AppThunkAction } from ".";
import update from "immutability-helper";
import { getServerSideErrors } from "../utils/utils";
import HotdeskBookingsService from "../services/HotdeskBookingsService";

export module AddOrEditHotdeskBookingStore {

    export interface IState {
        id: number;
        loading: boolean;

        userId: number;
        workspaceId: number;
        statusId: string;

        checkinAt: string;
        checkoutAt: string;
        cancelledAt: string;
        bookingAt: string;

        checkinAtTime: string;
        checkoutAtTime: string;
        cancelledAtTime: string;
        bookingAtTime: string;

        hasServerSideErrors: boolean,
        errors: string,
    }

    export enum Actions {
        ToggleLoading = "ADD_OR_EDIT_HOTDESK_LOG_TOGGLE_LOADING",
        Initialize = "ADD_OR_EDIT_HOTDESK_LOG_INITIALIZE",
        SetDetails = "ADD_OR_EDIT_HOTDESK_LOG_SET_DETAILS",
        SaveDetails = "ADD_OR_EDIT_HOTDESK_LOG_SAVE_DETAILS",
        SetErrors = "ADD_OR_EDIT_HOTDESK_LOG_SET_ERRORS"
    }

    interface IInitialize {
        type: Actions.Initialize;
    }

    interface IToggleLoading {
        type: Actions.ToggleLoading;
        loading: boolean;
    }

    interface ISetDetails {
        type: Actions.SetDetails;
        id: number;
        details: any
    }

    interface ISetErrors {
        type: Actions.SetErrors,
        hasServerSideErrors: boolean,
        errors: string
    }

    type KnownAction = IToggleLoading | IInitialize | ISetDetails
        | ISetErrors;

    export const actionCreators = {

        initializeCreatePage: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
            dispatch({ type: Actions.Initialize });
        },

        initializeEditPage: (id: number): AppThunkAction<KnownAction> => async (dispatch, getState) => {
            dispatch({ type: Actions.ToggleLoading, loading: true });

            var details = await HotdeskBookingsService.getHotdeskBookingDetailsForEdit(id).then(response => response.value);

            dispatch({
                type: Actions.SetDetails,
                id: id,
                details: details
            });
            dispatch({ type: Actions.ToggleLoading, loading: false });
        },

        saveDetails: (id: number, userId: number, workspaceId: number, statusId: string,
            checkinAt?: string, checkoutAt?: string, cancelledAt?: string, bookingAt?: string,
            checkinAtTime?: string, checkoutAtTime?: string, cancelledAtTime?: string, bookingAtTime?: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {

                var details = {
                    userId: userId,
                    workspaceId: workspaceId,
                    statusId: statusId,
                    checkinAt: checkinAt,
                    checkoutAt: checkoutAt,
                    cancelledAt: cancelledAt,
                    bookingAt: bookingAt,
                    checkinAtTime: checkinAtTime,
                    checkoutAtTime: checkoutAtTime,
                    cancelledAtTime: cancelledAtTime,
                    bookingAtTime: bookingAtTime
                };
                dispatch({
                    type: Actions.SetDetails,
                    id: id,
                    details: details
                });

                var serverResponse;
                if (id == null) {
                    serverResponse = await HotdeskBookingsService.addHotdeskBooking(details).then(response => response);
                } else {
                    serverResponse = await HotdeskBookingsService.updateHotdeskBooking(id, details).then(response => response);
                }

                var errors = getServerSideErrors(serverResponse);
                var hasServerSideErrors = !errors ? false : true;
                dispatch({
                    type: Actions.SetErrors,
                    hasServerSideErrors: hasServerSideErrors,
                    errors: errors
                });
            },
            deleteBooking: (id: number): AppThunkAction<KnownAction> => async (dispatch, getState) => {
                var serverResponse = await HotdeskBookingsService.deleteHotdeskBooking(id).then(response => response);
                var errors = getServerSideErrors(serverResponse);
                var hasServerSideErrors = !errors ? false : true;
                dispatch({
                    type: Actions.SetErrors,
                    hasServerSideErrors: hasServerSideErrors,
                    errors: errors
                });
            },
    }

    const initialState: IState = {
        id: null,
        loading: false,

        userId: null,
        workspaceId: null,
        statusId: "",

        checkinAt: null,
        checkoutAt: null,
        cancelledAt: null,
        bookingAt: null,

        checkinAtTime: null,
        checkoutAtTime: null,
        cancelledAtTime: null,
        bookingAtTime: null,

        hasServerSideErrors: null,
        errors: null

    }

    export const reducer: Reducer<IState> = (currentState: IState, incomingAction: Action) => {
        const action = incomingAction as KnownAction;

        switch (action.type) {
            case Actions.Initialize:
                return initialState
            case Actions.ToggleLoading:
                return { ...currentState, loading: action.loading }
            case Actions.SetDetails:
                return update(currentState,
                    {
                        id: { $set: action.id },
                        userId: { $set: action.details.userId },
                        workspaceId: { $set: action.details.workspaceId },
                        statusId: { $set: action.details.statusId },
                        checkinAt: { $set: action.details.checkinAt },
                        checkoutAt: { $set: action.details.checkoutAt },
                        cancelledAt: { $set: action.details.cancelledAt },
                        bookingAt: { $set: action.details.bookingAt },
                        checkinAtTime: { $set: action.details.checkinAtTime },
                        checkoutAtTime: { $set: action.details.checkoutAtTime },
                        cancelledAtTime: { $set: action.details.cancelledAtTime },
                        bookingAtTime: { $set: action.details.bookingAtTime },
                    });
            case Actions.SetErrors:
                return update(currentState,
                    {
                        hasServerSideErrors: { $set: action.hasServerSideErrors },
                        errors: { $set: action.errors },
                    });
            default:
                return currentState || initialState;
        }


    }

}