import React, { CSSProperties, forwardRef, useState } from 'react';
import {
  DndContext, 
  closestCenter,
  useSensor,
  useSensors,
  MouseSensor,
  TouchSensor,
  DragOverlay,
} from '@dnd-kit/core';
import {
  arrayMove,
  rectSortingStrategy,
  SortableContext,
  useSortable,
} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import { v4 } from 'uuid';

export function SortableItem(props: { getItemId: (arg0: any) => any; item: any; style?: React.CSSProperties; getGridElementInnerContent: (arg0: any, arg1: any) => boolean | React.ReactChild | React.ReactFragment | React.ReactPortal; }) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({id: props.getItemId(props.item), resizeObserverConfig: {disabled: true}});
  
  const style: CSSProperties = {
    ...props.style,
    transform: CSS.Transform.toString(transform),
    transition,
  };
  
  return (
    <Item ref={setNodeRef} style={style} {...attributes}>
      {props.getGridElementInnerContent(props.item, listeners)}
    </Item>
  );
}

export default function DnDGrid(props: { items: any[]; onChange: (arg0: unknown[]) => void; getGridElementInnerContent: (arg0: any, arg1: any) => boolean | React.ReactChild | React.ReactFragment | React.ReactPortal; getItemId: (arg0: any) => any; itemStyle?: React.CSSProperties; }) {
  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor)
  );
  const [activeId, setActiveId] = useState(null);

  function handleDragEnd(event) {
    const {active, over} = event;
    
    if (active.id !== over.id) {
        const oldIndex = props.items.findIndex(i => active.id == props.getItemId(i));
        const newIndex = props.items.findIndex(i => over.id == props.getItemId(i));
        var newItems = arrayMove(props.items, oldIndex, newIndex);
        props.onChange(newItems);
    }
    setActiveId(null);
  }
  function handleDragStart(event) {
    const {active} = event;
    setActiveId(active.id);
  }

  return (
    <DndContext 
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
      onDragStart={handleDragStart}
    >
      <SortableContext 
        items={props.items.map(i => props.getItemId(i))}
        strategy={rectSortingStrategy}
      >
        {props.items.map(item => <SortableItem key={v4()} item={item} getGridElementInnerContent={props.getGridElementInnerContent} getItemId={props.getItemId} style={props.itemStyle} />)}
      </SortableContext>
      {/* <DragOverlay>
        {activeId ? <Item id={activeId}/> : null}
      </DragOverlay> */}
    </DndContext>
  );
}


const Item = forwardRef<unknown, any>(({...props}, ref) => {
  return (
    <div {...props} ref={ref}>{props.children}</div>
  )
});