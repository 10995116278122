import React from "react";
import ManageAccounts from "../../../resources/AdminUI/ManageAccounts";
import FormInput from "../../Shared/FormInput";
import { EntityFieldInputType } from "../../../utils/reactUtils";
import { nameof } from "../../../utils/utils";
import { ValidatedComponent } from "../../FormValidations/Base/ValidatedComponent";
import bind from "bind-decorator";
import { ICompanyAccount } from "../../../models/ManageAccounts/ICompanyAccount";
import General from "../../../resources/AdminUI/General";
import { AddOrEditSpaceCredentialsValidator } from "../../FormValidations/Validators/AddOrEditSpaceCredentialsValidator";
import { ApplicationState } from "../../../store";
import { connect } from "react-redux";
import { ManageAccountStore } from "../../../store/ManageAccountsStore";
import Validations from "../../../resources/Common/Validations";
import { ISpaceGroupAccount } from "../../../models/ManageAccounts/ISpaceGroupAccount";

interface IProps {
    account: ISpaceGroupAccount & ICompanyAccount,
    hasServerSideErrors?: boolean;
    errors?: string;
    saveCompleted?: boolean;
    validator?: any

    onCancelCallback: () => void,
    onSaveCallback: (account: ISpaceGroupAccount & ICompanyAccount) => void,
    resetErrors?: () => void
}

interface IState {
    account: ISpaceGroupAccount & ICompanyAccount
}

class EditEmailAddressDialog extends ValidatedComponent<IProps, IState> {
    constructor(props) {
        super(props)

        this.state = {
            account: {
                id: this.props.account.id,
                email: null,
                password: this.props.account.password,
                groupId: this.props.account.groupId || null,
                companyId: this.props.account.companyId || null,
                isPasswordReset: false
            }
        }

        this.props.resetErrors();
    }

    @bind
    handleInputChange(name: string, data: any) {
        this.setState({
            account: {
                ...this.state.account,
                [name]: data
            }
        });
    }

    @bind
    handleOnEditEmail() {
        this.validate(() => {
            this.props.onSaveCallback(this.state.account)
        });
    }

    componentDidUpdate(prevProps: Readonly<IProps & IProps>, prevState: Readonly<IState & IState>, snapshot?: any): void {
        if (this.props.saveCompleted) {
            this.props.onCancelCallback();
        }
    }

    render() {
        return <div className="modal-container">

            <div className="modal-title">{ManageAccounts.Resources.editEmailAddressDialogHeader}</div>

            <div className="modal-description">{ManageAccounts.Resources.editEmailAddressDialogDesc}</div>

            <div className="form-line">
                <div className="line-info">
                    <div className="title in-modal">{ManageAccounts.Resources.currentEmailAddressLabel}</div>
                </div>
                <div className="line-action">
                    {this.props.account.email}
                </div>
            </div>

            <div className="form-line">
                <div className="line-info">
                    <div className="title in-modal">{ManageAccounts.Resources.newEmailAddressLabel}</div>
                </div>
                <div className="line-action">
                    <FormInput
                        placeholder={ManageAccounts.Resources.emailAddressLabel}
                        inputType={EntityFieldInputType.Text}
                        className={"form-control"}
                        name={nameof(s => s.email)}
                        onChange={this.handleInputChange}
                        errors={this.getFieldErrors(nameof(s => s.email))}
                        value={this.state.account.email}
                    />
                </div>
            </div>

            {this.props.hasServerSideErrors ? <div><span className="field-validation-error" style={{ color: "red" }}>{this.props.errors}</span></div> : <></>}

            {this.state.errors && Object.keys(this.state.errors).length ?
                <div>
                    < div >
                        <span className="field-validation-error" style={{ color: "red" }}>{Validations.Resources.notAllFieldsAreValid}</span></div>
                        {Object.values(this.state.errors).map(errorValues => {
                            return (errorValues as any).map(err => <span key={err} className="field-validation-error">{err}</span>)
                        })}
                </div>
                :
                <></>
            }

            <div className="form-line form-bottom-actions">
                <button className="btn-big btn-secondary" onClick={this.props.onCancelCallback}>{General.Resources.cancelButtonLabel}</button>
                <button className="btn-big btn-primary" onClick={this.handleOnEditEmail}>{ManageAccounts.Resources.editEmailButtonText}</button>
            </div>
        </div>
    }
}

export default connect(
    (state: ApplicationState, ownProps: IProps) => {
        return {
            ...ownProps,
            validator: AddOrEditSpaceCredentialsValidator,
            errors: state.manageAccounts.errors,
            hasServerSideErrors: state.manageAccounts.hasServerSideErrors,
            saveCompleted: state.manageAccounts.saveCompleted,
        }
    },
    {
        resetErrors: ManageAccountStore.actionCreators.reset
    }
)(EditEmailAddressDialog as any)