
import { Action, Reducer } from "redux";
import { AppThunkAction } from ".";
import { IClientItem } from "../models/Clients/Interfaces/IClientItem"
import update from "immutability-helper";
import IPageIndex from "../models/Pagination/IPageIndex";
import ClientsService from "../services/ClientsService";

export module ClientsGridStore {

    export interface IState {
        clients: IClientItem[],
        numberOfClients: number,
        loading: boolean,
        filters: string,
        searchTerm: string,
        selectedCountry: string,
        selectedState: string,
        itemsPerPage: number;
        currentPage: number,
        firstIndexFromPage: number;
        lastIndexFromPage: number;
        pageIndexArray: IPageIndex[];
    }

    export enum Actions {
        Initialize = 'CLIENTS_INITIALIZE',
        ToggleLoading = 'CLIENTS_TOGGLE_LOADING',
        ReloadData = "CLIENTS_RELOAD_DATA",
        ChangeCurrentPage = "CLIENTS_CHANGE_CURRENT_PAGE",
        RecalculatePageArray = "CLIENTS_RECALCULATE_PAGE_ARRAY",
        RecalculateIndexes = "CLIENTS_RECALCULATE_INDEXES",
        ChangeSearchTerm = "CLIENTS_CHANGE_SEARCH_TERM",
        ChangeSelectedCountry = "CLIENTS_CHANGE_SELECTED_COUNTRY",
        ChangeSelectedState = "CLIENTS_CHANGE_SELECTED_STATE",
        ChangeFilterTerm = "CLIENTS_CHANGE_FILTER_TERM",
    }

    interface IInitialize {
        type: Actions.Initialize,
        itemsPerPage: number
    }

    interface IToggleLoading {
        type: Actions.ToggleLoading,
        loading: boolean
    }

    interface IReloadData {
        type: Actions.ReloadData,
        clients: IClientItem[],
        numberOfClients: number
    }

    interface IChangeCurrentPage {
        type: Actions.ChangeCurrentPage,
        currentPage: number
    }

    interface IRecalculatePageArray {
        type: Actions.RecalculatePageArray
    }

    interface IRecalculateIndexes {
        type: Actions.RecalculateIndexes
    }

    interface IChangeSearchTerm {
        type: Actions.ChangeSearchTerm,
        filterValue: string
    }

    
    interface IChangeSelectedCountry {
        type: Actions.ChangeSelectedCountry,
        filterValue: string
    }

    
    interface IChangeSelectedState {
        type: Actions.ChangeSelectedState,
        filterValue: string
    }

    interface IChangeFilterTerm {
        type: Actions.ChangeFilterTerm,
        filters: string
    }

    type KnownAction = IInitialize
        | IToggleLoading
        | IReloadData
        | IChangeCurrentPage
        | IRecalculatePageArray
        | IRecalculateIndexes
        | IChangeSearchTerm
        | IChangeFilterTerm
        | IChangeSelectedCountry
        | IChangeSelectedState;

    export const actionCreators = {
        initialize: (defaultSelectedItemsPerPageOption: number): AppThunkAction<KnownAction> => async (dispatch, getState) => {
            dispatch({ type: Actions.Initialize, itemsPerPage: defaultSelectedItemsPerPageOption });
            dispatch({ type: Actions.ToggleLoading, loading: true });

            var clients = await ClientsService.getClients(defaultSelectedItemsPerPageOption, 0, '')
                .then(res => res.value);
            var clientsMapped = ClientsService.additionalMapping(clients);

            if (clientsMapped.entities.length != 0) {
                dispatch({
                    type: Actions.ReloadData,
                    clients: clientsMapped.entities,
                    numberOfClients: clientsMapped.numberOfEntities
                })
            } else {
                dispatch({
                    type: Actions.ReloadData,
                    clients: [],
                    numberOfClients: 0
                })
            }

            dispatch({ type: Actions.ChangeCurrentPage, currentPage: 1 });
            dispatch({ type: Actions.RecalculatePageArray });
            dispatch({ type: Actions.RecalculateIndexes });
            dispatch({ type: Actions.ToggleLoading, loading: false });
        },
        reload: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
            dispatch({ type: Actions.ToggleLoading, loading: true });

            var toTake = getState().clients.itemsPerPage;
            var toSkip = (getState().clients.currentPage - 1) * getState().clients.itemsPerPage;
            var filters = getState().clients.filters;

            var clients = await ClientsService.getClients(toTake, toSkip, filters)
                .then(res => res.value);
            var clientsMapped = ClientsService.additionalMapping(clients);

            dispatch({
                type: Actions.ReloadData,
                clients: clientsMapped.entities,
                numberOfClients: clientsMapped.numberOfEntities
            });

            dispatch({
                type: Actions.ToggleLoading,
                loading: false
            });
        },
        changeCurrentPage: (currentPage: number): AppThunkAction<KnownAction> => async (dispatch, getState) => {
            dispatch({ type: Actions.ChangeCurrentPage, currentPage });
            dispatch({ type: Actions.RecalculatePageArray });
            dispatch({ type: Actions.RecalculateIndexes });
        },
        setFilter: (filters: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
            dispatch({ type: Actions.ChangeFilterTerm, filters: filters });
        },
        setSearchTerm: (searchTerm: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
            dispatch({ type: Actions.ChangeSearchTerm, filterValue: searchTerm });
        },
        setSelectedCountry: (selectedCountry: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
            dispatch({ type: Actions.ChangeSelectedCountry, filterValue: selectedCountry });
        },
        setSelectedState: (selectedState: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
            dispatch({ type: Actions.ChangeSelectedState, filterValue: selectedState });
        }
    }

    const initialState = {
        clients: [],
        numberOfClients: 0,
        loading: false,
        filters: '',
        searchTerm: "",
        selectedState: "2",
        selectedCountry: "0",
        itemsPerPage: 15,
        currentPage: 1,
        firstIndexFromPage: 1,
        lastIndexFromPage: 1,
        pageIndexArray: []
    }

    export const reducer: Reducer<IState> = (currentState: IState, incomingAction: Action) => {
        const action = incomingAction as KnownAction;

        switch (action.type) {
            case Actions.Initialize:
                return update(initialState,
                    {
                        itemsPerPage: { $set: action.itemsPerPage }
                    }
                );
            case Actions.ToggleLoading:
                return update(currentState,
                    {
                        loading: { $set: action.loading }
                    }
                );
            case Actions.ReloadData:
                return update(currentState,
                    {
                        clients: { $set: action.clients },
                        numberOfClients: { $set: action.numberOfClients }
                    }
                );
            case Actions.ChangeCurrentPage:
                return update(currentState,
                    {
                        currentPage: { $set: action.currentPage }
                    }
                );
            case Actions.RecalculatePageArray:
                var totalPages = Math.floor((currentState.numberOfClients - 1) / currentState.itemsPerPage) + 1;
                var pageArray = [];
                pageArray.push({ pageNumber: 1, isActive: currentState.currentPage === 1 } as IPageIndex);
                for (let i = 2; i <= totalPages; i++) {
                    pageArray.push({ pageNumber: i, isActive: currentState.currentPage === i });

                }
                return update(currentState,
                    {
                        pageIndexArray: { $set: pageArray }
                    }
                );
            case Actions.RecalculateIndexes:
                var firstIndex = (currentState.currentPage - 1) * currentState.itemsPerPage + 1;
                var totalPages = currentState.pageIndexArray.length;
                var lastIndex: number;

                if (totalPages == currentState.currentPage) {
                    lastIndex = Math.min(currentState.numberOfClients, currentState.itemsPerPage * totalPages);
                } else {
                    lastIndex = currentState.currentPage * currentState.itemsPerPage;
                }
                return update(currentState,
                    {
                        firstIndexFromPage: { $set: firstIndex },
                        lastIndexFromPage: { $set: lastIndex },
                    }
                );
            case Actions.ChangeFilterTerm:
                return update(currentState,
                    {
                        filters: { $set: action.filters }
                    }
                );
            case Actions.ChangeSearchTerm:
                return update(currentState,
                    {
                        searchTerm: { $set: action.filterValue }
                    }
                );
            case Actions.ChangeSelectedCountry:
                return update(currentState,
                    {
                        selectedCountry: { $set: action.filterValue }
                    }
                );
            case Actions.ChangeSelectedState:
                return update(currentState,
                    {
                        selectedState: { $set: action.filterValue }
                    }
                );
            default:
                return currentState || initialState;
        }
    }
}