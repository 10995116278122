import React from "react";
import { ApplicationState } from "../../../store";
import { ClientsGridStore } from "../../../store/ClientsGridStore";
import { connect } from "react-redux";
import PaginationBar from "../../Shared/PaginationBar";
import { ClientItem } from "../../../models/Clients/ClientItem";
import General from "../../../resources/AdminUI/General";
import { ApplicationPathBuilder } from "../../../enums/Common/ApplicationPaths";
import IPageIndex from "../../../models/Pagination/IPageIndex";
import bind from "bind-decorator";
import { NavigateFunction } from "react-router";
import { withRouter } from "../../Shared/withRouter";
import Loading from "../../Shared/Loading";

interface IProps {
  clients?: ClientItem[];
  numberOfClients?: number;
  loading?: boolean;

  itemsPerPage?: number;
  firstIndexFromPage?: number;
  lastIndexFromPage?: number;
  pageIndexArray?: IPageIndex[];

  navigate?: NavigateFunction;

  initialize?: () => void;
  reload?: () => void;
  changeCurrentPage?: (currentPage: number) => void;
}

interface IState {}

export class ClientsTable extends React.PureComponent<any, IState> {
  constructor(props) {
    super(props);
  }

  public static defaultProps: Partial<IProps> = {
    loading: false,
  };

  itemsPerPage: number = 15;
  maxPagesDisplayed: number = 3;
  pageNeighbours: number = 1;
  hasRefreshButton: boolean = false;
  tableClass: string = "table";
  hiddenColumns = [
    "id",
    "deactivated",
    "paymentProvider",
    "paymentProviderId",
    "currency",
    "countryId",
    "contactPhone",
    "numberOfEmployees",
    "numberOfUsers",
    "emailDoamins",
    "renewalIntervalUnit",
    "renewalIntervalValue",
    "lastRenewalDate",
  ];

  @bind
  goToClientDetails(clientId: number) {
    this.props.navigate(ApplicationPathBuilder.ViewCustomerDetails(clientId));
  }

  componentDidMount(): void {
    if (
      !this.props.clients ||
      (this.props.clients.length == 0 && !this.props.filters)
    ) {
      this.props.initialize(this.itemsPerPage, this.props.filters);
    } else {
      this.props.reload();
    }
  }

  render() {
    if (this.props.loading) {
      return (
        <div>
          <Loading />
        </div>
      );
    }
    if (!this.props.clients || this.props.clients.length == 0) {
      return <div>{General.Resources.noElements}</div>;
    }
    return (
      <div>
        <div className="table-wrapper clients-table">
          <table className={this.tableClass}>
            <thead>
              <tr>
                <th>{General.Resources.actions}</th>
                {this.props.clients[0].TrueProperties.filter(
                  (p) => this.hiddenColumns.indexOf(p) < 0
                ).map((p) => (
                  <th key={p}>
                    {this.props.clients[0].GetDisplayNameForProperty(p)}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {this.props.clients.map((us) => {
                return (
                  <tr key={us.id}>
                    <td className="row-actions">
                      <button
                        className="btn-table-action"
                        onClick={() => this.goToClientDetails(us.id)}
                      >
                        <span className="material-symbols-outlined">
                          visibility
                        </span>
                      </button>
                    </td>
                    {us.TrueProperties.filter(
                      (p) => this.hiddenColumns.indexOf(p) < 0
                    ).map((p) => (
                      <td
                        key={p}
                        data-label={
                          this.props.hasHeader
                            ? this.props.clients[0].GetColumnNameForProperty(p)
                            : null
                        }
                      >
                        {us.GetDisplayValueForProperty(p)}
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div>
          <PaginationBar
            useItemsPerPageOptions={false}
            totalItems={this.props.numberOfClients}
            reload={this.props.reload}
            changeCurrentPage={this.props.changeCurrentPage}
            itemsPerPage={this.props.itemsPerPage}
            firstIndexFromPage={this.props.firstIndexFromPage}
            lastIndexFromPage={this.props.lastIndexFromPage}
            pageIndexArray={this.props.pageIndexArray}
            maxPagesDisplayed={this.maxPagesDisplayed}
            pageNeighbours={this.pageNeighbours}
            hasRefreshButton={this.hasRefreshButton}
          />
        </div>
      </div>
    );
  }
}

export default withRouter(
  connect(
    (state: ApplicationState, ownProps: IProps) => {
      return state.clients;
    },
    {
      initialize: ClientsGridStore.actionCreators.initialize,
      changeCurrentPage: ClientsGridStore.actionCreators.changeCurrentPage,
      reload: ClientsGridStore.actionCreators.reload,
    }
  )(ClientsTable as any)
);
