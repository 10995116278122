import update from "immutability-helper";
import { Action, Reducer } from "redux";
import { AppThunkAction } from ".";
import UserService from "../services/UsersService";
import { IUserDetails } from "../models/Users/Interfaces/IUserDetails";
import { getServerSideErrors } from "../utils/utils";
import { IEditUser } from "../models/Users/Interfaces/IEditUser";
import { UserMarketingPreferencesTypes } from "../enums/Users/UserMarketingPreferencesTypes";
import { UserStates } from "../enums/Users/UserStates";

export module UserDetailsStore {
  export interface IState {
    user: IUserDetails;
    loading: boolean;
    hasServerSideErrors: boolean;
    errors: string;
    saveCompleted: boolean;
  }

  export enum Actions {
    GetUserDetails = "USER_DETAILS_GET_USER_DETAILS",
    ToggleLoading = "USER_DETAILS_TOGGLE_LOADING",
    EditUserInfo = "USER_DETAILS_EDIT_USER_INFO",
    SetErrors = "USER_DETAILS_SET_ERRORS",
    SaveCompleted = "USER_DETAILS_SAVE_COMPLETED",
    ChangeUserStatus = "USER_DETAILS_CHANGE_USER_STATUS",
  }

  interface IGetUserDetails {
    type: Actions.GetUserDetails;
    userDetails: IUserDetails;
  }

  interface IToggleLoading {
    type: Actions.ToggleLoading;
    loading: boolean;
  }

  interface IEditUserInfo {
    type: Actions.EditUserInfo;
    userToEdit: IEditUser;
  }

  interface ISetErrors {
    type: Actions.SetErrors;
    hasServerSideErrors: boolean;
    errors: string;
  }

  interface ISaveCompleted {
    type: Actions.SaveCompleted;
    saveCompleted: boolean;
  }

  interface IChangeUserStatus {
    type: Actions.ChangeUserStatus;
    status: UserStates;
  }

  type KnownAction =
    | IGetUserDetails
    | IToggleLoading
    | IEditUserInfo
    | ISetErrors
    | ISaveCompleted
    | IChangeUserStatus;

  export const actionCreators = {
    getUserDetails:
      (userId: number): AppThunkAction<KnownAction> =>
      async (dispatch, getState) => {
        dispatch({ type: Actions.ToggleLoading, loading: true });

        var user = await UserService.getUserDetails(userId).then(
          (respose) => respose.value
        );
        user.marketingPreferences = [];
        user.marketingPreferences.push(
          user.isPhonePreferredChannel
            ? UserMarketingPreferencesTypes[UserMarketingPreferencesTypes.Phone]
            : null,
          user.isSmsPreferredChannel
            ? UserMarketingPreferencesTypes[UserMarketingPreferencesTypes.SMS]
            : null,
          user.isEmailPreferredChannel
            ? UserMarketingPreferencesTypes[UserMarketingPreferencesTypes.Email]
            : null
        );

        dispatch({ type: Actions.GetUserDetails, userDetails: user });

        dispatch({ type: Actions.ToggleLoading, loading: false });
      },
    saveUserInfo:
      (userToEdit: IEditUser): AppThunkAction<KnownAction> =>
      async (dispatch, getState) => {
        dispatch({ type: Actions.ToggleLoading, loading: true });

        if (userToEdit.id) {
          var serverResponse = await UserService.updateUserDetails(
            userToEdit
          ).then((response) => response);
        } else {
          var serverResponse = await UserService.addUser(userToEdit).then(
            (response) => response
          );
        }

        var errors = getServerSideErrors(serverResponse);
        var hasServerSideErrors = errors ? true : false;

        dispatch({
          type: Actions.SetErrors,
          hasServerSideErrors: hasServerSideErrors,
          errors: errors,
        });

        if (!hasServerSideErrors) {
          dispatch({ type: Actions.SaveCompleted, saveCompleted: true });

          dispatch({ type: Actions.EditUserInfo, userToEdit: userToEdit });
        }

        dispatch({ type: Actions.ToggleLoading, loading: false });
      },
    reset: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
      dispatch({
        type: Actions.SetErrors,
        hasServerSideErrors: false,
        errors: "",
      });
      dispatch({ type: Actions.SaveCompleted, saveCompleted: false });
    },
    activateUser:
      (userId: number): AppThunkAction<KnownAction> =>
      async (dispatch, getState) => {
        var serverResponse = await UserService.activateUser(userId).then(
          (response) => response
        );

        var errors = getServerSideErrors(serverResponse);
        var hasServerSideErrors = errors ? true : false;

        dispatch({
          type: Actions.SetErrors,
          hasServerSideErrors: hasServerSideErrors,
          errors: errors,
        });

        if (!hasServerSideErrors) {
          dispatch({
            type: Actions.ChangeUserStatus,
            status: UserStates.Active,
          });
        }
      },
    deactivateUser:
      (userId: number): AppThunkAction<KnownAction> =>
      async (dispatch, getState) => {
        var serverResponse = await UserService.deactivateUser(userId).then(
          (response) => response
        );

        var errors = getServerSideErrors(serverResponse);
        var hasServerSideErrors = errors ? true : false;

        dispatch({
          type: Actions.SetErrors,
          hasServerSideErrors: hasServerSideErrors,
          errors: errors,
        });

        if (!hasServerSideErrors) {
          dispatch({
            type: Actions.ChangeUserStatus,
            status: UserStates.Deactivated,
          });
        }
      },
    deleteUser:
      (userId: number): AppThunkAction<KnownAction> =>
      async (dispatch, getState) => {
        var serverResponse = await UserService.deleteUser(userId).then(
          (response) => response
        );

        var errors = getServerSideErrors(serverResponse);
        var hasServerSideErrors = errors ? true : false;

        dispatch({
          type: Actions.SetErrors,
          hasServerSideErrors: hasServerSideErrors,
          errors: errors,
        });

        if (!hasServerSideErrors) {
          dispatch({
            type: Actions.ChangeUserStatus,
            status: UserStates.Deleted,
          });
        }
      },
  };

  const initialState: IState = {
    user: null,
    loading: false,
    hasServerSideErrors: false,
    errors: null,
    saveCompleted: false,
  };

  export const reducer: Reducer<IState> = (
    currentState: IState,
    incomingAction: Action
  ) => {
    const action = incomingAction as KnownAction;

    switch (action.type) {
      case Actions.ToggleLoading:
        return { ...currentState, loading: action.loading };
      case Actions.GetUserDetails:
        return update(currentState, {
          user: { $set: action.userDetails },
        });
      case Actions.SetErrors:
        return update(currentState, {
          hasServerSideErrors: { $set: action.hasServerSideErrors },
          errors: { $set: action.errors },
        });
      case Actions.SaveCompleted:
        return update(currentState, {
          saveCompleted: { $set: action.saveCompleted },
        });
      case Actions.ChangeUserStatus:
        return {
          ...currentState,
          user: {
            ...currentState.user,
            status: action.status,
          },
        };
      case Actions.EditUserInfo:
        return {
          ...currentState,
          user: {
            ...currentState.user,
            firstName: action.userToEdit.firstName,
            lastName: action.userToEdit.lastName,
            email: action.userToEdit.email,
            phone: action.userToEdit.phone,
            internalMetadata: action.userToEdit.internalMetadata,
          },
        };
      default:
        return currentState || initialState;
    }
  };
}
