import bind from "bind-decorator";
import Validations from "../../../resources/Common/Validations";
import { String_Format, isEmail, nameof } from "../../../utils/utils";
import { BaseValidator } from "../Base/BaseValidator";
import EditSpacePrice from "../../../resources/AdminUI/Spaces/EditSpacePrice";

export class EditSpacePriceFormValidator extends BaseValidator {

    constructor() {
        super();
    }

    @bind
    validate(model: any, props: any) {

        this.check(() => model.spaceId != '' && model.spaceId != null, nameof(e => e.spaceId), String_Format(Validations.Resources.required, EditSpacePrice.Resources.spaceId))
        this.check(() => model.startDate != '' && model.startDate != null, nameof(e => e.startDate), String_Format(Validations.Resources.required, EditSpacePrice.Resources.startDateTitle))
        this.check(() => model.fullDayPrice != null, nameof(e => e.fullDayPrice), String_Format(Validations.Resources.required, EditSpacePrice.Resources.fullDayPrice))
        this.check(() => model.halfDayPrice != null, nameof(e => e.halfDayPrice), String_Format(Validations.Resources.required, EditSpacePrice.Resources.halfDayPrice))
        this.check(() => model.unredeemedPrice != null, nameof(e => e.unredeemedPrice), String_Format(Validations.Resources.required, EditSpacePrice.Resources.unredeemedPrice))
        this.check(() => model.vat != null, nameof(e => e.vat), String_Format(Validations.Resources.required, EditSpacePrice.Resources.VAT))
        return this.getErrorsDictionary();
    }
}